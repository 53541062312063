<template>
  <div class="container-fluid register-page">
    <div class="title-block" v-if="!isEmissionViewScreen">
      <description class="title-block--detail" :scope="scope" :category="category" :workFlowData="workFlowData" :class="!isDisplayDescription && 'has-opacity'" >
        <slot>
          <div class="container update-block" v-if="isHasLatestRecord">
            <div class="item d-flex">
              <img class="item-icon align-self-center" src="@/assets/images/registerData/lastUpdateIcon.svg" />
              <div class="item-detail">
                <div class="item-title">{{$t('common.last_update')}}</div>
                <p class="item-content">{{ getLastTimeUpdate }}</p>
              </div>
            </div>
            <div class="item d-flex">
              <img class="item-icon align-self-center" src="@/assets/images/registerData/updateByIcon.svg" />
              <div class="item-detail">
                <div class="item-title">{{$t('common.updated_by')}}</div>
                <p class="item-content">{{ latestRecord.user_updated }}</p>
              </div>
            </div>
            <div v-if="displayAsTemplateDetails" class="action-table-template">
              <v-btn v-if="displayAsOwnerTemplate" class="btn-action" @click="handleDeleteData">
                {{ $t("register_data.button_delete") }}
              </v-btn>
              <v-btn class="btn-action" @click="handleSaveData">
                {{ $t("register_data.button_keep") }}
              </v-btn>
              <v-btn class="btn-action btn-apply" @click="handleApplyData">
                {{ $t("register_data.button_application") }}
              </v-btn>
            </div>
          </div>
        </slot>
      </description>
    </div>
    <div class="container selections" v-if="!isEmissionViewScreen">
      <div class="bg-container" :class="[isOverTextMethodAndEmissions && 'is-over-text-method-emissions', isOverTextMethodFlex && 'is-over-text-method-flex']">
        <div class="left-container">
          <div class="select-container left">
            <label class="durations-label">{{ $t("register_data.label_registration_date") }}</label>
            <div class="select-block">
              <div class="durations-pulldown overflow-select-inherit">
                <div v-if="isCreateNewData()" class="duration-text">
                  <span>{{ getSelectedDurationText() }}</span>
                </div>
                <v-select
                  v-else
                  :label="getSelectLabel"
                  :placeholder="getSelectLabel"
                  :items="yearList"
                  v-model="selectedYear"
                  dense
                  solo
                  class="durations-select-item select-item select-month new-register"
                  :menu-props="{ contentClass: 'selecting select-durations select-menu select-years' }"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.text }}
                    <v-spacer></v-spacer>
                    <span v-if="data.item.isShowIcon" class="data-is-registered"></span>
                  </template>
                </v-select>
              </div>
              <div class="durations-pulldown month">
                <v-select
                  id="monthDropdown"
                  v-if="!isCreateNewData()"
                  :items="monthList"
                  item-text="text"
                  item-value="value"
                  v-model="selectedMonth"
                  :label="getSelectLabel"
                  :placeholder="getSelectLabel"
                  dense
                  solo
                  class="durations-select-item select-item select-month new-register"
                  :disabled="isCreateNewData()"
                  :menu-props="{ contentClass: 'selecting select-durations select-menu select-months' }"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.text }}
                    <v-spacer></v-spacer>
                    <span v-if="data.item.isShowIcon" class="data-is-registered"></span>
                  </template>
               </v-select>
                <div v-else class="duration-text"><span class="month-text">{{ getSelectedMonthText() }}</span></div>
              </div>
            </div>
          </div>
          <div class="method w-100 method-container" v-if="!isShowSummaryScore && methodList.length > 1" :key="keySetting" id="method-area-pc">
            <div class="total-label method-label">{{ $t("register_data.label_calculation_method") }}</div>
            <common-button v-if="displayGetMethodName" class="has-elipsis custom-btn-method" @action="openSelectPopup" :label="getMethodName"></common-button>
            <div v-else class="method-name"><span>{{getMethodName}}</span></div>
          </div>
          <div v-if="isShowSummaryScore" class="summary-score">
            <v-radio-group v-model="summaryScoreType" inline class="custome-radio-group">
              <v-radio v-for="(item, i) in summaryScoreItems" :key="i" :label="`${item.value}`" :value="item.id" :ripple="false" />
            </v-radio-group>
          </div>
          <div v-if="isShowNotePcaf && !isShowSummaryScore && itemSelectPCAF.itemsDataType.length" class="select-types">
            <div class="category-type" v-if="isShowCategorySelectPcaf">
              <div v-if="!displayGetMethodName || itemSelectPCAF.itemsCategoryType.length === 0" class="category-name"><span>{{ getCategoryName(itemSelectPCAF.itemsCategoryType, itemSelectPCAF.categoryIdSelect) }}</span></div>
              <v-select
                v-else
                :placeholder='$t("pcaf_update.placeholder_category_type_pcaf")'
                :items="itemSelectPCAF.itemsCategoryType"
                v-model="itemSelectPCAF.categoryIdSelect"
                @change="(newValue) => handleChangeSelect('categoryIdSelect', newValue)"
                item-text="name"
                item-value="id"
                dense
                solo
                class="select-item new-register"
                :menu-props="{ contentClass: 'selecting select-durations select-menu export-select' }"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }}
                  <v-spacer></v-spacer>
                  <span v-if="data.item.isShowIcon" class="data-is-registered"></span>
                </template>
              </v-select>
            </div>
            <div class="data-type">
              <div
                id="datatype-view"
                v-if="!displayGetMethodName|| itemSelectPCAF.itemsDataType.length === 0"
                @mouseover="toolTipOn(getCategoryName(itemSelectPCAF.itemsDataType, itemSelectPCAF.dataTypeIdSelect))"
                @mouseleave="toolTipOff()"
                @mousemove="updateTooltipPosition($event)"
               class="category-name name-data-type"><span>{{ getCategoryName(itemSelectPCAF.itemsDataType, itemSelectPCAF.dataTypeIdSelect) }}</span>
              <div id="tooltip-column" class="tooltip"></div></div>
              <v-select
                v-else
                :placeholder='$t("pcaf_update.placeholder_data_type_pcaf")'
                :items="itemSelectPCAF.itemsDataType"
                v-model="itemSelectPCAF.dataTypeIdSelect"
                @change="(newValue) => handleChangeSelect('dataTypeIdSelect', newValue)"
                item-text="name"
                item-value="id"
                dense
                solo
                class="select-item new-register"
                :menu-props="{ contentClass: 'selecting select-durations select-menu export-select pulldown-datatype' }"
                id="dropdown-show"
              >
                <template slot="item" slot-scope="data">
                  <div class="dropdown-item"
                    @mouseover="toolTipOn(data.item.name, true)"
                    @mouseleave="toolTipOff()"
                    @mousemove="updateTooltipPosition($event, true)"
                  >{{ data.item.name }}</div>
                  <v-spacer></v-spacer>
                  <span v-if="data.item.isShowIcon" class="data-is-registered"></span>
                  </template>
                  </v-select>
                <div id="tooltip-column" class="tooltip"></div>
            </div>
          </div>
        </div>
        <div class="total-result" :class="methodList.length === 1 && 'max-content'">
          <div class="emissions">
            <div class="total-label" v-if="$route.params.scope === '4' || scope === 4">{{ $t("register_data.label_emission_scope4") }}</div>
            <div class="total-label" v-else>{{ getSelectedMonthYearText() }}</div>
            <div class="total-emission" v-if="!isLoading">{{ formatNumber(totalEmissions, true) || 0 }}<span>t-CO2</span></div>
            <v-progress-circular
              v-if="isLoading"
              class="total-emission"
              indeterminate
              color="#A9A04B"
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isEmissionViewScreen">
      <div class="total-treatment-list mb-12" v-if="category === 5 || category === 12">
        <div class="treatment-item">
          <div class="treatment-label">{{ $t("register_data.label_incineration") }}</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.incinerator, true) || 0 }}<span>t-CO2</span></div>
        </div>
        <div class="treatment-item">
          <div class="treatment-label">{{ $t("register_data.label_landfill") }}</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.landfill, true) || 0 }}<span>t-CO2</span></div>
        </div>
        <div class="treatment-item">
          <div class="treatment-label">{{ $t("register_data.label_recycling") }}</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.recycling, true) || 0 }}<span>t-CO2</span></div>
        </div>
        <div class="treatment-item">
          <div class="treatment-label">{{ $t("register_data.label_unknown") }}</div>
          <div class="treatment-value">{{ formatNumber(totalTreatment.unknown, true) || 0 }}<span>t-CO2</span></div>
        </div>
      </div>
    </div>
    <ViewEmissionService v-if="isEmissionViewScreen && startMonth" @onHandleSearchEmission="handleSearchEmission" :startMonth="startMonth" :totalEmission="formatNumber(totalEmissions, true)" @updatePeriodData="handleUpdatePeriodData" />
    <div v-if="!this.isOpenScoreSummary" class="section-note-pcaf">{{  $t("register_data.label_note_pcaf") }}</div>
    <div
      class="category-table main-table custom-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded', isShowNotePcaf && 'pcaf-table', isEmissionViewScreen && 'padding-left-layout padding-right-layout']"
    >
      <data-table
        v-if="pattern"
        :data-source="dataTable"
        :init-grid="initTheTable"
        :allowDelete="!isReadOnlyDataTable"
        :grid-columns="getHeaderTable()"
        :isCreatedDataStatus="true"
        :key="key"
        :rowFocus="1"
        :totalData="isReadOnlyDataTable ? totalData - 1 : totalData"
        :showFocus="!isReadOnlyDataTable"
        :isReadOnly="isReadOnlyDataTable"
        :allowAutoWidth="pattern.allowWidthAuto ? pattern.allowWidthAuto() : false"
        :lastedRow="lastedRow"
        :allowAddNew="allowAddNew"
        :isHasData="isHasDataTable"
        :dataPattern="dataPattern"
        :isEditedData="isEditData"
        :isReadOnlyTitle="isReadOnlyTitle"
        :pattern="pattern"
        :itemsInView="itemsInView"
        :value.sync="selectedMonth"
        :selectedMonth="selectedMonth"
        :input.sync="selectedYear"
        :selectedYear="selectedYear"
        :methodList="methodList"
        :keySetting="keySetting"
        :displayGetMethodName="displayGetMethodName"
        :getMethodName="getMethodName"
        :totalEmissions="totalEmissions"
        :isCreateNewData="isCreateNewData"
        :isGetAllData="isGetAllData"
        :durationList="yearList"
        :monthYearSelectionList="monthList"
        :isResizeCustome="isResizeCustome"
        :isDisableBySetting="isDisableBySetting"
        :isHideUndoRedo="isHideUndoRedo"
        :isDisableButtonStatusTemplate="isDisableButtonStatusTemplate"
        :allowRightClick="!isReadOnlyDataTable && !isSummaryScoreScreen"
        :flexgridProps="flexgrid"
        :dbStore="getDbStore"
        :isApproval="isApproval"
        :settingPcafBySelectMonth="settingPcafBySelectMonth"
        :isShowNotePcaf="isShowNotePcaf"
        :isShowSummaryScore="isShowSummaryScore"
        :enterValue.sync="summaryScoreType"
        :summaryScoreType="summaryScoreType"
        :summaryScoreItems="summaryScoreItems"
        :isShowCategorySelectPcaf="isShowCategorySelectPcaf"
        :itemSelectPCAF="itemSelectPCAF"
        :currentDuration="currentDuration"
        :isEmissionViewScreen="isEmissionViewScreen"
        :basicUnitData="basicUnitData"
        :handleDataOnCheckError="handleDataOnCheckError"
        :isCheckedError="isCheckedError"
        tableName="registerData"
        @onHandleActionTable="onHandleActionTable"
        @onHandleShowExportPopup="onHandleShowExportPopup"
        @onHandleGotoScoreSummary="onHandleGotoScoreSummary"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        @openSelectPopup="openSelectPopup"
        @onChangeStyleActionTable="onChangeStyleActionTable"
        @onChangeStyleMethodFlex="onChangeStyleMethodFlex"
        @actionRightClick="actionRightClick"
        @addValueSourceIntoGrid="handleAddValueSourceIntoGrid"
        @changeTextToIdInSpecialPattern="handleChangeTextToIdInSpecialPattern"
        @checkTableHasData="handleContextPasted"
        @updateAutoFill="handleUpdateAutoFill"
        @onHandleShowError="onHandleShowError"
        @onHandleChangeSelect="handleChangeSelect"
        @updateDatatitle="updateDataTitle"
      />
      <submit-data-popup
        v-if="pattern"
        :dialog="submitDataDialogPopup"
        :listApprovers="listApprovers"
        :isLoadingSubmitting="isLoadingSubmitting"
        :dataPattern="dataPattern"
        :title="dataTitle"
        :isShowUserApproval="isShowUserApproval"
        @submitHandler="submitRegisterDataHandler"
        @closeDialogSubmitData="closeDialogSubmitData"
      />
      <save-template-data-popup
        :dialog="saveTemplateDataDialogPopup"
        :isCloneTemplate="isCloneTemplate"
        @submitHandler="submitTemplateAction"
        @closeDialogSaveTemplate="closeDialogSaveTemplate"
      />
      <error-popup
        :dialog="warningTemplateDataDialogPopup"
        :message="messageWarningTemplate"
        :isErrorImg="true"
        @submit="closeDialogWarningSaveTemplate"
      />
      <approval-popup
        :dialog="dialogApproval"
        :items="listStatusApproval"
        :confirmText="$t('register_data.button_decision')"
        @submit="approvalDataHandler"
        @close="dialogApproval = false"
      />
      <notification-popup
        :dialog="dialogNotification"
        :message="messageNotification"
        :showCheckbox="isShowCheckbox"
        :confirmText="textNoti"
        @submit="redirectAfterClickBtn(redirectUrl)"
        @updateShowPopup="updateShowPopup"
        classes="register"
      />
      <question-popup
        :dialog="dialogQuestion"
        :confirm="true"
        :message="messageQuestion"
        :confirmText="confirmQuestionText"
        :closeText="closeText"
        :isCloneTemplate="isCloneTemplate"
        :isSaveTemplateButton="isSaveTemplateButton"
        @close="closeQuestionPopUp()"
        classes="register"
        @submit="submitAction()"
      />
      <accept-cancel-popup
        :dialog="dialogAcceptCancel"
        :items="listStatusCancel"
        :confirmText="$t('register_data.button_decision')"
        @submit="handleAcceptRequestCancelSubmit"
        @close="dialogAcceptCancel = false"
      />
    </div>

    <radio-popup
      :scope="scope"
      :category="category"
      :dialog="radioDialog"
      :radioType="radioType"
      :items="methodList"
      :method="method"
      :method-layer2="methodLayer2"
      :currentSelect.sync="currentSelected"
      :listMethodsAtive="listMethodsAtive"
      @onChange="onChangeRadio"
      @close="closeRadioPopup"
      @submit="submitRadioPopup"
    />
    <radio-popup
      :scope="scope"
      :category="category"
      :listMethodsAtive="listMethodsAtive"
      :dialog="radioDialogLayer2"
      :radioType="radioType"
      :items="methodListLayer2"
      :method="methodLayer2"
      :currentSelect.sync="currentSelectedLayer2"
      :isLayer2="true"
      @onChange="onChangeRadio"
      @close="backtoLayer1"
      @submit="submitLayer2RadioPopup"
    />
    <error-popup
      :dialog="isShowPopup"
      :message="$t('register_data.popup_message_general_waste_industrial_only')"
      @submit="handleClosePopup"
    />
    <error-popup
      :dialog="isShowPopupUnCheckPermission"
      :message="$t('register_data.popup_message_permission_decline')"
      @submit="handleClosePopupError"
    />
    <error-popup :dialog="isErrorFilterData" :message="getErrorFilterData" @submit="handleCloseFilterData" />
    <question-popup
      :dialog="dialogQuestionChangeMethod"
      :message="messageChangeMethod"
      @close="dialogQuestionChangeMethod = false"
      classes="change-method"
      @submit="submitChangeMethod()"
    />
    <ExportReportPopup
      :key="exportDialogKey"
      :dialog.sync="exportDialog"
      :method="method"
      :methodLayer2="methodLayer2"
      :monthYearSelectionList="monthYearSelectionList"
      :monthList="monthList"
      :yearList="yearList"
      :branchData="branchData"
      :isSummaryScore="isOpenScoreSummary"
      :summaryScoreType="summaryScoreType"
      :type_classify="itemSelectPCAF.categoryIdSelect"
      :type_data="itemSelectPCAF.dataTypeIdSelect"
    />
    <notification-popup
      :dialog="isShowPopupCsv"
      :message="messagePopupCsv"
      @submit="isShowPopupCsv = false"
      classes="register"
    />
    <basicUnitPopup
      v-if="basicUnitDialog"
      :dialog="basicUnitDialog"
      :detailExternalSource="detailExternalSource"
      :basicUnitListDefault="basicUnitListDefault"
      :objPatternData="objPatternData"
      :basicUnitFilterDataDefault="basicUnitFilterDataDefault"
      @submitBasicUnitItem="submitBasicUnitItem"
      @close="basicUnitDialog = false"
    />
    <external-source-popup
      :dialog="externalSourceDialog"
      :selectedRow="selectedRow"
      :detailExternalSource="detailExternalSource"
      @submit="addExternalSource"
      @close="externalSourceDialog = false"
    />
    <service-link-source-popup
      :dialog="serviceLinkDialog"
      :selectedRow="selectedRow"
      :codeProductValue="codeProductValue"
      :listServicesLinked="listServicesLinked"
      :onlyViewedServicesLinked="onlyViewedServicesLinked"
      @submit="addServiceLinkSource"
      @close="serviceLinkDialog = false"
    />
    <notification-popup
      :dialog="dialogDeleteDataApproval"
      :message="messageDialogDeleteDataApproval"
      @submit="dialogDeleteDataApproval = false"
    />
    <question-popup
      :dialog="dialogQuestionChangeTypeIdSelect"
      :message="messageChangeTypeIdSelect"
      @close="cancelChangeSelectType()"
      classes="change-method"
      @submit="confirmChangeSelectType()"
    />
    <EvidenceStoragePopup :dialog.sync="openDialogEvidenceStorage" :typeForm="typeFormEvidenceStorage" :descriptionScope="descriptionScope" :evidences="evidences" @onSubmitSuccess="handleAddAttachFile" @onDelete="handleDeleteAttachFile"></EvidenceStoragePopup>
    <QuestionPopup
      :dialog="dialogStatusPopup"
      :message="$t('data_enable.description_change_status')"
      @submit="() => checkboxHandler(false)"
      @close="closeDialogStatus"
    />
    <ReadOcrService :dialog.sync="dialogReadFile" @addDataReadFile="onAddDataReadFile" :itemDetail="itemReadFile"></ReadOcrService>
  </div>
</template>
<script>
import * as wjcCore from '@mescius/wijmo';
import DataTable from '@/components/category/data-table';
import { UndoStack } from '@mescius/wijmo.undo';
import {
  ACTION_HEADER_TABLE,
  SCOPE,
  CATEGORY,
  METHOD_3,
  BLANK_ID,
  APPROVAL_TYPE,
  APPROVAL_STATUS,
  CANCEL_TYPE,
  ORGANIZATIONAL_DATA,
  MESSAGE_NOT_NULL, MESSAGE_INCORRECT_YEAR, MESSAGE_POSITIVE_NUMBER,
  CURRENCY_TYPE, GICS_PULLDOWN,
  FILTER_DATA, DATA_TYPE
} from '@/constants/registerData';
import webStorage from '@/utils/webStorage';
import { makeNewId, getListDbAddNew, getDbAddNewInObject } from '@/concerns/utils/master-or-customize-db';
import { getDefaultMethods } from "@/utils/updateDataBeforeRedirect";
import store from '@/store';
import * as wjGrid from '@mescius/wijmo.grid';
import { AutoComplete } from '@mescius/wijmo.input';
import description from '@/components/newRegisterData/description';
import ExportReportPopup from '@/components/dialogs/export-report-popup.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';
import SaveTemplateDataPopup from '@/components/dialogs/ActionHeaderTable/save-template-data-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ApprovalPopup from '@/components/dialogs/approval-popup';
import AcceptCancelPopup from '@/components/dialogs/accept-cancel-popup';
import CommonButton from '@/components/utils/button.vue';
import MonthSelectionPc from '@/components/utils/monthSelectionPc.vue';
import RadioPopup from '@/components/dialogs/radio-popup';
import { DESCRIPTIONS } from '@/constants/descriptions';
import { ROUTES } from '@/router/constants';
import {
  getListDetailBranch,
  getListData,
  getListDbCustomize,
  getListMasterDb,
  updateListData,
  getSummaryScore,
  getMonthExistDataSummaryScore
} from '@/api/registerData';
import { updateShowPopupNotificationPcaf } from '@/api/auth';
import { getStartMonth } from '@/api/duration';
import { getScopesAndCategories } from "@/api/emissionCalculations";
import { mapGetters, mapActions, mapState } from 'vuex';
import { formatDateTime, rangeDateFormat } from '@/utils/datetimeFormat';
import { STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_FIELD, STATUS_CANCEL_SUBMMITED } from '@/constants/status';
import { ROLE } from '@/constants/role';
import { KEYS_CODE } from '@/constants/keyboard';
import moment from 'moment';
import {
  updateTemplateData,
  deleteTemplate,
  approvalDataAction,
  cancelSubmittedData,
  saveDraftAction,
  submitDataAction,
  getCertification
} from '@/api/newRegisterData';
import { getCsvDataAction } from '@/api/newRegisterData';
import _ from 'lodash';
import { getErrorMessge } from '@/utils/messageHandle';
import { formatValue, math, formatBigNumber, $_helper_isNumberType, $_helper_isNumberType_bySetting, formatNumberBySetting, removeSpecialChars, preventKeydownNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { convertCsvToJson, convertCsvToJsonNew, handlePastingCell } from '@/utils/csvImportHandler';
import { calcYearFromStartMonthCustome, getMonthListCustome, getFirstMonthByOrder, convertMonthCalendar, prepareBranchData, findRowNumPattern, isHideColumnEmissionPcaf, isHideColValueNumber, getListGicsByYear,
  getPatternFileImport, defineMethodListLayer1, defineMethodListLayer2
 } from '@/utils/registerData';
import { formatNumberByConditions, addThoundSandCommaWithTwoDigit, formatNumberWithSeparators } from '@/utils/convertNumber';
import { getRangeFiscalYear } from '@/utils/fiscalyear';
import BasicUnitPopup from '@/components/dialogs/basic-unit-popup';
import ExternalSourcePopup from '@/components/dialogs/external-source-popup';
import ServiceLinkSourcePopup from '@/components/dialogs/service-link-popup';
import EvidenceStoragePopup from '@/views/emissions/EvidenceStorage/index.vue';
import { scopeCategorySourcesData, scopeCategoriesData } from '@/constants/export-report';
import { prepareMasterOrCustomizeDB } from '@/concerns/utils/master-or-customize-db';
import {getAllDbSource, dbIdeaObj} from '@/api/ideaDbSource';
import { getAllDbServiceLink, getServicesLinked, dbServiceLinkObj } from '@/api/serviceLink';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import throttle from 'lodash/throttle'
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth'
import { ACTION_CODE } from '@/constants/rightClickAction.js';
import { GridEditAction } from "@/views/managements/wijmo-extends";
import debounce from 'lodash/debounce'
import { toolTipCustom } from "@/utils/tooltipCustom";
import { businessGroupList } from '@/api/group-enterprise';
import i18n from '@/lang/i18n.js';
import { DB_TYPE } from '@/constants/dbType';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
import ReadOcrService from '@/views/emissions/ReadOcrService';
import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf';
import { getDataTypeApi } from '@/api/pcaf';
import ViewEmissionService from '@/views/emissions/view-emission.vue';
import { getViewingEmissionList } from '@/api/registerData';
import { getAutoApprovalSetting } from '@/api/autoApproval';
import { getListBasicUnit } from '@/api/basic-unit.js';
import { handlerFilterData, handlerAddFilterData, addFilterByPasted } from '@/concerns/utils/filter-data'
export default {
  components: {
    description,
    CommonButton,
    RadioPopup,
    MonthSelectionPc,
    ErrorPopup,
    DataTable,
    SubmitDataPopup,
    SaveTemplateDataPopup,
    NotificationPopup,
    QuestionPopup,
    ApprovalPopup,
    AcceptCancelPopup,
    ExportReportPopup,
    EvidenceStoragePopup,
    ServiceLinkSourcePopup,
    ReadOcrService,
    ViewEmissionService,
    BasicUnitPopup,
    ExternalSourcePopup
  },
  data() {
    return {
      textNoti: null,
      isRegisterNew:false,
      isUpdated: false,
      currentSelected: 1,
      flex: null,
      data: [],
      isHasMethod: true,
      exportDialog: false,
      method: null,
      listMethodsAtive:[],
      methodSize: 1,
      methodList: [],
      scope: 1,
      category: 1,
      partern: null,
      year: 2022,
      curentYear: null,
      curentMonth: null,
      // month: null,
      message: this.$t("register_data.popup_message_content_invalid"),
      selectedDuration: null,
      selectDurationChange: null,
      startYear: null,
      endYear: null,
      startMonth: null,
      endMonth: null,
      curentIndexDuration: 0,
      durationList: [],
      selectMonthYearChange: null,
      monthYearSelectionList: [],
      radioDialog: false,
      radioType: this.$t("register_data.popup_select_method"),
      isUpdateMonthToStartMonth: false,
      radioDialogLayer2: false,
      methodListLayer2: [],
      methodLayer2: null,
      currentSelectedLayer2: null,
      isErrorFilterData: false,
      registerWorkflow: {},
      isHasData: false,
      dataPattern: {},
      workFlowData: {},
      layerData: [],
      // pattern-table variables
      listDataPattern: [],
      header: null,
      pattern: null,
      dataTable: null,
      init: () => {},
      perPage: 5,
      order: 'id',
      openDialogEvidenceStorage: false,
      dialogReadFile: false,
      itemReadFile: {},
      typeFormEvidenceStorage: 'view',
      onlyViewEvidence: false,
      listNumberItems: [
        'industrial_waste_value',
        'te_value',
        'value',
        'employees_number_value',
        'working_days_number_value',
        'loading_data_maximum',
        'loading_data_rate',
        'distance',
        'fuel_efficiency',
        'energy_value',
        'sales_volume_value',
        'scale_index_value',
        'dir_value',
        'qd_value',
        'energy_saving_value',
        'scope12_overlap',
        'aoa_value',
        'cpa_value',
        'transport_weight_value',
        'sea_distance_value',
        'travel_value',
        'iss_value',
        'ir_internal_investment',
        'ir_total_investment',
        'ie_company_value',
        'ir_calculated_denominator',
        'ir_calculation_numerator',
        'government_bond_emissions',
        'gdp_ppp_adjusted',
        'loan_amount'
      ],
      key: 0,
      isConvert: false,
      listDataActions: [],
      undoStack: null,
      canUndo: false,
      canRedo: false,
      actionCount: 0,
      timeoutUndoStack: false,
      lastedRow: null,
      flexgrid: null,
      originalData: [],
      listRowFields: [
        'business_name',
        'company_name',
        'db_master_id',
        'duration_id',
        'memo_1',
        'memo_2',
        'month',
        'organizational_division',
        'qd_source',
        'qd_unit',
        'qd_value',
        'supplier_name',
        'type',
        'wsu_source',
        'wsu_unit',
        'wsu_value',
        'year',
      ],
      layer: [],
      layerIndex: {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      },
      submitDataDialogPopup: false,
      saveTemplateDataDialogPopup: false,
      warningTemplateDataDialogPopup: false,
      dialogNotification: false,
      isShowPopupUnCheckPermission: false,
      messageNotification: '',
      isHasDataTable: false,
      itemsInView: [],
      dataTitle: null,
      isHasErrorData: false,
      dialogQuestion: false,
      messageQuestion: this.$t("register_data.description_text_confirm"),
      confirmQuestionText: this.$t('register_data.keep'),
      isSaveTemplateButton: false,
      dialogApproval: false,
      dialogCancelSubmit: false,
      listStatusApproval: [],
      redirectUrl: '',
      isCloneTemplate: false,
      totalEmissions: 0,
      isReadOnly: false,
      durationText: '',
      totalTreatment: {
        incinerator: 0,
        landfill: 0,
        recycling: 0,
        unknown: 0,
      },
      isGetAllData: false,
      templateTitle: null,
      isSubmitData: false,
      approvalForm: {},
      isReloadPage: true,
      messageWarningTemplate: this.$t("register_data.popup_message_warning_unshare_template"),
      isDisplayDescription: false,
      listStatusCancel: [],
      dialogAcceptCancel: false,
      isEditDataTable: false,
      messagePopupCsv: '',
      isShowPopupCsv: false,
      lengthCsv: 0,
      exportDialogKey: 0,
      itemErrorCount: 0,
      exportDialogKey: 0,
      latestRecord: {},
      closeText: '',
      selectedFilterColumn: null,
      durationOtherSetting: [],
      keySetting: 0,
      dialogQuestionChangeMethod: false,
      messageChangeMethod: this.$t("popup.popup_change_method"),
      originalDataPattern: [],
      selectedMonth: null,
      selectedYear: null,
      monthList: [],
      yearList: [],
      settingPcafBySelectMonth: false,
      durationRegistered: {},
      summaryScoreIsRegistered: {},
      serviceLinkDialog: false,
      selectedRow: null,
      isOverTextMethodAndEmissions: null,
      isOverTextMethodFlex: null,
      branchData: {},
      isChangeMethod: false,
      dialogDeleteDataApproval: false,
      messageDialogDeleteDataApproval: '',
      masterDb: [],
      temFlexGrid: null,
      detailExternalSource: null,
      objSourceIdea: {},
      objSourceService: {},
      evidences: {},
      pattern_id_evidence: null,
      isChangeEvidence: false,
      itemsChangeEvidence: {},
      isInitGrid: false,
      itemsChangeStatus: {},
      isChangeStatus: false,
      dialogStatusPopup: false,
      stackUndoWhenUncheck: null,
      hdrTips: new wjcCore.Tooltip({
        position: wjcCore.PopupPosition.Above,
        showAtMouse: true,
        showDelay: 600,
        cssClass: "hdr-tip"
      }),
      businessGroupList: {},
      isOpenScoreSummary: false,
      groupObj: {},
      isLimitedAttributionFactor: false,
      summaryScoreType: 1,
      isBinddingEmissionFactor: false,
      isCheckedFilterData: false,
      isCheckedError: false,
      listIdDetail: [],
      basicUnitDialog: false,
      isCheckLang: i18n.locale,
      dbExternalAdded: {
        [DB_TYPE.IDEA]: [],
        [DB_TYPE.SERVICE_LINK]: [],
        [DB_TYPE.MASTER]: [],
        [DB_TYPE.CUSTOMIZE]: [],
      },
      dbMasterOriginal: [],
      listServicesLinked: [],
      codeProductValue: null,
      onlyViewedServicesLinked: false,
      itemSelectPCAF: {
        itemsCategoryType: [],
        itemsDataType: [],
        categoryIdSelect: null,
        dataTypeIdSelect: null,
      },
      dialogQuestionChangeTypeIdSelect: false,
      messageChangeTypeIdSelect: this.$t("pcaf_update.popup_change_data_type"),
      isChangeSelectTypePCAF: false,
      isShowCheckbox: false,
      isDeleteTingEvent: false,
      socketAccessInterval: null,
      listUsersAccessTemplateData: [],
      isSearchEmissionView: false,
      viewEmissionData: {},
      isFlagUpdateDataListEmission: false,
      periodData: {},
      currentDuration: null,
      itemsInViewError: [],
      isEditTitleApprovedData: false,
      isShowUserApproval: true,
      gicsList: {},
      oldYearSelected: null,
      dbMasterAddNew: [],
      lastPastingRow: 0,
      uniqueOriginalDb: [],
      getDbCustomizeOrDbMasterByWsuSource: {},
      getDbCustomizeOrDbMasterById: {},
      getDbCustomizeOrDbMasterByItemName: {},
      getDbCustomizeOrDbMasterByNameSource: [],
      getDbCustomizeByItemName: {},
      getDbCustomizeByIdSource: {},
      isAddIdeaDb: false,
      basicUnitListDefault: [],
      basicUnitData: {},
      basicUnitFilterDataDefault: {},
      externalSourceDialog: false,
      uniqueOriginalAllDbUnit: [],
      selectedValuePulldown: {},
      rawDataOnCheckError: {},
    };
  },
  async mounted() {
    getAllDbSource();
    getAllDbServiceLink();
    // this.getListBasicUnitDefault()
    this.handleUpdateMethodByUrl();
    await this.handleGetServicesLinked();
    await this.handleGetSettingWorkFollow();
    const monthRes = await getStartMonth(this.$store.state.userData.contractor)
    this.startMonth = monthRes.data.start_month
    const fileCsvName = sessionStorage.getItem('fileCsvName');
    this.isLimitedAttributionFactor = sessionStorage.getItem('attribution_factor_setting') === '1' ? true : false;
    const isHasFlagImport = this.$route.query?.import;
    if(this.$route.path?.includes('/emissions/view')) {
      if (this.$route.query?.year) {
        this.selectedYear = Number(this.$route.query?.year);
      } else {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        let currentYear = currentDate.getFullYear();
        if (this.startMonth > currentMonth) {
          currentYear = currentYear - 1;
        }
        this.selectedYear = currentYear;
      }
      if (this.$route.query?.month) {
        this.selectedMonth = Number(this.$route.query?.month);
      } else {
        this.selectedMonth = this.startMonth;
      }
      if (this.$route.query?.order) {
        this.selectedMonth = Number(getFirstMonthByOrder(moment, this.startMonth, this.$route.query?.order));
      }
    }
    this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.selectedYear);
    this.monthList = getMonthListCustome(this.yearSelected, this.startMonth); 


    if(!fileCsvName && isHasFlagImport) {
      this.$router.replace({ path: this.$route.path }) // clear flag import in router
    }
    if(this.$route.fullPath.includes('/view/score-summary')) {
      this.isOpenScoreSummary = true
      if(this.$route.query?.selectedMonth && this.$route.query?.selectedYear) {
        this.selectedMonth = this.$route.query?.selectedMonth ? Number(this.$route.query?.selectedMonth) : null
        this.selectedYear = this.$route.query?.selectedYear ? Number(this.$route.query?.selectedYear) : null
        this.$router.replace({ path: this.$route.path })
      }
    } else {
      this.isOpenScoreSummary = false
    }
    this.listNumberItems = [
      'value', 'loading_data_maximum', 'loading_data_rate',
      'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
      'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
      'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
      'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
      'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
      'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
      'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value','wsu_value','emissions',
      'il_amount', 'il_total_amount', 'ie_ghg_emissions', 'dq_quality_score', 'revenue', 'purchase_level', 'government_bond_emissions','gdp_ppp_adjusted',
      'loan_amount', 'amount', 'total_capital', 'value_number_1', 'value_number_2', 'value_purchase', 'value_number', 'emissions_factor'
    ];
    if (this.$route.fullPath.includes('/emissions/view/')) {
      this.isGetAllData = true;
      this.method = this.$store.state.newRegisterData.methodLayer1Id;
      this.methodLayer2 = this.$store.state.newRegisterData.methodLayer2Id;
      if(this.isSettingPcaf && this.category === 15) {
        this.method += 4
      }
    }
    if(this.isSettingPcaf) {
      const contractorData = this.$store.state.newRegisterData.pcafSelectedDetail[this.$store.state.userData.contractor];
      const { categoryIdSelect, dataTypeIdSelect } = contractorData || {};
      const setPCAFProperties = (key, value) => {
        this.itemSelectPCAF[`${key}Select`] = value;
        this.itemSelectPCAF[`${key}SelectTmp`] = value;
        this.itemSelectPCAF[`${key}SelectOriginal`] = value;
      };

      setPCAFProperties('categoryId', categoryIdSelect);
      setPCAFProperties('dataTypeId', dataTypeIdSelect);
    }
    if (
      [
        ROUTES.LIST_EMISSION_SUBMITTED,
        ROUTES.LIST_EMISSION_TEMPLATES,
        ROUTES.LIST_EMISSION_TEMPORARY,
        ROUTES.LIST_APPROVAL,
        ROUTES.LIST_MENU_EMISSION,
        ROUTES.PRIMARY_DATA_MERGE_DETAIL
      ].includes(this.$route.params?.scope)
    ) {
      let workFollowDataId = { id: this.$route.params?.category };
      this.actionUpdateIsLoading(true)
      // CALL API GET scope and category, title, pattern_id by data Id
      try {
        this.isHasData = true;
        await this.getListDataPattern(workFollowDataId).then((res) => {
          // this.dataPattern = res;
          if (!this.isApproved) {
            this.dataPattern.data = res.data;
            this.originalDataPattern = res.data;
            this.dataPattern.latest_record = res.latest_record;
            this.latestRecord = res.latest_record;
          }
          this.listIdDetail = res.data?.map(item => item.id)
          this.dataPattern.unCheckPermission = res?.check_permission;
          this.dataPattern.disabledByPermission = res?.has_permission_view;
          this.dataPattern.db_source_detail = res?.db_source_detail;
          this.dataPattern.service_product_package_link = res?.service_product_package_link;
          this.dataPattern.workflow_data = res.workflow_data;
          this.dataPattern.layer = res.layer;
          this.dataPattern.db_master = {};
          this.dataPattern.db_customize = {};
          this.dataPattern.branch = {};
          this.dataPattern.external_source = {};

          const dbCustomAddNew = res.db_customize.map(item => {
            return {
              ...item,
              type: 0,
              db_type: 0,
              source: item.item_name
            }
          })

          this.dbMasterAddNew = res.db_master.map(item => {
            return {
              ...item,
              type: 1,
              db_type: 1,
              source: item.name_basic
            }
          }).concat(dbCustomAddNew)

          if(res.data[0]) {
            this.isLimitedAttributionFactor = res.data[0]?.is_in_approx
          }
          res.db_master.forEach(item => {
            this.dataPattern.db_master[item.id] = { ...item, db_type: 1, type: 1, source: item.unit_basic };
          })

          res.db_master_old?.forEach(item => {
            if(!this.dataPattern.db_master[item.id]) {
              this.dataPattern.db_master[item.id] = { ...item, db_type: 1, type: 1, source: item.unit_basic };
            }
          })
          res.db_customize.forEach(item => {
            this.dataPattern.db_customize[item.id] = { ...item, db_type: 0, type: 0, source: item.item_name };
          })
          res.branch.forEach(item => {
            this.dataPattern.branch[item.id] = item;
          })
          res.db_source_detail.forEach(item => {
            this.dataPattern.external_source[item.id] = item;
            this.objSourceIdea[item.id] = item
          })
         
          let dataWorkFollow = res.workflow_data;
          this.workFlowData = dataWorkFollow;
          const setPCAFProperties = (key, value) => {
            this.itemSelectPCAF[`${key}Select`] = value;
            this.itemSelectPCAF[`${key}SelectTmp`] = value;
            this.itemSelectPCAF[`${key}SelectOriginal`] = value;
          };

          setPCAFProperties('categoryId', dataWorkFollow?.type_classify);
          setPCAFProperties('dataTypeId', dataWorkFollow?.type_data);
          this.scope = parseInt(dataWorkFollow.scope);
          this.category = parseInt(dataWorkFollow.category);
          if (this.scope === 3 && this.category === 1) {
            res.service_product_package_link.forEach(item => {
              const source = item.source;
              this.dataPattern.external_source[item.product_package_code] = {...item, id: item.product_package_code, item_name: source, source: source,  type: DB_TYPE.SERVICE_LINK}
              this.objSourceService[item.product_package_code] = item
            })
          }
          if(this.scope === 3 && this.category === 15) {
            this.settingPcafBySelectMonth = this.planType?.is_pcaf;
          }
          this.registerWorkflow = {
            scope: this.scope,
            category: this.category,
            pattern_id: dataWorkFollow.pattern_id,
            title: dataWorkFollow.title,
            contractor_id: this.currentUser.user.contractor_id,
            data_id: dataWorkFollow.data_id,
            id: dataWorkFollow.id,
          };
          this.selectedYear = dataWorkFollow.status === STATUS_FIELD.STATUS_TEMPLATE ? null : dataWorkFollow.year;
          this.selectedMonth = dataWorkFollow.status === STATUS_FIELD.STATUS_TEMPLATE ? null : dataWorkFollow.month;
          if (this.currentUser?.user?.role_id !== ROLE.USER && (dataWorkFollow.status === STATUS_SUBMIT || dataWorkFollow.status === STATUS_CANCEL_SUBMMITED)) {
            this.isShowPopupUnCheckPermission = this.dataPattern.unCheckPermission;
          }
          if (
            (dataWorkFollow.unread_flg &&
              dataWorkFollow.is_approver === 1 &&
              [STATUS_SUBMIT, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) || // cal API if user is approver
            (dataWorkFollow.unread_flg &&
              dataWorkFollow.is_owner === 1 &&
              [STATUS_RETURN, STATUS_REPRENSENT_RETURN].includes(dataWorkFollow.status)) // call API if user is owner
          ) {
            if ([STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) {
              const params = {
                id: dataWorkFollow.id,
              };
              this.setNotification(params).then(() => {
                this.getNotification();
              });
            }
          }
        });
      } catch (error) {
        this.actionUpdateIsLoading(false)
        switch (this.$route.params?.scope) {
          case ROUTES.LIST_EMISSION_TEMPLATES:
            this.$router.push({ path: '/emissions/list-templates' });
            break;
          case ROUTES.LIST_MENU_EMISSION:
            this.$router.push({ path: '/emissions' });
            break;
          case ROUTES.LIST_EMISSION_SUBMITTED:
            this.$router.push({ path: '/emissions/list-submitted' });
            break;
          case ROUTES.LIST_APPROVAL:
            this.$router.push({ path: '/approval' });
            break;
          default:
            break;
        }
      }
    } else if (this.$route.params?.scope && isNaN(parseInt(this.$route.params?.scope)) && !this.$route.fullPath.includes('/score-summary')) {
      return this.$router.push('/404');
    } else {
      // TODO: Remove hard code by send in query
      // Payload in register data by click new register button
      this.scope = parseInt(this.$route.params?.scope) || 1;
      this.category = parseInt(this.$route.params?.category) || 1;
      if(this.$route.fullPath.includes('/score-summary')) {
        this.scope = 3
        this.category = 15
      }
      this.isRegisterNew= true;
      this.registerWorkflow = {
        scope: this.scope,
        category: this.category,
        pattern_id: 1,
        title: 'Title create new',
        contractor_id: this.currentUser.user.contractor_id,
      };
      // Reset register data empty
      await this.$store.dispatch('newRegisterData/setListDataPatternInit', {});
    }
    if(!this.isEmissionViewScreen && !this.$route.fullPath.includes('/score-summary')) {
      this.selectedMonth = this.workFlowData?.status === STATUS_FIELD.STATUS_TEMPLATE ? null : Number(this.month),
      this.selectedYear = this.workFlowData?.status === STATUS_FIELD.STATUS_TEMPLATE ? null : Number(this.yearSelected);
    }
    this.$store.dispatch('registerData/updateListDbCustomize', [], { root: true });
    this.isDisplayDescription = true
    // await this.getListDurations();
    // Call API check disable scope 3 or not
    if (this.$route.query?.duration) {
      this.selectDurationChange = parseInt(this.$route.query?.duration)
    }
    await getScopesAndCategories({ contractor_id: this.contractor, display_scope_3_flg: 1 })
        .then((response) => {
          this.$store.dispatch('dashboard/setIsEnableScope3', response?.display_scope_3)
          if (!response.display_scope_3 && this.scope === 3) {
            return this.$router.push('/404');
          }
        })
        .catch(() => {});

    if(!this.selectMonthYearChange) {
      await getListDetailBranch().then((res) => {
        this.branchData = prepareBranchData(res)
        this.layerData = res.layer;
      });
    }

    this.getApproversList();
    this.actionShowPopup(false);
    this.actionUpdateIsFullScreen(false);
    this.isUpdated = false;

    this.curentYear = new Date().getFullYear();
    this.curentMonth = new Date().getMonth() + 1;

    this.getMethodList(this.scope, this.category);
    if (!this.methodLayer1Id && !this.methodLayer2Id) {
      await getDefaultMethods(this.scope, this.category);
    }

    if(!this.method) {
      this.method = this.methodLayer1Id;
    }
    this.currentSelected = this.method;
    this.getMethodListLayer2();

    if(!this.methodLayer2) {
      this.methodLayer2 = this.methodLayer2Id;
    }
    this.keySetting ++
    this.currentSelectedLayer2 = this.methodLayer2;
    this.updateBreadCrumb(this.breadcrumbOption);
    if (this.dataPattern?.workflow_data?.id && this.dataPattern?.workflow_data?.template_type !== null) {
      this.selectMonthYearChange = null;
      this.selectedYear = null;
      this.selectedMonth = null;
    } else {
      this.getSelectedMonth();
    }
    this.updateMonthYearSelected();
    if(!this.isEmissionViewScreen && !this.dataPattern?.workflow_data?.id) {
      this.handleCheckInputDuration()
    }
    this.isErrorFilterData = false;
    this.$store.dispatch('registerData/updateListErrorFilterDataMessage', '', { root: true });
    // pattern-table
    this.actionUpdateIsClearUndoStack(false);
    this.$emit('updateIsUpdated', false);
    this.updateIsUpdated(false);
    this.updateActionName(''); // update action name
    this.actionUpdateStatusBtn({ undo: false, redo: false });
    this.updateUndoRedoClick('');
    this.updateUndoStack(null);
    this.updateUndoRedoStackData({
      undo: [],
      redo: [],
    });
    this.$store.dispatch('registerData/updateDataList', []);
    this.$store.dispatch('registerData/updateStatusIsUpdateData', false);
    this.$store.dispatch('registerData/updateTotalEmission', 0);
    this.$store.dispatch('registerData/updateTotalDurationEmission', 0);
    this.$store.commit('registerData/updateEnergyType', this.energyTypes_list);
    // this.$store.dispatch('registerData/updateLatestRecord', {});
    this.actionAllowAddNew(!this.isApproval);

    const listRouteGetData = [
      'emissions/register/approval',
      'emissions/register/primary-data',
      'emissions/register/list-menu',
    ]
    const isNotApprovalOrMenu = listRouteGetData.every(path => !this.$route.path?.includes(path))
    const isEmissionView = this.$route.path?.includes('emissions/view'); // TODO: screen emissions/view called (getDataFromScope) at checkPcafInDurtionSelect so block called
    if ((!this.isGetAllData && isNotApprovalOrMenu || !this.isApproved) && !isEmissionView) {
      await this.getHeaderFromScope();
      this.getDataFromScope();
    }
    if(!this.$route.query?.import) {
      this.isHasDataTable = this.isHasData
    }
    if (this.$route.fullPath.includes('/emissions/view/3/15')) {
      if(this.isSettingPcaf) {
        const getFirstItemId = (items) => {
          return items.length ? items[0].id : null;
        }
        this.itemSelectPCAF.categoryIdSelect = getFirstItemId(this.itemSelectPCAF.itemsCategoryType);
        this.itemSelectPCAF.dataTypeIdSelect = getFirstItemId(this.itemSelectPCAF.itemsDataType);
      }
    }
    webStorage.remove('duration');
    if (this.isShowNotiPcaf && (this.isRegisterNew && !this.isGetAllData) && this.isSettingPcaf && this.scope === 3 && this.category === 15 && !this.$route.query?.import) {
      this.isShowCheckbox = true;
      this.dialogNotification = true;
      this.textNoti = this.$t("pcaf_update.button_notification_data_quality_score")
      this.messageNotification = this.$t("pcaf_update.message_notification_data_quality_score");
    }
	if (this.theTemplateIsShared && !this.dataPattern?.workflow_data?.is_owner) {
		this.socketAccessInterval = setInterval(() => {
			this.$socket.emit('accessedDetailTemplate', this.dataPattern?.workflow_data?.id);
		}, 1000);
	}
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoading', 'actionUpdateIsLoadingTable']),
    ...mapActions('emission', ['selectDuration']),
    ...mapActions('registerData', [
      'updateDataScope',
      'updateDataCategory',
      'updateDataMonth',
      'updateDataMethod',
      'updateDuration',
      'updateYearSelected',
      'updateStartMonth',
      'updateIsUpdated',
      'actionUpdateIsFullScreen',
      'actionShowPopup',
      'updateDataMethodLayer2',
      // pattern-table
      'updateUndoRedoClick',
      'actionUpdateIsClearUndoStack',
      'updateUndoStack',
      'actionAllowAddNew',
      'updateUndoRedoStackData',
      'updateOrderNumberDontHavePermissions',
      'actionsUpdateCertifications'
    ]),
    ...mapActions('newRegisterData', [
      'getListDataPattern',
      'addRegisterDataWorkFollow',
      'updateMethodLayer1Id',
      'updateMethodLayer2Id',
      'updateIsEditData',
      'updatePcafDetail'
    ]),
    ...mapActions('workflowData', ['setNotification', 'getNotification']),
    ...mapActions('approval', ['getApproversList']),
    ...mapActions('actionsTable', ['actionUpdate', 'actionUpdateStatusBtn', 'updateActionDelete', 'updateActionName']),
    ...mapActions("userData", ["updateMethodSetting"]),
	handleDeleteData() {
		this.onHandleActionTable(ACTION_HEADER_TABLE.DELETE_DATA, this.dataTitle, this.checkHasUserAccessTemplateDetail)
	},
	handleSaveData() {
		this.onHandleActionTable(ACTION_HEADER_TABLE.SAVE_TEMPRORA, this.dataTitle)
	},
    handleApplyData() {
      if (this.isDateInvalid) {
        this.dialogNotification = true;
        this.messageNotification = this.$t("register_data.popup_message_require_month_year");
        return;
      }
      this.onHandleActionTable(ACTION_HEADER_TABLE.SUBMIT_DATA, this.dataTitle)
    },
	updateDataTitle(data) {
		this.dataTitle = data;
    if(data !== this.workFlowData?.title && this.isApproval) {
      this.isEditTitleApprovedData = true
    } else {
      this.isEditTitleApprovedData = false
    }
	},
    async updateShowPopup(value) {
      try {
        const data = {
          is_notification_pcaf: value ? 0 : 1
        }
        await updateShowPopupNotificationPcaf(data)
      } catch (error) {
        console.warn(error);
      }
    },
    toolTipOn(item, pulldownOption = false) {
      let textContainer = document.createElement("span");
      textContainer.style.fontFamily = 'Source Han Sans';
      textContainer.style.fontSize = '14px';
      textContainer.style.fontWeight = '400';
      textContainer.style.letterSpacing = '0.42px';
      textContainer.style.fontStyle = 'normal';
      textContainer.style.lineHeight = '24px';
      textContainer.style.position = 'absolute';
      textContainer.style.whiteSpace = 'nowrap';
      textContainer.style.visibility = 'hidden';
      textContainer.innerHTML = item;

      document.body.appendChild(textContainer);
      const width = textContainer.clientWidth;
      document.body.removeChild(textContainer);
      const ele = document.getElementById('tooltip-column');
      ele.innerHTML = item;
      if (pulldownOption) {
        let element = document.querySelector('.pulldown-datatype');
        let style = window.getComputedStyle(element);
        let widthPulldown = style.width;
        let valueCompare = parseFloat(widthPulldown) - 56;
        if (width > valueCompare) {
          ele.style.display = 'flex';
        }
      } else {
        let element = document.querySelector('#datatype-view');
        let style = window.getComputedStyle(element);
        let widthPulldown = style.width;
        let valueCompare = parseFloat(widthPulldown) - 34;
        if (width > valueCompare) {
          ele.style.display = 'flex';
        }
      }
    },
    toolTipOff() {
      const ele = document.getElementById('tooltip-column');
      ele.style.display = 'none';
    },
    updateTooltipPosition(event, pulldownOption = false) {
      const ele = document.getElementById('tooltip-column');
      if (pulldownOption) {
        const parent = document.getElementById('dropdown-show').getBoundingClientRect();
        ele.style.left = 134 + 'px';
        let top = window.innerWidth > 1024 ? 120 : 260;
        ele.style.top = event.clientY - (parent.y - top) + 'px';
        ele.style.width = window.innerWidth - event.clientX - 50 + 'px';
      } else {
        const parent = document.getElementById('datatype-view').getBoundingClientRect();
        let top = window.innerWidth > 1024 ? 240 : 450;
        ele.style.left = window.innerWidth > 1024 ? 160 + 'px' : 20 + 'px';
        ele.style.top = event.clientY - (parent.y - top) + 'px';
        ele.style.width = window.innerWidth - event.clientX - 50 + 'px';
      }
    },
    convertFuelOption(fuel){
      if(!fuel) return null;
      const listOldFuel= [
        {scope: 1, category: 1, method: 4 },
        {scope: 3, category: 4, method: 1, methodLayer2: 6 },
        {scope: 3, category: 9, method: 1, methodLayer2: 3 }
      ]
      const isNoReplace= listOldFuel.find(pattern=>
      {
        if(this.scope == 1) {
          return pattern.scope == this.scope && pattern.category == this.category && pattern.method == this.method
        }else if( this.scope==3){
          return pattern.scope == this.scope && pattern.category == this.category && pattern.method == this.method && pattern.methodLayer2 == this.methodLayer2
        }else{
          return false
        }

      })
      const isOldFuelLabel= ['ガソリン', '軽油', 'その他'].includes(fuel)
      if(isOldFuelLabel && isNoReplace) {
        return  fuel
      }else
      return ['ガソリン', '自動車輸送 ガソリンの使用'].includes(fuel) ?
          "自動車輸送 ガソリンの使用"
          :"自動車輸送 軽油の使用"
    },
    async getCategoryPCAF() {
      try {
        const res = await getDataTypeApi(this.method + 4)
        this.itemSelectPCAF.itemsDataType = res.data;
      } catch (error) {
        console.warn(error);
      }
    },
    getDataCategoryPCAF() {
      if(this.scope === 3 && this.category === 15 && this.isShowNotePcaf) {
        this.itemSelectPCAF.itemsCategoryType = this.method === 1 ? PCAF_CATEGORY_TYPE_METHOD_1 : this.method === 2 ? PCAF_CATEGORY_TYPE_METHOD_2 : [];
        this.itemSelectPCAF.itemsCategoryType = this.itemSelectPCAF.itemsCategoryType?.map(item => {
          return {
            id: item?.id,
            name: item?.name,
            isShowIcon: false
          }
        })
      }
    },
    async handleChangeSelect(key, newValue) {
      const tmpKey = `${key}Tmp`;
      const contractorKey = key === 'categoryIdSelect' ? 'categoryIdSelect' : 'dataTypeIdSelect';
      const dataPCAF = this.itemSelectPCAF;
      dataPCAF.keyCurrent = key;
      dataPCAF.valueCurrent = newValue;
      if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT) {
        if ((newValue !== dataPCAF[tmpKey] && newValue !== dataPCAF[`${key}Original`]) || !this.isDraftNotChangeMethod()) { // case: category OR data type selected change
          this.dialogQuestionChangeTypeIdSelect = true;
        } else if (dataPCAF.categoryIdSelect === dataPCAF.categoryIdSelectOriginal && 
                   dataPCAF.dataTypeIdSelect === dataPCAF.dataTypeIdSelectOriginal) { // case: category AND data type select change original value
          dataPCAF[tmpKey] = newValue;
          this.resetChangesSelectToOriginal();
        } else { // case: category OR data type change original value
          dataPCAF[tmpKey] = newValue;
          // keep old data table & change quality score
          this.resetOnlyQualityScore();
          this.refreshTable();
        }
      } else {
        if (this.isGetAllData) {
          await this.getUrlFromScopeCategory();
          this.refreshTable();
        }
        else {
          this.updateSelect(key, newValue);
        }
      }
    },
    async handleGetSettingWorkFollow() {
      try {
        const response = await getAutoApprovalSetting()
        this.isShowUserApproval = !response.data.auto_approve
      } catch (error) {
        console.warn(error);
      }
    },
    confirmChangeSelectType() {
      const key = this.itemSelectPCAF.keyCurrent;
      const value = this.itemSelectPCAF.valueCurrent;     
      this.itemSelectPCAF[key] = value;
      this.itemSelectPCAF[`${key}Tmp`] = value; // set tmp value

      this.setFieldsToNullSelectTypes();
      this.refreshTable();
      this.dialogQuestionChangeTypeIdSelect = false;
      this.isChangeSelectTypePCAF = true;
    },
    cancelChangeSelectType() {
      const key = this.itemSelectPCAF.keyCurrent;
      this.dialogQuestionChangeTypeIdSelect = false;
      this.itemSelectPCAF[key] = this.itemSelectPCAF[`${key}Tmp`]; //set value = to tmp variable when cancelled
    },
    resetOnlyQualityScore() {
      const scoreVal = this.pattern.getQualityScore(this.itemSelectPCAF.dataTypeIdSelect, this.itemSelectPCAF);
      this.dataPattern.data = this.originalDataPattern.map((item) => {
        return { ...item, dq_quality_score: scoreVal, status: true, id: item['id']};
      });
    },
    resetChangesSelectToOriginal() {
      this.$nextTick(() => {
        this.itemSelectPCAF.categoryIdSelect = this.itemSelectPCAF.categoryIdSelect;
        this.itemSelectPCAF.dataTypeIdSelect = this.itemSelectPCAF.dataTypeIdSelect;
        if(this.isDraftNotChangeMethod()) { // IF not changed method: set data table to original
          this.dataPattern.data = this.originalDataPattern;
        }
        this.refreshTable();
        this.isChangeSelectTypePCAF = false;
      });
    },
    updateSelect(key, val) {
      this.itemSelectPCAF[key] = val;
      this.pattern.setFilterIndex({})
      this.refreshTable();
    },
    setFieldsToNullSelectTypes() {
     const itemsSpecials = [
       "branch",
       "business_name",
       "company_name",
       "organizational_division",
       'layer_1',
       'layer_2',
       'layer_3',
       'layer_4',
       'layer_5',
       'layer_6',
       "country",
     ];
     this.dataPattern.data = this.originalDataPattern.map((item, index) => {
       const modifiedItem = {};
       for (const key in item) {
         if (item.hasOwnProperty(key)) {
           modifiedItem[key] = itemsSpecials.includes(key) ? item[key] : null;
          }
        }
        const everyNullBranches = itemsSpecials.every(key => modifiedItem[key] === null || modifiedItem[key] === undefined || modifiedItem[key] === '');
        if(!everyNullBranches) {
          modifiedItem['dq_quality_score'] = this.pattern.getQualityScore(this.itemSelectPCAF.dataTypeIdSelect, this.itemSelectPCAF);
        }
       const modifiedItemResult = { ...modifiedItem, status: true, id: item['id']};
       return modifiedItemResult;
     });
    },
    refreshTable() {
      this.getHeaderFromScope();
      this.getDataFromScope();
      this.key++;
    },
    getCategoryName(items, id) {
      const category = items.find(item => item.id === id);
      return category ? category.name : '';
    },
    getSubstringRange(columnName){
      return ['fuel_efficiency', 'loading_data_rate', 'pj_investment_ratio'].includes(columnName) ? 10: 25
    },
    onChangeStyleActionTable(value) {
      this.isOverTextMethodAndEmissions = value
    },
    onChangeStyleMethodFlex(value) {
      this.isOverTextMethodFlex = value
    },
    initTheTable(flexGrid) {
      this.temFlexGrid = null
      this.temFlexGrid = flexGrid
      if (this.category === 14 || this.scope === 4) {
        const dataProps = {
          flexgrid: flexGrid,
          itemCount: this.isApproval ? 0 : 100,
          branchData: this.branchData,
          dbStore: this.getDbStore,
          businessGroupList: this.businessGroupList,
          isLimitedAttributionFactor: this.isLimitedAttributionFactor,
          isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
          itemSelectPCAF: this.itemSelectPCAF,
          getNewBbStore: this.getNewBbStore,
          handleDataOnCheckError: this.handleDataOnCheckError,
          getDataRowOnCheckError: this.getDataRowOnCheckError
        }

        this.pattern.initializeGrid(dataProps);
      }
      document.getElementById('monthDropdown')?.addEventListener('change', () => {
        this.selectedMonth = document.getElementById('monthDropdown').value;
        flexGrid.invalidate();
      });
      flexGrid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel === s.columnHeaders) {
          // if (colBinding === 'basic_unit_search') {
          //   e.cell.innerHTML = this.$t('basic_unit_library.label_search_basic_unit');
          // }
          if (e.panel.columns[e.col].cssClassAll?.includes('required-field') && e.cell.classList.contains("wj-filter-off")) {
            e.cell.innerHTML = '<span class="required-field">*</span>' + e.cell.innerHTML;
          }
        }
        if(this.isViewOnlyAttachFile) {
          if (e.panel == s.cells && colBinding === 'attach_file') {
            const rowData = s.rows[e.row].dataItem
            const evidence = rowData?.evidence
            const isDisableByStatus = !rowData?.status && this.isApproval
            if (!evidence || isDisableByStatus) {
              wjcCore.addClass(e.cell, "btn-disabled");
            }
          }
        }

        if(e.panel == s.cells && colBinding === 'read_file') {
          const rowData = s.rows[e.row].dataItem;
          const itemEnergyType = this.getDbCustomizeOrDbMasterById[rowData?.energy_type];
          const isDisableByStatus = !rowData?.status && this.isApproval;
          if(!rowData?.energy_type || !itemEnergyType?.ocr_type || isDisableByStatus) {
            wjcCore.addClass(e.cell, "btn-disabled");
          } 
        }

        if(e.panel == s.cells && colBinding === 'basic_unit_search') {
          const rowData = s.rows[e.row].dataItem;
          const isSelectedMonthYear  = this.selectedMonth && this.selectedYear;
          const isDisableByStatus = (!rowData?.status && this.isApproval) || this.workFlowData?.status === STATUS_FIELD.STATUS_SUBMIT || this.workFlowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED;
          if(isDisableByStatus || !isSelectedMonthYear && !this.$route.path.includes('/list-templates')) {
            wjcCore.addClass(e.cell, "btn-disabled");
          } 
          else {
            wjcCore.removeClass(e.cell, 'btn-disabled');
          }
        }

        if (e.panel == s.cells && !this.isApproval && colBinding === 'status') {
          // wjcCore.addClass(e.cell, "is-admin-read-only");
          const rowData = s.rows[e.row].dataItem
          const isHideStatusCell = !rowData?.id || rowData?.id === BLANK_ID
          if(isHideStatusCell) {
            e.cell.innerHTML = null
          } else {
            wjcCore.addClass(e.cell, "is-admin-read-only");
          }
        }

        if (e.panel == s.cells && this.isApproval && colBinding === 'status' && this.currentUser?.user?.role_id !== ROLE.ADMIN) {
          wjcCore.addClass(e.cell, "is-admin-read-only");
        }

        if (e.panel == s.cells && this.isApproval && colBinding === 'status' && (!this.workFlowData.is_active && this.workFlowData?.id)) {
          wjcCore.addClass(e.cell, "is-admin-read-only");
        }

        if (e.panel == s.cells && this.isApproval && colBinding !== 'status' && !this.isOpenScoreSummary) {
          const rowData = s.rows[e.row].dataItem
          const isDisable = !rowData?.status
          const isPrimaryData = rowData?.is_primary_data
          const evidence = rowData?.evidence
          if(isDisable || (isPrimaryData && colBinding !== 'attach_file')) { // change style if row data is primary_data or has status false
            wjcCore.addClass(e.cell, "is-admin-read-only");
            if(colBinding === 'attach_file') {
              wjcCore.addClass(e.cell, "btn-disabled");
            }
          }
          else if (colBinding === 'attach_file' && isPrimaryData && !evidence) { 
            wjcCore.addClass(e.cell, "btn-disabled");
          }
        }

        // add tooltip for status column
        if (e.panel === s.columnHeaders) {
          if (colBinding === "status") {
            toolTipCustom(
              e,
              "t1",
              this.$t('data_enable.tooltip_status'),
              this.hdrTips
            );
          }
          if (colBinding === "basic_unit_search" && e.cell.innerText === this.$t('basic_unit_library.label_search_basic_unit')) {
            toolTipCustom(
              e,
              "t2",
              this.$t('basic_unit_library.tooltip_basic_unit_search'),
              this.hdrTips
            );
          }
        }
        if(e.panel == s.cells && e.panel.columns[e.col].binding === 'attach_file') {
          if (e.cell.querySelector('button') && e.cell.querySelector('img')) {
            const img = e.cell.querySelector('img');
            let fileAttached = 'file_attached.svg';
            if (img) {
              if (this.isApproval) {
                const rowData = s.rows[e.row].dataItem;
                if (rowData['status'] === true && rowData['changed_columns']?.evidence) {
                  fileAttached = 'file_attached_approved.svg';
                  if (e.cell.querySelector('div')) {
                    e.cell.querySelector('.file-attached-container').classList.add('approved');
                  }
                } else if (!rowData['status']) {
                  fileAttached = 'file_attached_disabled.svg';
                }
              } else {
                fileAttached = 'file_attached.svg';
              }
              img.setAttribute('src', require(`@/assets/icons/${fileAttached}`));
              e.cell.querySelector('button').addEventListener('mouseenter', function () {
                img.setAttribute('src', require('@/assets/icons/file_attached_active.svg'));
              })
              e.cell.querySelector('button').addEventListener('mouseleave', function () {
                img.setAttribute('src', require(`@/assets/icons/${fileAttached}`));
              })
            }
          }
        }
      });
      this.actionUpdateIsLoadingTable(false);
      flexGrid.hostElement?.addEventListener(
        'keydown',
        (event) => {
          if (event.keyCode === KEYS_CODE.ENTER && this.isApproval) {
            event.stopImmediatePropagation();
          }
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.KEY_V) {
              this.actionUpdateIsLoadingTable(true)
              const { row, row2} = flexGrid.selection
              const isSingleRowPasted = row2 === row
              if(isSingleRowPasted) {
                setTimeout(() => {this.actionUpdateIsLoadingTable(false)}, 300)
              }
            }
          }
        },
        false,
      );

      let dataScope = scopeCategorySourcesData
      if(this.settingPcafBySelectMonth) {
        dataScope[dataScope.length - 1].dbKey = 'source'
      }
      const itemKey = dataScope.find(item => {
        if (
          (item.scope === undefined || item.scope === this.scope) &&
          (item.category === undefined || item.category === this.category) &&
          (item.method === undefined || item.method === this.method) &&
          (item.methodLayer2 === undefined || item.methodLayer2 === this.methodLayer2)
        ) {
          return item;
        }
      })
      const isDontUseDbItem = !this.planType?.is_pcaf && [15, '15'].includes(this.category)
      const autoFillColumns = this.pattern?.autoFields
      const sourceColumn = this.pattern?.valueSource

      flexGrid.pastingCell.addHandler((s, e) => {
        const colBinding = flexGrid.columns[e.col].binding
        const noAllowPasting = sourceColumn && autoFillColumns.includes(colBinding) && colBinding !== sourceColumn
        if(noAllowPasting) { // block pasting auto fill column
          e.cancel = true;
        }

        if(!itemKey || isDontUseDbItem) return
        if (itemKey.data === flexGrid.columns[e.col].binding) {
          const dbItem = this.getDbCustomizeByItemName[e.data]
          const dbItemBySource = this.getDbCustomizeOrDbMasterByWsuSource[e.data] 
          if(dbItemBySource || dbItem || !e.data) return

          if(!this.isAddIdeaDb) {
            // update header with all item in one time & will update in pasted event
            this.isAddIdeaDb = true
            this.handleUpdateHeader({ useAllDbItem: true })
          }
          const getDbStore = {
            dbExternalAdded: this.dbExternalAdded
          }
          const propsHeader = {
            dbStore: getDbStore,
            grid: this.flexgrid,
            rowIndex: e, 
            item: e.data, 
            basicUnitData: this.basicUnitData,
            category: this.category
          }
          handlePastingCell(propsHeader);
        }
      })
      flexGrid.pasting.addHandler((s, e) => {
        this.lastPastingRow = e._rng.row2
      })
    },
    handleUpdateHeader(props = {}) {
      this.flexgrid.columnGroups = this.getHeaderTable(props);
    },
    handleClosePopup() {
      this.actionShowPopup(false);
      this.$store.dispatch('registerData/updateStatusIsUpdateData', true);
    },
    handleClosePopupError() {
      this.isShowPopupUnCheckPermission = false;
    },
    updateIsUpdate() {
      this.updateIsUpdated(true);
    },
    formatNumber(num, isAlowMore25digit = false) {
      const addThoundSandCommaNumber = addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, isAlowMore25digit);
      return formatNumberWithSeparators(addThoundSandCommaNumber);
    },
    formatNumberRealNum(num) {
      return formatNumberByConditions(num, { isRealNumber: true, isAlowMore25digit: false });
    },
    closeRadioPopup() {
      this.radioDialog = false;
      this.radioDialogLayer2 = false;
    },
    backtoLayer1() {
      this.radioDialogLayer2 = false;
      this.radioDialog = true;
    },
    onChangeRadio(newValue) {
      // this.method = newValue;
      this.closeRadioPopup();
    },
    getSelectedDurationText() {
      return this.$t('register_data.year_calendar_select', {year: this.selectedYear});
    },
    getSelectedMonthText() {
      return this.$t('register_data.month_select', { month: convertMonthCalendar(this.selectedMonth)});
    },

    // implement 13022
    getSelectedMonthYearText() {
      if (this.selectedMonth && this.selectedYear) {
      return this.$t('emissions_detail.label_total_emissions_month_year', {year: this.selectedYear, month: convertMonthCalendar(this.selectedMonth)});
      }
      return this.$t('emissions_detail.label_total_emissions_month_year_empty');
    },
    getMonthSelectionList(startAt, endAt, duration_registered = []) {
      let durationStartAt = new Date(startAt);
      let durationEndAt = new Date(`${endAt}-02`);

      let timeFormat = durationStartAt.toLocaleString('ja-JP', {
        month: 'long',
        year: 'numeric',
      });

      let newRegisterDuration = duration_registered.map((yearMonth) => `${yearMonth.year}年${yearMonth.month}月`);

      this.monthYearSelectionList = [
        {
          text: timeFormat,
          value: durationStartAt.getMonth() + 1,
          duration_registered: newRegisterDuration.find((yearMonth) => yearMonth === timeFormat),
        },
      ];

      while (durationStartAt.setMonth(durationStartAt.getMonth() + 1) < durationEndAt) {
        let localeString = durationStartAt.toLocaleString('ja-JP', {
          month: 'long',
          year: 'numeric',
        });

        let currentMonthYear = {
          text: localeString,
          value: durationStartAt.getMonth() + 1,
          duration_registered: newRegisterDuration.find((yearMonth) => yearMonth === localeString),
        };
        this.monthYearSelectionList.push(currentMonthYear);
      }
    },
    getMethodList(scope, category) {
      this.methodSize = 1;
      this.methodList = [];
      const methodListData = defineMethodListLayer1({
        scope,
        category,
        settingPcafBySelectMonth: this.settingPcafBySelectMonth
      });
      this.methodSize = methodListData.methodSize;
      let { listMethodName, description } = methodListData;

      for (let index = 1; index <= this.methodSize; index++) {
        this.methodList.push({
          text: listMethodName[index - 1],
          value: index,
          id: index,
          name: listMethodName[index - 1],
          description: description[index - 1],
        });
      }
    },
    getMethodListLayer2() {
      let { methodSize, startIndex, listMethodName, description } = defineMethodListLayer2({
        scope: this.scope,
        category: this.category,
        currentSelected: this.currentSelected
      })
      if (listMethodName.length === 0 && description.length === 0) {
        // check condition when scope/category dont have layer2
        return;
      }
      this.methodSize = methodSize;
      this.methodListLayer2 = [];
      for (let index = startIndex; index <= methodSize; index++) {
        this.methodListLayer2.push({
          text: listMethodName[index - startIndex],
          value: index,
          id: index,
          name: listMethodName[index - startIndex],
          description: description[index - startIndex],
        });
      }
      this.keySetting ++
    },
    submitRadioPopup() {
      if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT) {
        if (
          this.isLayer2()
        ) {
          this.radioDialog = false;
          this.method = this.currentSelected;
          this.isUpdated = false;
          if(this.methodLayer2) this.currentSelectedLayer2 = this.methodLayer2;
          this.radioDialogLayer2 = true;
          this.getMethodListLayer2();
        } else if(!this.isDraftNotChangeMethod()) {  // is changed layer then open dialog
          this.dialogQuestionChangeMethod = true;
        } else { // change method to initial method : only layer 1
          this.handleNormalSubmission();
          if (this.isShowNotePcaf && this.isChangeSelectTypePCAF) { 
            this.setFieldsToNull();
          }
        }
      } else {
        this.handleConfirmRadioPopup();
      }
    },
    submitChangeMethod() {
      this.dialogQuestionChangeMethod = false;
      if (this.isLayer2()) {
        this.handleLayer2Submission();
      } else {
        this.handleNormalSubmission();
      }
      this.setFieldsToNull();
      this.keySetting ++;
    },

    handleConfirmRadioPopup() {
      this.radioDialog = false;
      this.method = this.currentSelected;
      if (this.isLayer2()) {
        this.isUpdated = false;
        this.radioDialogLayer2 = true;
        this.getMethodListLayer2();
      } else {
        this.isUpdated = true;
      }
    },
    submitLayer2RadioPopup() {
      if (!this.isDraftNotChangeMethod()) { // is changed layer then open dialog
        this.dialogQuestionChangeMethod = true;
      } else {
        this.handleLayer2Submission();
      }
      this.keySetting ++
    },
    isLayer2() {
      return (
        (this.scope === 3 && this.category === 4 && this.currentSelected === 1) ||
        (this.scope === 3 && this.category === 4 && this.currentSelected === 2) ||
        (this.scope === 3 && this.category === 9) ||
        (this.scope === 3 && this.category === 11 && this.currentSelected === 1)
      );
    },
    isDraftNotChangeMethod() {
      if (!this.isLayer2()) {
        this.currentSelectedLayer2 = null;
      }
      const method_1 = this.setPatternId(this.category, this.scope, this.currentSelected , null, true);
      const method_2 = this.setPatternId(this.category, this.scope,  this.currentSelected, this.currentSelectedLayer2, true);
      const pattern_id  = this.dataPattern?.workflow_data?.pattern_id;
      const isMethodOnlyValid = !this.currentSelectedLayer2 && method_1 === pattern_id;
      const isMethodLayer2Valid = method_2 === pattern_id;
      if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT) {
        return isMethodOnlyValid || isMethodLayer2Valid;
      } else return true;
    },
    setFieldsToNull() {
      this.isChangeMethod = true;
      if (this.isDraftNotChangeMethod() && !this.isChangeSelectTypePCAF) {
        this.dataPattern.data = this.originalDataPattern;
      }
      else {
        const itemsSpecials = [
          "branch",
          "business_name",
          "company_name",
          "organizational_division",
          'layer_1',
          'layer_2',
          'layer_3',
          'layer_4',
          'layer_5',
          'layer_6',
          "country",
        ];
        this.dataPattern.data = this.originalDataPattern.map(item => {
          const modifiedItem = {};
          for (const key in item) {
            if (item.hasOwnProperty(key)) {
              modifiedItem[key] = itemsSpecials.includes(key) ? item[key] : null;
            }
            if (this.isShowNotePcaf) {
              const everyNullBranches = itemsSpecials.every(key => modifiedItem[key] === null || modifiedItem[key] === undefined || modifiedItem[key] === '');
              if(!everyNullBranches) {
                modifiedItem['dq_quality_score'] = 1; // Changed method always takes the first ID of category and data type; default value for quality score is 1.
              }
            }
          }
          return { ...modifiedItem, status: true, id: item['id'] };
        });
      }
    },
    handleLayer2Submission() {
      if (this.isDraftNotChangeMethod() && !this.isChangeSelectTypePCAF) { // change method to initial method
        this.dataPattern.data = this.originalDataPattern;
      }
      if (!this.currentSelectedLayer2) {
        this.currentSelectedLayer2 = this.methodListLayer2[0].value;
      }
      this.isUpdated = true;
      this.radioDialog = false;
      this.radioDialogLayer2 = false;
      this.methodLayer2 = this.currentSelectedLayer2;
    },
    handleNormalSubmission() {
      if (this.isDraftNotChangeMethod() && !this.isChangeSelectTypePCAF) { // change method to initial method
        this.dataPattern.data = this.originalDataPattern;
      }
      this.radioDialog = false;
      this.method = this.currentSelected;
      this.isUpdated = true;
    },
    handleClose() {
      this.$store.dispatch('registerData/updateIsErrorStatus', false);
      this.$store.dispatch('registerData/updateStatusIsUpdateData', true);
      this.$store.dispatch('registerData/updateListErrorMessage', []);
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    openSelectPopup() { 
      // if (this.dataPattern?.workflow_data?.id) {
      //   return;
      // }
      this.radioDialog = true;
      if(this.method) this.currentSelected = this.method;
      this.getMethodList(this.scope, this.category);
    },
    handleCloseFilterData() {
      this.isErrorFilterData = false;
      this.$store.dispatch('registerData/updateListErrorFilterDataMessage', '', { root: true });
      this.$store.dispatch('registerData/updateIsErrorStatus', false);
      this.$store.dispatch('registerData/updateStatusIsUpdateData', true);
      this.$store.dispatch('registerData/updateListErrorMessage', []);
    },
    getObjDbMaster() {
      const dataList = this.masterDb
      // get list item do not has in list getDbCustomizeOrDbMasterByNameSource
      const dataListAddNew = getListDbAddNew(this.dbExternalAdded)

      if(dataListAddNew.length <= 0) { // loop all db in first render
        for (const item of dataList) {
          this.getDbCustomizeOrDbMasterByNameSource.push(item.source)
          this.getDbCustomizeByIdSource[item.id] = !this.getDbCustomizeByIdSource[item.id] ? {...item} : this.getDbCustomizeByIdSource[item.id];
          this.getDbCustomizeByItemName[item.item_name] = !this.getDbCustomizeByItemName[item.item_name] ? {...item} : this.getDbCustomizeByItemName[item.item_name];
        }
      } else {
        for (const item of dataListAddNew) { // only loop items_add (IDEA,..)
          this.getDbCustomizeOrDbMasterByNameSource.push(item.source)
          this.getDbCustomizeByIdSource[item.origin_id] = !this.getDbCustomizeByIdSource[item.origin_id] ? {...item} : this.getDbCustomizeByIdSource[item.origin_id];
          this.getDbCustomizeByItemName[item.item_name] = !this.getDbCustomizeByItemName[item.item_name] ? {...item} : this.getDbCustomizeByItemName[item.item_name];
        }
      }
    },
    resetObjectDbUnit() {
      this.getDbCustomizeOrDbMasterByWsuSource = {}
      this.getDbCustomizeOrDbMasterById = {}
      this.getDbCustomizeOrDbMasterByItemName = []
      this.getDbCustomizeByIdSource = {}
      this.getDbCustomizeByItemName = {}
      this.getDbCustomizeOrDbMasterByNameSource = []
    },
    getCustomeObjDbMaster() {
      const mapBySource = new Map();
      const mapById = new Map();
      const mapByItemName = new Map();

      // only loop dbExternalAdded in case pasting IDEA in source, to push custom data in list
      const listDataAddNew = getListDbAddNew(this.dbExternalAdded)
      const numberDataAddNew = listDataAddNew?.length
      if(!numberDataAddNew) {
        this.resetObjectDbUnit()
      }
      const dataList = numberDataAddNew > 0 ? listDataAddNew : this.dbCustomizesOrDbMasters
      for (const item of dataList) {  // use for off to improve performance
        if (!mapBySource.has(item.source)) {
          mapBySource.set(item.source, {...item});
        }

        if (!mapById.has(item.id)) {
          mapById.set(item.id, {...item});
        }

        if (!mapByItemName.has(item.item_name)) {
          mapByItemName.set(item.item_name, {...item});
        }
      }
      this.getDbCustomizeOrDbMasterByWsuSource = { ...this.getDbCustomizeOrDbMasterByWsuSource, ...Object.fromEntries(mapBySource)};
      this.getDbCustomizeOrDbMasterById = { ...this.getDbCustomizeOrDbMasterById, ...Object.fromEntries(mapById)};
      this.getDbCustomizeOrDbMasterByItemName = { ...this.getDbCustomizeOrDbMasterByItemName, ...Object.fromEntries(mapByItemName)};
    },
    async getDetailMasterDb(paramData) {
      const { db_types, scope_id, category_id, method_id } = paramData
      const masterData = await getListMasterDb({
        db_types: db_types,
        contractor_id: this.currentUser.user.contractor_id,
        scope_id: scope_id,
        category_id: category_id,
        method_id: method_id,
        status: true,
        workflow_data_id: this.dataPattern?.workflow_data?.id || null
      });
      
      let masterDbCustom = masterData?.masterDb.map(item => {
        return {
          ...item,
          source: this.pattern?.isUseBasicUnit ? item.unit_basic : item.source,
          item_name: this.pattern?.isUseBasicUnit ? item.unit_basic : item.item_name,
        }
      })

      masterDbCustom = masterData?.masterDb.concat(this.dbMasterAddNew)
      this.masterDb = masterDbCustom
      this.dbExternalAdded =  {
        [DB_TYPE.IDEA]: [],
        [DB_TYPE.SERVICE_LINK]: [],
        [DB_TYPE.MASTER]: [],
        [DB_TYPE.CUSTOMIZE]: [],
      },
      this.dbMasterOriginal = [...masterDbCustom]
      this.uniqueOriginalDb = prepareMasterOrCustomizeDB(this.dbMasterOriginal)
      this.getCustomeObjDbMaster()
      this.getObjDbMaster()
    },
    async getMasterDb() {
      if(this.scope === 1 && (this.method === 1 || this.method === 2)) {
        await this.getDetailMasterDb({
          db_types: [1],
          contractor_id: this.currentUser.user.contractor_id,
          scope_id: this.scope,
          category_id: 0,
          method_id: this.method === 1 ? 4 : 1,
          status: true,
        });
      }

      if(this.scope === 1 && (this.method === 3 || this.method === 4)) {
        await this.getDetailMasterDb({
          db_types: [3],
          contractor_id: this.currentUser.user.contractor_id,
          scope_id: this.scope,
          category_id: 0,
          method_id: this.method === 3 ? 3 : 2,
          status: true,
        });
      }

      if(this.scope === 2) {
        await this.getDetailMasterDb({
          db_types: [1, 2],
          contractor_id: this.currentUser.user.contractor_id,
          scope_id: this.scope,
          category_id: 0,
          method_id: 0,
          status: true,
        })
      }
      if (this.scope === 3) {
        let methodId = this.method
        if(this.method === 1) {
          methodId = 2
        } else if(this.method === 3) {
          methodId = 1
        } else if(this.method === 2) {
          methodId = 3
        }

        switch (this.category) {
          case 1:
          case 2:
          case 3:
          case 15:
            //if pcaf get db customize, ghg get masster db
            if(this.isShowNotePcaf) {
              await this.getDataWithDbCustomize();
            } else {
              await this.getDetailMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: 0,
                status: true,
              })
            }
            
            break;
          case 4:
            if(this.methodLayer2 === 4 || this.methodLayer2 === 7) {
              await this.getDetailMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 5 || this.methodLayer2 === 6) {
              await this.getDetailMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2 === 6 ? 5 : 6,
                status: true,
              });
            }

            if(this.methodLayer2 === 8 ) {
              await this.getDetailMasterDb({
                db_types: [2],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }
            break;
          case 6:
            if(this.method === 1) {
              await this.getDetailMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: methodId,
                status: true,
              });
            } else {
              await this.getDetailMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: methodId,
                status: true,
              });
            }
            break;
          case 7:
            if(this.method === 1) {
              await this.getDetailMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: methodId,
                status: true,
              });
            } else {
              await this.getDetailMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: methodId,
                status: true,
              });
            }
            break;
          case 9:
            if(this.methodLayer2 === 1 || this.methodLayer2 === 4) {
              await this.getDetailMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 5) {
              await this.getDetailMasterDb({
                db_types: [2],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 2 || this.methodLayer2 === 3) {
              await this.getDetailMasterDb({
                db_types: [3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2 === 2 ? 3 : 2,
                status: true,
              });
            }
            break;
          case 10:
            await this.getDetailMasterDb({
              db_types: [1, 2],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 5:
          case 12:
            await this.getDetailMasterDb({
              db_types: [3, 4],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 8:
          case 13:
            await this.getDetailMasterDb({
              db_types: [1, 2, 3],
              contractor_id: this.currentUser.user.contractor_id,
              scope_id: this.scope,
              category_id: this.category,
              method_id: 0,
              status: true,
            });
            break;
          case 16:
            await this.getDataWithDbCustomize();
            break;
          case 11:
            if(this.methodLayer2 === 1) {
              await this.getDetailMasterDb({
                db_types: [1, 2, 3],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 2) {
              await this.getDetailMasterDb({
                db_types: [1],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: this.methodLayer2,
                status: true,
              });
            }

            if(this.methodLayer2 === 3) {
              await this.getDataWithDbCustomize();
            }

            if(this.method === 2) {
              await this.getDetailMasterDb({
                db_types: [1, 2],
                contractor_id: this.currentUser.user.contractor_id,
                scope_id: this.scope,
                category_id: this.category,
                method_id: 4,
                status: true,
              });
            }
          default:
            break;
        }
      }
    },
    updateMonthYearSelected() {
      if (!this.selectedMonth && !this.selectedYear) {
        this.updateDataMonth(null);
        this.updateYearSelected(null);
        return;
      }
      if(this.monthList.findIndex(item => item.value === this.selectedMonth) < 0) {
        this.selectedMonth = ''
      }
      this.updateDataMonth(moment(this.selectedMonth, 'M').format('M'));
      this.updateYearSelected(moment(this.selectedYear, 'YYYY').format('YYYY'));
    },
    getSelectedMonth() {
      if (this.dataPattern?.workflow_data?.id && this.dataPattern?.data && this.dataPattern?.data.length > 0) {
        this.selectMonthYearChange = this.dataPattern?.data[0]?.month;
      } else {
        this.selectMonthYearChange = this.month;
      }
    },
    async getUrlFromScopeCategory(workflowDataId = null) {
      let scope = this.$store.state.registerData.newScope;
      let category = this.$store.state.registerData.category;
      const month = this.$store.state.registerData.month;
      const year = this.$store.state.registerData.yearSelected;
      if ((!Number(month) || !Number(year)) && !this.$route.path.includes('emissions/view')) return;
      let method = this.$store.state.newRegisterData.methodLayer1Id;
      let methodLayer2 = this.$store.state.newRegisterData.methodLayer2Id;
      let params = `year=${year}&month=${month}&order=id`;
      if (this.workFlowData?.id) { //
        params += `&workflow_data_id=${this.workFlowData?.id}`;
      } else {
        scope = this.scope;
        category = this.category;
        method = this.method;
        methodLayer2 = this.methodLayer2;
      }
      if(this.$route.path.includes(ROUTES.PRIMARY_DATA_MERGE_DETAIL)) {
        params += `&is_detail_primary=1`;
      }
      if(this.settingPcafBySelectMonth && this.category === 15 && this.$route.path.includes('emissions/view')) {
        method += 4
      }
      if (this.settingPcafBySelectMonth && this.category === 15) {
        let typeData = null
        let categoryData = null
        if(this.$route.path.includes('emissions/view')) {
          typeData = this.itemSelectPCAF.itemsDataType.length <= 0 ? 1 : this.itemSelectPCAF?.dataTypeIdSelect
          categoryData = this.itemSelectPCAF.itemsDataType.length <= 0 ? 1 : this.itemSelectPCAF?.categoryIdSelect
        } else {
          typeData = this.itemSelectPCAF?.dataTypeIdSelect
          categoryData = this.itemSelectPCAF?.categoryIdSelect
        }

        if (typeData) {
          params += `&type_data=${typeData}`;
        }
        if (categoryData) {
          params += `&type_classify=${categoryData}`;
        }
      }
      let url = null;
      switch (scope) {
        case 1:
          method = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
          url = `/pattern-s${scope}/${method || 4}?${params}`;
          break;
        case 2:
          url = `/pattern-s${scope}?${params}`;
          break;
        case 3:
          switch (category) {
            case 4:
              if (method === 3) {
                url = `/pattern-s3-c4/2?${params}`;
              } else {
                methodLayer2 = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
                url = `/pattern-s3-c4/${methodLayer2 || 4}?${params}`;
              }
              break;
            case 6:
            case 7:
              method = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
              url = `/pattern-s${scope}-c${category}/${method || 1}?${params}`;
              break;
            case 9:
              methodLayer2 = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
              url = `/pattern-s3-c9/${methodLayer2 || 1}?${params}`;
              break;
            case 11:
              if (method === 1) {
                url = `/pattern-s3-c11/${methodLayer2 || 1}?${params}`;
              } else {
                url = `/pattern-s3-c11/4?${params}`;
              }
              break;
            case 15:
              if(this.settingPcafBySelectMonth) {
                url = `/pattern-s3-c15/${method|| 5}?${params}`;
              } else {
                let methods3c15 = method || 1
                url = `/pattern-s3-c15/${methods3c15}?${params}`;
              }

              if(this.dataPattern?.workflow_data?.id) {
                url = `/pattern-s3-c15/${this.dataPattern?.workflow_data?.pattern_id || 1}?${params}`;
              }
              break;
            case 16:
              url = `/pattern-s3-c16?${params}`;
              break;
            default:
              url = `/pattern-s${scope}-c${category}?${params}`;
              break;
          }
          break;
        case 4:
          url = `/pattern-s${scope}-c1?${params}`;
          break;
        default:
          url = `/pattern-s${scope}/${this.patternId}?${params}`;
          break;
      }

      if(this.isOpenScoreSummary === true) {
        const payload = {
          type: this.summaryScoreType,
          year: this.selectedYear,
          month: this.selectedMonth
        }
        await getSummaryScore(payload).then(res => {
          this.dataPattern.data = [];
          res.forEach(item => {
            this.dataPattern.data.push({
              ...item,
              classify_gics: GICS_PULLDOWN.find(gics => gics['key'] === item['classify_gics'])?.['value'] || '',
            })
          })
        })
        await getMonthExistDataSummaryScore().then(res => {
          this.monthList = getMonthListCustome(this.selectedYear, this.startMonth).map(item => {
            item.isShowIcon = res.data[this.selectedYear]?.indexOf(item.value) >= 0 ? true : false;
            return item
          });

          this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.selectedYear).map(item => {
            item.isShowIcon = res.data.hasOwnProperty(item.value) ? true : false;
            return item
          });
          this.summaryScoreIsRegistered = res.data
        })
      } else {
        this.actionUpdateIsLoading(true)
        let payload = url;
        let enPointApi = getListData;
        if(this.isSearchEmissionView) {
          enPointApi = getViewingEmissionList
          payload = this.viewEmissionData.payload
        }
        await enPointApi(payload).then((res) => {
        this.listIdDetail = res.data?.map(item => item.id)
        const customData = res.data.map(item => {
          return {
            ...item,
            isApprovalData: true
          }
        })
        this.dataPattern.data = customData;
        this.originalDataPattern = customData;
        this.dataPattern.latest_record = res.latest_record;
        this.latestRecord = res.latest_record;
        this.listMethodsAtive = [];
        this.durationRegistered = res.duration_registered;
        this.dataPattern.db_source_detail = res?.db_source_detail;
        this.dataPattern.service_product_package_link = res?.service_product_package_link;
        this.dataPattern.db_master = {};
        this.dataPattern.db_customize = {};
        this.dataPattern.branch = {};
        this.dataPattern.external_source = {};
        if(res.data[0]) {
          this.isLimitedAttributionFactor = res.data[0]?.is_in_approx
        }

        const dbCustomAddNew = res.db_customize.map(item => {
          return {
            ...item,
            type: 0,
            db_type: 0,
            source: item.item_name
          }
        })

        this.dbMasterAddNew = res.db_master.map(item => {
          return {
            ...item,
            type: 1,
            db_type: 1,
            source: item.name_basic
          }
        }).concat(dbCustomAddNew)

        res.db_master.forEach(item => {
          this.dataPattern.db_master[item.id] = { ...item, db_type: 1, type: 1, source: item.unit_basic };
        })
        res.db_master_old?.forEach(item => {
          if(!this.dataPattern.db_master[item.id]) {
            this.dataPattern.db_master[item.id] = { ...item, db_type: 1, type: 1, source: item.unit_basic };
          }
        })

        res.db_customize.forEach(item => {
          this.dataPattern.db_customize[item.id] = { ...item, db_type: 0, type: 0, source: item.item_name };;
        })
        res.branch.forEach(item => {
          this.dataPattern.branch[item.id] = item;
        })
        res.db_source_detail.forEach(item => {
          this.dataPattern.external_source[item.id] = item;
          this.objSourceIdea[item.id] = item
        })
        let dataWorkFollow = res.workflow_data;
        if (this.scope === 3 && this.category === 1) {
          res.service_product_package_link.forEach(item => {
            const source = item.sources;
            this.dataPattern.external_source[item.product_package_code] = {...item, id: item.product_package_code, item_name: item.package_name, source: source, type: DB_TYPE.SERVICE_LINK}
            this.objSourceService[item.product_package_code] = item
          })
        }
        if(this.isSearchEmissionView) return;
        this.monthList = getMonthListCustome(this.selectedYear, this.startMonth).map(item => {
          item.isShowIcon = res.duration_registered[this.yearSelected]?.indexOf(item.value) >= 0 ? true : false;
          return item
        });

        this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.selectedYear).map(item => {
            item.isShowIcon = res.duration_registered.hasOwnProperty(item.value) ? true : false;
            return item
        });
        if(res.hasOwnProperty('method_exits_data')){
          for(const exitData in res.method_exits_data) {
            if(res.method_exits_data[exitData]) {
              let existData = parseInt(exitData)
              const dataId = this.getMethodActiveByPatternId(this.category, this.scope, existData)
              if (this.scope === 3 && this.category === 15 && this.planType?.is_pcaf) {
                if ([5, 6].includes(existData)) {
                    let categoryExistData = {
                      0: false,
                      1: false,
                    };

                    let methodExistData = {
                      0: false,
                      1: false,
                    }

                    Object.values(res.method_exits_data[exitData]).forEach((categoryType, categoryTypeIndex) => {
                        Object.keys(categoryType).forEach((dataType) => {
                          if (categoryType[dataType]) {
                            methodExistData[categoryTypeIndex] = true;
                          }
                        })
                      })

                    if(this.method + 4 === existData) {
                      Object.values(res.method_exits_data[exitData]).forEach((categoryType, categoryTypeIndex) => {
                        Object.keys(categoryType).forEach((dataType, dataTypeIndex) => {
                          if (this.itemSelectPCAF.categoryIdSelect === (categoryTypeIndex + 1) && [1, 2].includes(this.method)) {
                            this.itemSelectPCAF.itemsDataType[dataTypeIndex].isShowIcon = categoryType[dataType];
                          }
                          if (categoryType[dataType]) {
                            categoryExistData[categoryTypeIndex] = true;
                          }
                        })
                        if ([1, 2].includes(this.method)) {
                          this.itemSelectPCAF.itemsCategoryType[categoryTypeIndex].isShowIcon = categoryExistData[categoryTypeIndex];
                        }
                      })
                    }
                    if (Object.values(methodExistData).some(exist => exist === true)) {
                      this.listMethodsAtive.push(dataId);
                    }
                  } else {
                  if (this.method === (exitData - 4)) {
                    let methodExistData = false;
                    Object.values(res.method_exits_data[exitData]).forEach((dataType, dataTypeIndex) => {
                      this.itemSelectPCAF.itemsDataType[dataTypeIndex].isShowIcon = dataType;
                      if (dataType) {
                        methodExistData = true;
                      }
                    })
                    if (methodExistData) {
                      this.listMethodsAtive.push(dataId);
                    }
                  } else {
                    if (Object.values(res.method_exits_data[exitData]).some(itemsDataType => itemsDataType === true)) {
                      this.listMethodsAtive.push(dataId);
                    }
                  }
                }
              } else {
                this.listMethodsAtive.push(dataId);
              }
            }
          }
        }
      });
      // setTimeout(() => this.actionUpdateIsLoading(false), 300)
      }
    },
    // pattern-table function
    getPatternIdCurrent() {
      if (this.scope !== 3) {
        delete this.registerWorkflow.category;
        this.registerWorkflow.pattern_id = this.method ? this.setPatternId(this.category, this.scope, this.method, this.methodLayer2) : 1;
      } else {
        if (this.category === 4) {
          if (this.method === 3) {
            this.registerWorkflow.pattern_id = 2;
          }
          if (this.methodLayer2 !== null) {
            this.registerWorkflow.pattern_id = this.methodLayer2;
          }
        } else if ([6, 7].includes(this.category)) {
          this.registerWorkflow.pattern_id = this.method;
        } else if (this.category === 9) {
          this.registerWorkflow.pattern_id = this.methodLayer2;
        } else if (this.category === 11) {
          if (this.method === 2) {
            this.registerWorkflow.pattern_id = 4;
          }
          if (this.methodLayer2 !== null) {
            this.registerWorkflow.pattern_id = this.methodLayer2;
          }
        } else if(this.category === 15) {
          if(!this.isDraftNotChangeMethod()) {
            this.registerWorkflow.pattern_id = this.settingPcafBySelectMonth ? this.method + 4 : this.method;
          } else if(this.workFlowData?.id) {
            this.registerWorkflow.pattern_id =  this.workFlowData.pattern_id
          } else {
            this.registerWorkflow.pattern_id = this.settingPcafBySelectMonth ? this.method + 4 : this.method;
          }
        } else {
          this.registerWorkflow.pattern_id = 1;
        }
        // TODO: Change display order
        if(this.scope === 1 || this.scope === 3 && !this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES) {
          this.registerWorkflow.pattern_id = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
        }
      }
    },
    onHandleActionTable(action, dataTitle, checkHasUserAccessTemplateDetail) {
      this.dataTitle = dataTitle;
      this.getPatternIdCurrent();
      // return;
      this.closeText = this.$t("register_data.button_cancel");

      switch (action) {
        case ACTION_HEADER_TABLE.SAVE_TEMPRORA:
          if (this.isDateInvalid) {
            this.dialogNotification = true;
            this.messageNotification = this.$t("register_data.popup_message_require_month_year");
            return;
          }
          if (
            this.dataPattern.workflow_data?.id &&
            this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE
          ) {
            // clone template from draft
            this.dialogQuestion = true;
            this.messageQuestion = this.$t("register_data.popup_message_save_as_new_register_data");
            this.confirmQuestionText = this.$t("register_data.button_save_new");
          } else {
            this.saveDraftDataHandler();
          }
          break;
        case ACTION_HEADER_TABLE.SAVE_TEMPLATE:
          if (checkHasUserAccessTemplateDetail) {
            this.warningTemplateDataDialogPopup = true;
          } 
          else {
            if (this.isDateInvalid) {
              this.dialogNotification = true;
              this.messageNotification = this.$t("register_data.popup_message_require_month_year");
              return;
            } 
            this.saveTemplateDataDialogPopup = true;
            this.isCloneTemplate = false;
          }
          break;
        case ACTION_HEADER_TABLE.DELETE_DATA:
          if (checkHasUserAccessTemplateDetail) {
            this.warningTemplateDataDialogPopup = true;
            this.messageWarningTemplate = this.$t("register_data.popup_message_delete_share_open_template");
            break;
          }
          this.dialogQuestion = true;
          this.confirmQuestionText = this.$t("register_data.button_delete");
          if (
            this.dataPattern?.workflow_data.id &&
            this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE
          ) {
            // handle remove data to template detail
            this.messageQuestion = this.$t("register_data.popup_message_delete_template");
          } else {
            this.messageQuestion = this.$t("register_data.popup_message_delete_register_data");
          }
          break;
        case ACTION_HEADER_TABLE.SUBMIT_DATA:
          if (this.isDateInvalid) {
            this.dialogNotification = true;
            this.messageNotification = this.$t("register_data.popup_message_require_month_year");
            return;
          }
          this.validateDataTable();
          let submitData = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
            this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
          );
          if (this.dataPattern.workflow_data?.id) {
            submitData = this.cloneDataHandler();
          }
          if(this.$route.query?.import) {
            submitData = this.getDataImport()
          }
          const isNotExistBranch = submitData.find((item) => !item.branch_id);
          if (isNotExistBranch) {
            const layerName = this.layer.map((layer) => {
              return layer.header;
            });
            this.isReloadPage = false;
            this.dialogNotification = true;
            this.messageNotification = this.$t('new_validate.error_mapping_layer', { listLayer: layerName?.join('/') })
            return;
          }
          if (!this.isHasErrorData && this.yearSelected !== null && this.month !== null) {
            this.submitDataDialogPopup = true;
          }
          break;
        case ACTION_HEADER_TABLE.OVERRIDE_TEMPLATE:
          if (checkHasUserAccessTemplateDetail) {
            this.warningTemplateDataDialogPopup = true;
            this.messageWarningTemplate = this.$t("register_data.popup_message_cannot_overwrite_template");
          } else {
            this.dialogQuestion = true;
            this.messageQuestion = this.$t("register_data.popup_message_question_save_template");
            this.confirmQuestionText = this.$t("register_data.button_keep");
          }
          break;
        case ACTION_HEADER_TABLE.CLONE_TEMPLATE:
          this.saveTemplateDataDialogPopup = true;
          this.isCloneTemplate = true;
          break;
        case ACTION_HEADER_TABLE.REVIEWING_DATA:
          this.dialogApproval = true;
          if (this.dataPattern.workflow_data.is_approver === 1) {
            this.listStatusApproval = [
              {
                id: APPROVAL_TYPE.APPROVE,
                name: this.$t("register_data.radio_approve"),
                description: this.$t("register_data.description_approve"),
              },
              {
                id: APPROVAL_TYPE.REJECT,
                name: this.$t("register_data.radio_reject"),
                description: this.$t("register_data.description_reject"),
              },
            ];
          } else {
            this.listStatusApproval = [
              {
                id: APPROVAL_TYPE.APPROVE,
                name: this.$t("register_data.radio_proxy_approve"),
                description: this.$t("register_data.description_proxy_approve"),
              },
              {
                id: APPROVAL_TYPE.REJECT,
                name: this.$t("register_data.radio_proxy_reject"),
                description: this.$t("register_data.description_proxy_reject"),
              },
            ];
          }
          break;
        case ACTION_HEADER_TABLE.CANCEL_SUBMITTED:
          this.dialogQuestion = true;
          this.messageQuestion = this.$t("register_data.popup_message_cancel_approve_request");
          this.confirmQuestionText = this.$t("register_data.button_cancel_request");
          break;
        case ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED:
          this.dialogAcceptCancel = true;
          this.listStatusCancel = [
            {
              id: CANCEL_TYPE.ACCEPT,
              name: this.$t("register_data.radio_accept_cancel_submit"),
              description: this.$t("register_data.description_accept_cancel_submit"),
            },
            {
              id: CANCEL_TYPE.REJECT,
              name: this.$t("register_data.radio_reject_cancel_submit"),
              description: this.$t("register_data.description_reject_cancel_submit"),
            },
          ];
          break;
        case ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA:
          this.validateDataTable();
          if (!this.isHasErrorData) {
            this.isFlagUpdateDataListEmission = this.isEmissionViewScreen ? true : false;
            this.dialogQuestion = true;
            this.messageQuestion =
              this.$t("register_data.popup_message_update_approved_data");
            this.confirmQuestionText = this.$t("register_data.button_keep");
            this.closeText = this.$t("register_data.button_close");
          }
          break;
        default:
          break;
      }
    },
    validateDataTable() {
      this.dataTable.getError = this.getError;
      const itemsInView = this.pattern.cellRequireInView(this.itemsInView);
      let errorData = [];
      const valueSource = this.pattern?.valueSource
      const valueAutoFill = this.pattern?.autoFields
      const isByPassvalidateSpecialCell = this.scope === 4
      this.dataTable.sourceCollection.forEach((item) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return true;
        }
        this.flexgrid.columns.forEach((column) => {
          if((item?.[valueSource] && valueAutoFill?.includes(column.binding)) || (isByPassvalidateSpecialCell && column.binding === 'dir_unit')) {
            errorData.push(null)
          } else {
            errorData.push(this.getError(item, column.binding));
          }
          return true;
        });
      });
      errorData = errorData.filter((item) => item !== null);
      if (errorData.length > 0) {
        this.isHasErrorData = true;
        this.isReloadPage = false;
        if (errorData.includes(MESSAGE_NOT_NULL) || errorData.includes(MESSAGE_INCORRECT_YEAR) || errorData.includes(MESSAGE_POSITIVE_NUMBER)) {
          this.dialogNotification = true;
          this.messageNotification = this.$t("register_data.popup_message_require_input");
        }
      } else {
        this.isHasErrorData = false;
      }
    },
    closeQuestionPopUp() {
      this.dialogQuestion = false
      this.isSaveTemplateButton = false;
    },

    submitAction() {
      if (this.confirmQuestionText === this.$t("register_data.button_delete")) {
        this.deleteAllDataHandler();
      } else if (this.confirmQuestionText === this.$t("register_data.button_keep")) {
        if (
          (this.dataPattern.workflow_data?.id &&
            (this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_APPROVED ||
              this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED)) ||
          this.isGetAllData
        ) {
          this.updateApprovedDataHandler();
        } else {
          this.updateTemplateDataHandler();
        }
      } else if (this.confirmQuestionText === this.$t("register_data.button_cancel_request")) {
        this.handleCancelSubmit();
      } else if (this.confirmQuestionText === this.$t("register_data.button_express")) {
        return this.$router.push({ path: '/emissions/list-templates' });
      } else if (this.confirmQuestionText === this.$t("register_data.button_save_new")) {
        this.saveDraftDataHandler();
      } else if (
        this.confirmQuestionText === this.$t('list_menu.label_decision') &&
        this.messageQuestion === this.$t("register_data.popup_message_duplicate_data") &&
        !this.isHasErrorData &&
        this.yearSelected !== null &&
        this.month !== null
      ) {
        this.dialogQuestion = false;
        this.isSubmitData = true;
        this.submitRegisterDataHandler();
      }
    },
    submitTemplateAction(templateTitle) {
      this.templateTitle = templateTitle;
      this.saveTemplateRegisterDataHandler();
    },
    updateApprovedDataHandler() {
      if (this.isGetAllData) {
        this.updateDataMonth(this.$store.state.registerData.month);
        this.updateYearSelected(this.yearSelected);
      } else {
        this.updateDataMonth(this.dataPattern?.workflow_data?.month);
        this.updateYearSelected(this.dataPattern?.workflow_data?.year);
      }
      const updatedData = this.editDataRegisterOnTable(this.dataTable, this.itemsInView, false, true);

      // check change evidence in item
      const dataChangeEvidences = Object.values(this.itemsChangeEvidence)
      const dataChangeStatus = Object.values(this.itemsChangeStatus)
      if(dataChangeEvidences.length > 0) {
        dataChangeEvidences.forEach((originItem, index) => {
          const isEditAnotherColumn = updatedData.findIndex(itemEdit => itemEdit.id === originItem.id) >= 0
          if(!isEditAnotherColumn) {
            const dataProps = {
              dataTable: this.dataTable._view[originItem.rowIndex],
              bindingLayout: this.itemsInView,
              isSaveTemplate: false,
              branchData: this.branchData,
              isGetBranchDetail: true,
              dbStore: this.getDbStore,
              isLimitedAttributionFactor: originItem?.is_in_approx,
              isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
              typeOfPattern: this.itemSelectPCAF
            }
            let dataTableItemCustom = this.pattern.handlerDataOnTable(dataProps);
            delete dataTableItemCustom.pcaf_business_group_name
            delete dataTableItemCustom.pcaf_business_name
            updatedData.push({ ...dataTableItemCustom, status: originItem.status ? 1 : 0, index: index + 1, branch_id_old: originItem.branch_id_old, business_group_id_old: originItem.business_group_id_old });
          }
        })
      }

      if(dataChangeStatus.length > 0) {
        dataChangeStatus.forEach((originItem, index) => {
          const isEditAnotherColumn = updatedData.find(itemEdit => itemEdit.id === originItem.id);
          if(!isEditAnotherColumn) {
            const dataProps = {
              dataTable: originItem,
              bindingLayout: this.itemsInView,
              isSaveTemplate: false,
              branchData: this.branchData,
              isGetBranchDetail: true,
              dbStore: this.getDbStore,
              isLimitedAttributionFactor: originItem?.is_in_approx,
              isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
              typeOfPattern: this.itemSelectPCAF
            }
            let dataTableItemCustom = this.pattern.handlerDataOnTable(dataProps);
            // delete dataTableItemCustom.pcaf_business_group_name
            // delete dataTableItemCustom.pcaf_business_name
            updatedData.push({ ...dataTableItemCustom, status: originItem.status ? 1 : 0, index: index + 1, branch_id_old: originItem.branch_id_old, business_group_id_old: originItem.business_group_id_old });
          } else {
            isEditAnotherColumn.status = originItem.status ? 1 : 0
          }
        })
      }
      const dataUpdateWithWorkFlowId = {
        data: updatedData,
        dataWorkflow: {
          id: this.workFlowData?.id,
          title: this.dataTitle?.slice(0, 255),
          scope: this.workFlowData?.scope,
          category: this.workFlowData?.category,
          pattern_id: this.workFlowData?.pattern_id
        }
      }
      
      // return
      if (updatedData.length || this.isEditTitleApprovedData) {
        this.actionUpdateIsLoading(true)
        this.dialogQuestion = false;
        updateListData(this.pattern.patternUrl, this.workFlowData?.id ? dataUpdateWithWorkFlowId : updatedData, false, this.isFlagUpdateDataListEmission).then(() => {
          this.updateTotalEmissionsHandler();
          this.actionUpdateIsLoading(false);
          this.isChangeEvidence = false;
          this.isChangeStatus = false;
          this.itemsChangeEvidence = {};
          this.itemsChangeStatus = {};
        }).catch((errors) => {
          this.actionUpdateIsLoading(false)
          if (getErrorMessge(errors).length > 0) {
            this.dialogNotification = true;
            this.messageNotification = getErrorMessge(errors);
          }
          this.flexgrid.refresh();
        });
      } else {
        this.dialogQuestion = false;
        this.isChangeEvidence = false;
        this.itemsChangeEvidence = {}
      }
    },
    saveDraftDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      let dataWorkflow = {
        ...this.registerWorkflow,
        title: this.dataTitle,
        template_type: null,
        month: this.month,
        year: this.yearSelected,
      };
      if (dataWorkflow?.title?.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      if (!dataWorkflow?.title) {
        dataWorkflow.title = "";
      }
      if(this.isSettingPcaf) {
        dataWorkflow.type_data = this.itemSelectPCAF.dataTypeIdSelect;
        dataWorkflow.type_classify = this.itemSelectPCAF.categoryIdSelect;
      }
      let payload = {};
      if (this.dataPattern.workflow_data?.id && this.dataPattern.workflow_data.status === STATUS_FIELD.STATUS_DRAFT) {
        if (this.isDraftNotChangeMethod() && !this.isChangeSelectTypePCAF) {
          // TODO: Change display order
          if(this.scope === 1 || this.scope === 3) {
            dataWorkflow.pattern_id = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
          }
          // update draft
          const addedData = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
            this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
          );
          const updatedData = this.editDataRegisterOnTable(this.dataTable, this.itemsInView);
          const deletedData = this.removeDataRegisterOnTable(this.dataTable);
          payload = {
            data: {
              add: addedData,
              update: updatedData,
              delete: deletedData,
            },
            dataWorkflow: dataWorkflow,
          };
          updateTemplateData(payload).then(() => {
            this.updateIsEditData(false);
            this.isEditDataTable = false;
            this.dialogNotification = true;
            this.messageNotification = this.$t("register_data.popup_message_save_change");
          });
          return;
        }
      }
      let draftData = this.$route.query?.import || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT ? this.getDataImport() : this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
        this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
      );
      // clone draft from template
      if (
        this.dataPattern.workflow_data?.id &&
        this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE
      ) {
        delete dataWorkflow.id;
        this.updateDataMonth(parseInt(this.selectedMonth));
        this.updateYearSelected(this.yearSelected);
        dataWorkflow.month = parseInt(this.selectedMonth);
        dataWorkflow.year = this.yearSelected;
        dataWorkflow.pattern_id = this.dataPattern?.workflow_data.pattern_id;
        draftData = this.cloneDataHandler();
      } else if (
        this.dataPattern.workflow_data?.id &&
        (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ||
          this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_RETURN)
      ) {
        dataWorkflow.id = this.dataPattern.workflow_data?.id;
        dataWorkflow.pattern_id = this.dataPattern?.workflow_data.pattern_id;
        draftData = this.cloneDataHandler();
      }
      // TODO: Change display order
      if(this.scope === 1 || this.scope === 3) {
        dataWorkflow.pattern_id = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2);
      }
      // add status true when save draft
      if(!this.dataPattern.workflow_data?.id) {
        draftData = draftData.map(item => {
          return {
            ...item,
            status: 1
          }
        })
      }

      payload = {
        action: 'saveDraft',
        data: draftData,
        dataWorkflow: dataWorkflow,
      };
      this.dialogQuestion = false;
      // Call API SUBMIT
      saveDraftAction(payload).then((res) => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.messageNotification = this.$t("user_management.message_saved_changes");
        if (
          this.dataPattern?.workflow_data?.id &&
          this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_TEMPLATE
        ) {
          this.messageNotification = this.$t("register_data.popup_message_save_as_registered_data");
          this.redirectUrl = '/emissions';
        }
        if (
          this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ||
          this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN
        ) {
          this.messageNotification = this.$t("register_data.popup_message_return_to_draft");
        }
        if (!this.dataPattern?.workflow_data?.id) {
          this.messageNotification = this.$t("register_data.popup_message_save_change");
          this.redirectUrl = `/emissions/register/list-menu/${res?.latest_record?.workflow_data?.id}`;
        }
        this.dialogNotification = true;
      });
    },
    submitRegisterDataHandler(approvalFormData) {
      this.approvalForm = approvalFormData || this.approvalForm;
      let dataWorkflow = {
        ...this.registerWorkflow,
        ...this.approvalForm,
        month: this.month,
        year: this.yearSelected,
      };
      if (dataWorkflow?.title?.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      if (!dataWorkflow?.title) {
        dataWorkflow.title = "";
      }
      if(this.isSettingPcaf) {
        dataWorkflow.type_data = this.itemSelectPCAF.dataTypeIdSelect;
        dataWorkflow.type_classify = this.itemSelectPCAF.categoryIdSelect;
      }
      if (dataWorkflow?.comment?.length > 255) {
        dataWorkflow.comment = dataWorkflow.comment.substr(0, 255);
      }
      this.updateDataMonth(parseInt(this.selectedMonth));
      this.updateYearSelected(this.yearSelected);
      let submitData = this.$route.query?.import ? this.getDataImport() : this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
        this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
      );
      if (this.dataPattern?.workflow_data?.id) {
        if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_TEMPLATE) {
          delete dataWorkflow.id;
          this.updateDataMonth(parseInt(this.selectedMonth));
          this.updateYearSelected(this.yearSelected);
          dataWorkflow.month = parseInt(this.selectedMonth);
          dataWorkflow.year = this.yearSelected;
        }
        submitData = this.cloneDataHandler();
      } else {
        submitData = submitData.map(item => {
          return {
            ...item,
            status: 1
          }
        })
      }
      
      const payload = {
        action: ACTION_HEADER_TABLE.SUBMIT_DATA,
        data: submitData,
        dataWorkflow: dataWorkflow,
        check_duplicate: this.isSubmitData ? false : true,
      };

      if(this.settingPcafBySelectMonth && this.method < 5 && this.category === 15) {
        payload.dataWorkflow.pattern_id = this.method + 4
      }
      if(this.dataPattern?.workflow_data?.id) {
        payload.dataWorkflow.pattern_id = this.dataPattern?.workflow_data?.pattern_id
      }
      if(!this.isDraftNotChangeMethod()) { // type draft and changed method
        payload.dataWorkflow.pattern_id = this.methodLayer2 ? this.methodLayer2 : this.method;
      }
      if(this.scope === 1 || this.scope === 3) {
        dataWorkflow.pattern_id = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2)
      }
      // Call API SUBMIT
      this.submitDataDialogPopup = false;
      submitDataAction(payload)
        .then((res) => {
          this.isReloadPage = true;
          this.updateIsEditData(false);
          this.isEditDataTable = false;
          this.dialogNotification = true;
          this.redirectUrl = res.latest_record?.is_auto_approve ? '/approval' : '/emissions/list-submitted';
          this.messageNotification = this.$t("register_data.popup_message_submit_sucess");
          this.isSubmitData = false;
        })
        .catch((error) => {
          if (error.errors?.data_duplicate) {
            this.dialogQuestion = true;
            this.highlightDuplicatedRows(error.errors.data_duplicate);
            this.messageQuestion = this.$t("register_data.popup_message_duplicate_data");
            this.confirmQuestionText = this.$t('list_menu.label_decision');
          }
          if (!error.errors?.data_duplicate && getErrorMessge(error).length > 0) {
            this.isReloadPage = false;
            // this.dialogNotification = true;
            // this.messageNotification = getErrorMessge(error);
            this.dialogDeleteDataApproval = true
            this.messageDialogDeleteDataApproval = getErrorMessge(error);
          }
        });
    },
    saveTemplateRegisterDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      let dataWorkflow = {
        ...this.registerWorkflow,
        category: this.registerWorkflow.scope !== 3 ? null : this.registerWorkflow.category,
        title: this.templateTitle || this.dataTitle || sessionStorage.getItem('title'),
        template_type: 0,
        month: this.month || 1,
        year: this.yearSelected,
      };

      // TODO: Change display order
      if(this.scope === 1 || this.scope === 3) {
        dataWorkflow.pattern_id = this.setPatternId(this.category, this.scope, this.method, this.methodLayer2)
      }
      if (dataWorkflow?.title?.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      if (!dataWorkflow?.title) {
        dataWorkflow.title = "";
      }
      if(this.isSettingPcaf) {
        dataWorkflow.type_data = this.itemSelectPCAF.dataTypeIdSelect;
        dataWorkflow.type_classify = this.itemSelectPCAF.categoryIdSelect;
      }
      let payload = {
        action: 'saveTemplate',
        data: null,
        dataWorkflow: dataWorkflow,
      };
      // handle for clone template
      if (this.dataPattern?.workflow_data?.id) {
        if (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
          delete dataWorkflow.id;
          this.updateDataMonth(parseInt(this.dataPattern.workflow_data.month));
          this.updateYearSelected(this.dataPattern.workflow_data.year);
          dataWorkflow.month = parseInt(this.dataPattern.workflow_data.month);
          dataWorkflow.year = this.dataPattern.workflow_data.year;
        }
        payload.data = this.cloneDataHandler(true);
      } else {
        if(this.$route.query?.import) {
          payload.data = this.getDataImport(true)
        } else {
          payload.data = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView, true).concat(
          this.addDataRegisterOnTable(this.dataTable, this.itemsInView, true),
        );
        }
      }
      // Call API SUBMIT

      this.addRegisterDataWorkFollow(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.isSaveTemplateButton = true;
        this.dialogQuestion = true;
        if (this.isCloneTemplate) {
          this.messageQuestion = this.$t("register_data.popup_message_save_new_template");
        } else {
          this.messageQuestion = this.$t("register_data.popup_message_clone_new_template");
        }
        this.confirmQuestionText = this.$t("register_data.button_express");
        this.dialogNotification = false;
      });
    },
    handleCancelSubmit() {
      this.dialogQuestion = false;
      const payload = {
        action: ACTION_HEADER_TABLE.CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataPattern.workflow_data.id,
        },
      };
      cancelSubmittedData(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.dialogNotification = true;
        this.redirectUrl = '/emissions';
        this.messageNotification = this.$t("register_data.popup_message_cancel_submit");
      })
      .catch((error) => {
        this.dialogQuestion = false;
        this.dialogNotification = true;
        this.redirectUrl = '/emissions';
        this.messageNotification = error.errors[0][0]
      });
    },
    handleAcceptRequestCancelSubmit(acceptCancelFormData) {
      let is_allow_cancel = null;
      if (acceptCancelFormData.status === CANCEL_TYPE.ACCEPT) {
        is_allow_cancel = CANCEL_TYPE.ACCEPT;
      } else {
        is_allow_cancel = CANCEL_TYPE.REJECT;
      }
      const payload = {
        action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataPattern.workflow_data.id,
          comment: acceptCancelFormData?.comment?.length > 255 ? acceptCancelFormData.comment.substr(0, 255) : acceptCancelFormData.comment,
        },
        is_allow_cancel: is_allow_cancel
      }

      cancelSubmittedData(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.dialogAcceptCancel = false;
        this.messageNotification = acceptCancelFormData.status === CANCEL_TYPE.ACCEPT ? this.$t("register_data.popup_message_accept_cancel_approve") : this.$t("register_data.popup_message_accept_cancel_reject");
        this.dialogNotification = true;
        this.redirectUrl = '/approval'
      }).catch((error) => {
        this.dialogAcceptCancel = false;
        this.dialogNotification = true;
        this.redirectUrl = '/approval';
        this.messageNotification = error.errors[0][0]
      });

    },
    updateTemplateDataHandler() {
      if (!this.isHasDataTable) {
        return;
      }
      this.dialogQuestion = false;
      let dataWorkflow = {
        ...this.registerWorkflow,
        title: this.dataTitle || this.dataPattern.workflow_data.title,
        template_type: 0,
        month: this.dataPattern?.workflow_data?.month,
        year: this.dataPattern?.workflow_data?.year,
      };
      dataWorkflow.pattern_id = this.dataPattern?.workflow_data.pattern_id;
      if (dataWorkflow?.title?.length > 255) {
        dataWorkflow.title = dataWorkflow.title.substr(0, 255);
      }
      if (!dataWorkflow?.title) {
        dataWorkflow.title = "";
      }
      if(this.isSettingPcaf) {
        dataWorkflow.type_data = this.itemSelectPCAF.dataTypeIdSelect;
        dataWorkflow.type_classify = this.itemSelectPCAF.categoryIdSelect;
      }
      this.updateDataMonth(parseInt(this.dataPattern?.workflow_data?.month));
      this.updateYearSelected(this.dataPattern?.workflow_data?.year);
      dataWorkflow.month = this.selectedMonth || parseInt(this.dataPattern?.workflow_data?.month);
      dataWorkflow.year = this.selectedYear || this.dataPattern?.workflow_data?.year;
      const addedData = this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView, true).concat(
        this.addDataRegisterOnTable(this.dataTable, this.itemsInView, true),
      );
      const updatedData = this.editDataRegisterOnTable(this.dataTable, this.itemsInView, true);
      const deletedData = this.removeDataRegisterOnTable(this.dataTable);
      const payload = {
        data: {
          add: addedData,
          update: updatedData,
          delete: deletedData,
        },
        dataWorkflow: dataWorkflow,
      };
      // return;
      // Call API SUBMIT
      updateTemplateData(payload).then(() => {
        this.updateIsEditData(false);
        this.isEditDataTable = false;
        this.dialogNotification = true;
        this.redirectUrl = 'save-btn-template'
        this.messageNotification = this.$t("register_data.popup_message_save_template");
      });
    },
    deleteAllDataHandler() {
      this.updateIsEditData(false);
      this.isEditDataTable = false;
      if (this.dataPattern.workflow_data) {
        deleteTemplate({
          workflow_id: this.dataPattern.workflow_data.id,
        }).then(() => {
          if (this.dataPattern?.workflow_data.status === STATUS_FIELD.STATUS_TEMPLATE) {
            return this.$router.push({ path: '/emissions/list-templates' });
          }
          return this.$router.push({ path: '/emissions' });
        }).catch(error => {
          this.dialogDeleteDataApproval = true
          this.messageDialogDeleteDataApproval = error.errors[0][0];
        });
      } else {
        this.totalEmissions = 0;
        this.dataTable.sourceCollection = [];
        this.listDataPattern = [];
        this.pattern.addBlankItemsToView(this.dataTable, this.isApproval ? 0 : 10);
        this.checkTableHasData()
      }
      this.dialogQuestion = false;
    },
    approvalDataHandler(approvalFormData) {
      let status = null;
      if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
        status =
          this.dataPattern.workflow_data.is_approver === 1
            ? APPROVAL_STATUS.APPROVER_APPROVAL
            : APPROVAL_STATUS.APPROVAL;
      } else {
        status =
          this.dataPattern.workflow_data.is_approver === 1 ? APPROVAL_STATUS.APPROVER_RETURN : APPROVAL_STATUS.RETURN;
      }
      const payload = {
        id: this.dataPattern.workflow_data.id,
        status: status,
        comment: approvalFormData?.comment?.length > 255 ? approvalFormData.comment.substr(0, 255) : approvalFormData.comment,
      };
      // call API to submit data
      this.updateIsEditData(false);
      this.isEditDataTable = false;
      this.dialogApproval = false;
      let urlRedirect = '/approval';
      if(this.$route.params?.scope === ROUTES.PRIMARY_DATA_MERGE_DETAIL) {
        urlRedirect = '/primary-data';
      }
      approvalDataAction(payload)
        .then(() => {
          if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
            this.messageNotification =
              this.dataPattern.workflow_data.is_approver === 1 ? this.$t("register_data.popup_message_accept_approve") : this.$t("register_data.popup_message_accept_approver_approve");
          } else {
            this.messageNotification =
              this.dataPattern.workflow_data.is_approver === 1
                ? this.$t("register_data.popup_message_return_approve")
                : this.$t("register_data.popup_message_return_approver_approve");
          }
          this.redirectUrl = urlRedirect;
          this.dialogNotification = true;
        })
        .catch((error) => {
          this.dialogNotification = true;
          this.redirectUrl = urlRedirect;
          this.messageNotification = getErrorMessge(error).length > 0 ? getErrorMessge(error) : error.errors[0][0]
        });
    },
    closeDialogSubmitData() {
      this.submitDataDialogPopup = false;
    },
    closeDialogSaveTemplate() {
      this.saveTemplateDataDialogPopup = false;
    },
    closeDialogWarningSaveTemplate() {
      this.warningTemplateDataDialogPopup = false;
    },
    handleActionAfterScopeIdFetched() {
      this.checkScopeToConvert();
      if (this.isConvert) {
        this.listNumberItems.push('wsu_source', 'wsu_value');
      }
    },
    checkScopeToConvert() {
      if (this.scope === SCOPE.SCOPE_3) {
        switch (this.category) {
          case 8:
          case 9:
          case 10:
          case 11:
          case 13:
          case 14:
          case 15:
            this.isConvert = true;
            break;
          case 16:
            this.isConvert = true;
            break;
          default:
            this.isConvert = false;
            break;
        }
      }
    },
    async getHeaderFromScope() {
      // set method for get data by workflow id
      this.pattern = null;
      let idPattern = null;
      if(this.dataPattern.workflow_data?.id && !this.isChangeMethod) {
        const category = this.dataPattern.workflow_data.category;
        const patternId = this.dataPattern.workflow_data.pattern_id;
        const scope = this.dataPattern.workflow_data.scope;
        idPattern = this.dataPattern.workflow_data.pattern_id;
        if(!['4', '9', '11', '15']?.includes(category)) {
          this.method = this.dataPattern.workflow_data.pattern_id
          if(scope == 1 && category == 1 && patternId === 1) {
            this.method = 2;
          }
          if(scope == 1 && category == 1 && patternId === 2) {
            this.method = 4;
          }
          if(scope == 1 && category == 1 && patternId === 3) {
            this.method = 3;
          }
          if(scope == 1 && category == 1 && patternId === 4) {
            this.method = 1;
          }
          if(scope == 3 && category == 6 && patternId === 1) {
            this.method = 3;
          }
          if(scope == 3 && category == 6 && patternId === 2) {
            this.method = 1;
          }
          if(scope == 3 && category == 6 && patternId === 3) {
            this.method = 2;
          }
          if(scope == 3 && category == 7 && patternId === 1) {
            this.method = 3;
          }
          if(scope == 3 && category == 7 && patternId === 2) {
            this.method = 1;
          }
          if(scope == 3 && category == 7 && patternId === 3) {
            this.method = 2;
          }
        } else if(category === '4') {
          switch (patternId) {
            case 4:
              this.method = 1;
              this.methodLayer2 = 4;
              break;
            case 5:
              this.method = 1;
              this.methodLayer2 = 6;
              break;
            case 6:
              this.method = 1;
              this.methodLayer2 = 5;
              break;
            case 7:
              this.method = 2;
              this.methodLayer2 = 7;
              break;
            case 8:
              this.method = 2;
              this.methodLayer2 = 8;
              break;
            case 2:
              this.method = 3;
              this.methodLayer2 = null;
              break;
            default:
              break;
          }
        } else if(category === '9') {
          if(patternId === 2) {
            this.methodLayer2 = 3;
          } else if(patternId === 3) {
            this.methodLayer2 = 2;
          } else {
            this.methodLayer2 = patternId;
          }
        } else if(category === '11') {
          if(patternId === 4) {
            this.method = 2;
            this.methodLayer2 = null;
          } else {
            this.methodLayer2 = patternId;
            this.method = 1;
          }
        } else if (category === '15') {
          if(patternId <= 4) {
            this.method = patternId
            this.methodLayer2 = null
          } else {
            this.method = patternId - 4
            this.methodLayer2 = null
          }
        }
      }
      const patternFile = getPatternFileImport({
        scope: this.scope,
        category: this.category,
        method: this.method,
        methodLayer2: this.methodLayer2,
        isScoreSummary: this.isOpenScoreSummary,
        isEmissionViewScreen: this.isEmissionViewScreen,
        settingPcafBySelectMonth: this.settingPcafBySelectMonth,
        idPattern: idPattern,
        isPCAF: this.planType?.is_pcaf,
      });
      const patternData = await import(`../../../concerns/newRegisterData/${patternFile}.js`)
      this.pattern = patternData.default;
    },
    handleBindDbCustomize(item, idCus) {
      if (!this.idCustomizes.includes(idCus)) {
        if (parseInt(this.scope) > SCOPE.SCOPE_2) {
          item.wsu_source = item?.db_customize?.source;
          item.db_customize_id_1 = item?.db_customize1?.source;
          if (this.category === CATEGORY.CATEGORY_15) {
            item.db_id = item?.db_customize?.source;
          }
        } else {
          item.wsu_source = item?.db_customize?.item_name;
        }

        if (parseInt(this.category) === CATEGORY.CATEGORY_16) {
          item.db_customize_id = item?.db_customize?.source;
        }

        if (parseInt(this.category) === CATEGORY.CATEGORY_4 && this.method === METHOD_3) {
          item.db_customize_id_1 = item?.db_customize1?.source;
        }
      }
    },
    handleBindDbCustomize1(item, idCus) {
      if (!this.idCustomizes.includes(idCus)) {
        item.db_customize_id_1 = item?.db_customize1?.source;
      }
    },
    getDataFromScope() {
      this.listDataPattern = [];
      this.dataTable = null;
      this.lastedRow = null;
      this.$store.dispatch('registerData/updateDataList', []);
      this.actionUpdateIsClearUndoStack(false);
      this.timeoutUndoStack = false;
      this.actionUpdateStatusBtn({ undo: false, redo: false });
      this.updateUndoRedoClick('');
      if (this.scope === 3 && this.category === 4 && this.method === 1 && this.methodLayer2 === null) return;
      this.showListDataPattern();
    },
    async showListDataPattern() {
      if(this.scope === 4) {
        await this.getCertification()
      }
      if (!this.isOpenScoreSummary) {
        const responseGroup = await businessGroupList();
        this.businessGroupList = responseGroup.groups
        this.groupObj = responseGroup.dataObj
      }
      
      this.convertDataOnTable();

      this.$store.dispatch('registerData/updateTotalEmission', this.dataPattern.total_emissions);
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.dataPattern.total_duration_emissions);
      if(this.$route.query?.import) {
        return
      }
      this.renderDataPatternOnTable();
    },
    convertDataOnTable() {
      const layerList = {};
      const layerResponse = this.dataPattern?.layer || this.layerData;
      this.itemsInView = [];
      this.itemsInViewError = [];
      const layers = layerResponse.map((item) => {
        let listBranch = this.branchData;
        this.itemsInView.push(this.layerIndex[item.layer_index]);
        this.itemsInViewError.push(item.layer_name);
        layerList[this.layerIndex[item.layer_index]] = item.layer_name;
        return {
          header: item.layer_name,
          visible: true,
          binding: this.layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: 90,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          dataMap: new wjGrid.DataMap(listBranch[this.layerIndex[item.layer_index]].filter(item => item.status === true), 'key', 'value'),
          editor: new AutoComplete(document.createElement('div'), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: listBranch[this.layerIndex[item.layer_index]].filter(item => item.status === true),
            selectedValuePath: 'key',
            displayMemberPath: 'value',
            maxItems: 1000,
            minLength: 1,
            selectedIndex: -1,
          }),
        };
      });
      this.layer = layers || [];

      store.dispatch('registerData/actionUpdateLayerFilter', layerList, { root: true });
      // this.updateOrderNumberDontHavePermissions(res.order_number_not_permissions)
      const listType = this.$store.state.registerData.listType.map((item) => item.key);
      const companyList = this.branchData?.company_name.map((item) => item.value);
      const businessList = this.branchData?.business_name.map((item) => item.value);
      const countryList = this.branchData?.country.map((item) => item.value);
      const layer3List = this.branchData?.layer_3.map((item) => item.value);
      const layer4List = this.branchData?.layer_4.map((item) => item.value);
      const layer5List = this.branchData?.layer_5.map((item) => item.value);
      const layer6List = this.branchData?.layer_6.map((item) => item.value);
      
      this.dataPattern.data?.forEach((itemDataPattern, index) => {
        let filterData = {}
        if(!this.isOpenScoreSummary) {
          filterData = {
            organizational_division: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.organizational_division ?? itemDataPattern.organizational_division,
            company_name: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.company_name ?? itemDataPattern.company_name,
            business_name: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.business_name ?? itemDataPattern.business_name,
            country: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.country ?? itemDataPattern.country,
            layer_3: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.layer_3 ?? itemDataPattern.layer_3,
            layer_4: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.layer_4 ?? itemDataPattern.layer_4,
            layer_5: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.layer_5 ?? itemDataPattern.layer_5,
            layer_6: this.dataPattern.branch[Number(itemDataPattern?.branch_id)]?.layer_6 ?? itemDataPattern.layer_6,
          };
        }

        if (typeof itemDataPattern?.branch === 'object' && itemDataPattern?.branch !== null) {
          filterData = {
            organizational_division: itemDataPattern?.branch?.organizational_division,
            company_name: itemDataPattern?.branch?.company_name,
            business_name: itemDataPattern?.branch?.business_name,
            country: itemDataPattern?.branch?.country,
            layer_3: itemDataPattern?.branch?.layer_3,
            layer_4: itemDataPattern?.branch?.layer_4,
            layer_5: itemDataPattern?.branch?.layer_5,
            layer_6: itemDataPattern?.branch?.layer_6,
          };
        }
        let newItemDataPattern = {
          ...itemDataPattern,
          organizational_division: listType?.includes(filterData.organizational_division)
            ? filterData.organizational_division
            : null,
          company_name: companyList?.includes(filterData.company_name) ? filterData.company_name : null,
          business_name: businessList?.includes(filterData.business_name) ? filterData.business_name : null,
          country: countryList?.includes(filterData.country) ? filterData.country : null,
          layer_3: layer3List?.includes(filterData.layer_3) ? filterData.layer_3 : null,
          layer_4: layer4List?.includes(filterData.layer_4) ? filterData.layer_4 : null,
          layer_5: layer5List?.includes(filterData.layer_5) ? filterData.layer_5 : null,
          layer_6: layer6List?.includes(filterData.layer_6) ? filterData.layer_6 : null,
        };
        if(this.isOpenScoreSummary) {
          newItemDataPattern.id = index;
          newItemDataPattern.raw_emissions = newItemDataPattern.total_emissions;
          newItemDataPattern.total_emissions = newItemDataPattern.total_emissions === null ? null : this.formatNumberRealNum(newItemDataPattern?.total_emissions?.toString(), '', true) || '0.00';
          newItemDataPattern.total_emissions = newItemDataPattern.total_emissions === '0' ? '0.00' : newItemDataPattern.total_emissions;
        }
        const organizationList = this.$store.state.registerData.listType;
        const indexOrganization = organizationList.findIndex(orgi => orgi.key === newItemDataPattern.organizational_division)
        if (indexOrganization >= 0) {
          newItemDataPattern.organizational_division = organizationList[indexOrganization].status === false ? organizationList[indexOrganization].value : parseInt(newItemDataPattern.organizational_division);
        }
        this.listNumberItems.forEach((itemDataPattern) => {
          if (itemDataPattern in newItemDataPattern) {
            newItemDataPattern[itemDataPattern] =
              newItemDataPattern[itemDataPattern] === null
                ? null
                // : newItemDataPattern[itemDataPattern].toString() || '0';
                : this.formatNumberRealNum(newItemDataPattern[itemDataPattern].toString()) || '0';
          }
        });
        // newItemDataPattern.emissions = newItemDataPattern.emissions === null ? null : newItemDataPattern.emissions.toString() || '0';
        const emission25Number = formatNumberBySetting(newItemDataPattern.emissions)?.includes('-') ? 26 : 25;
        // newItemDataPattern.emissions = newItemDataPattern.emissions === null ? null : formatValue(newItemDataPattern.emissions)?.substring(0, emission25Number) || '0';
        newItemDataPattern.emissions = newItemDataPattern.emissions === null ? null : this.formatNumberRealNum(formatNumberBySetting(newItemDataPattern.emissions)?.substring(0, emission25Number)) || '0';
        newItemDataPattern.type_1 = itemDataPattern.type_1 ? 1 : 0; // type = 1 if you use master_db
        newItemDataPattern.type = itemDataPattern.type;
        newItemDataPattern.fuel= this.convertFuelOption(newItemDataPattern.fuel)
        newItemDataPattern['ie_year'] = formatValue(newItemDataPattern['ie_year']);

        if(this.category == 15 && this.method <= 3 && this.planType?.is_pcaf && !itemDataPattern.emissions_factor &&  itemDataPattern.type_data === DATA_TYPE[0].key) {
          newItemDataPattern.emissions_factor = 1
        }

        if (itemDataPattern?.evidence) {
          let evidenceData = itemDataPattern.evidence;
          if(typeof evidenceData === 'string') {
            evidenceData = JSON.parse(evidenceData)
          }
          newItemDataPattern.attach_file = evidenceData.file_name;
        }
        // handle add db customize || db master
        if (itemDataPattern?.db_customize_id) {
          itemDataPattern.db_customize = this.dataPattern.db_customize[Number(itemDataPattern?.db_customize_id)];
          newItemDataPattern.db_customize = this.dataPattern.db_customize[Number(itemDataPattern?.db_customize_id)];
        }
        if (itemDataPattern?.db_master_id) {
          itemDataPattern.db_master = this.dataPattern.db_master[Number(itemDataPattern?.db_master_id)];
        }
        if (itemDataPattern?.db_relation && (itemDataPattern.type === DB_TYPE.IDEA || itemDataPattern.type === DB_TYPE.SERVICE_LINK)) {
          const dbRelationKey = getDbRelationKeysByType(itemDataPattern.type);
          itemDataPattern.external_source = this.dataPattern.external_source[(itemDataPattern?.db_relation[dbRelationKey])];
          if(this.objSourceIdea[itemDataPattern?.external_source?.id]) {
            newItemDataPattern.db_relation = {
              [dbRelationKey]: itemDataPattern?.external_source?.id,
            }
            newItemDataPattern.idDbExternal = makeNewId(itemDataPattern?.external_source?.id, itemDataPattern.type)
            newItemDataPattern.detailExternalSourceId = dbIdeaObj[newItemDataPattern.idDbExternal]?.db_source_id
          }
          if(this.objSourceService[itemDataPattern?.external_source?.product_package_code]) {
            newItemDataPattern.db_relation = {
              [dbRelationKey]: itemDataPattern?.external_source?.id,
            }
            newItemDataPattern.idDbExternal = makeNewId(itemDataPattern?.external_source?.id, itemDataPattern.type)
            newItemDataPattern.detailExternalProductCode = this.objSourceService[itemDataPattern?.external_source?.product_package_code]?.product_code
          }
        }
        if (itemDataPattern?.db_relation && (itemDataPattern.type === DB_TYPE.IDEA || itemDataPattern.type === DB_TYPE.SERVICE_LINK)) {
          if (this.scope >= 3 && this.category < 16) {
            newItemDataPattern.wsu_source = Number(
              itemDataPattern?.external_source?.id?.toString().split(',').join(''),
            );
          } else {
            newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source?.toString().split(',').join('');
          }
          newItemDataPattern.wsu_unit = itemDataPattern?.external_source?.unit_source;
          newItemDataPattern.wsu_value = itemDataPattern?.external_source?.value_source ? itemDataPattern?.external_source?.value_source : null;
          newItemDataPattern.unit = itemDataPattern?.external_source?.unit;
          newItemDataPattern.wsu_item_name = itemDataPattern?.external_source?.item_name;
          newItemDataPattern.wsu_unit_activity = itemDataPattern?.external_source?.unit;
          // s1m1
          if (this.scope === 1 || this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.external_source?.source;
          }
          if (this.scope === 1 && this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.external_source?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
          }
          if (this.scope === 1 && this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.external_source?.id?.toString().split(',').join('');
          }
          if (this.scope === 1 && this.method === 3) {
            newItemDataPattern.fuel = itemDataPattern?.external_source?.id;
          }
          if (this.scope === 1 && this.method === 4) {
            newItemDataPattern.wsu_source = itemDataPattern?.external_source?.id?.toString().split(',').join('');
          }
          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.external_source?.id?.toString().split(',').join('');
          }
          if (this.scope === 3 && this.category === 1) {
            newItemDataPattern.wsu_source = itemDataPattern?.external_source?.id;
          }
          // s3c2 cpa_unit = unit
          if (this.scope === 3 && this.category === 2) {
            newItemDataPattern.cpa_unit = itemDataPattern?.external_source?.unit;
          }
          // s3c3 energy_unit = unit,  energy_type = item_name
          if (this.scope === 3 && this.category === 3) {
            newItemDataPattern.energy_unit = itemDataPattern?.external_source?.unit;
            newItemDataPattern.energy_type = itemDataPattern?.external_source?.source;
          }
          // s3c4m1 qd_unit = unit
          if (this.scope === 3 && this.category === 4) {
            newItemDataPattern.qd_unit = itemDataPattern?.external_source?.unit;
            // for m3
            newItemDataPattern.value_source1 = itemDataPattern?.external_source?.value_source;
            newItemDataPattern.unit_source1 = itemDataPattern?.external_source?.unit_source;
            if (this.methodLayer2 === 4) {
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
            if (this.methodLayer2 === 4) {
              newItemDataPattern.energy_type = itemDataPattern?.external_source?.id;
            }
            if (this.methodLayer2 === 5) {
              newItemDataPattern.fuel = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
          }
          if (this.scope === 3 && this.category === 5) {
            newItemDataPattern.item_unit = itemDataPattern?.external_source?.unit;
            newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
          }
          if (this.scope === 3 && this.category === 6) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.external_source?.item_name;
            newItemDataPattern.travel_unit = itemDataPattern?.external_source?.unit;
            if (this.method === 1) {
              newItemDataPattern.energy_type = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
            if (this.method === 2) {
              newItemDataPattern.fuel = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
          }
          if (this.scope === 3 && this.category === CATEGORY.CATEGORY_7) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.external_source?.item_name;
            newItemDataPattern.te_unit = itemDataPattern?.external_source?.unit;
            if (this.method === 1) {
              // newItemDataPattern.energy_type = itemDataPattern?.external_source?.item_name;
              newItemDataPattern.energy_type = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
            if (this.method === 3) {
              newItemDataPattern.energy_type = itemDataPattern?.external_source?.source;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.id;
            }
            if (this.method === 2) {
              newItemDataPattern.fuel = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
          }
          if (this.scope === 3 && this.category === 8) {
            if(!this.objSourceIdea[itemDataPattern?.external_source?.id]) {
              newItemDataPattern.lease_asset_name = null
            }
          }
          if (this.scope === 3 && this.category === 9) {
            newItemDataPattern.energy_type = itemDataPattern?.external_source?.source;
            if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
              newItemDataPattern.energy_type = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
            if (this.methodLayer2 === 2) {
              newItemDataPattern.fuel = itemDataPattern?.external_source?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.external_source?.source;
            }
          }
          if (this.scope === 3 && this.category === 10) {
            newItemDataPattern.intermediate_product = itemDataPattern?.external_source?.source;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.external_source?.unit;
            if(!this.objSourceIdea[itemDataPattern?.external_source?.id]) {
              newItemDataPattern.intermediate_product = null
            }
          }
          if (this.scope === 3 && this.category === 11) {
            newItemDataPattern.wsu_source = itemDataPattern?.external_source?.id;
          }

          if (this.scope === 3 && this.category === 13) {
            newItemDataPattern.lease_asset_name = itemDataPattern?.external_source?.source;
            if(!this.objSourceIdea[itemDataPattern?.external_source?.id]) {
              newItemDataPattern.lease_asset_name = null
            }
          }

          if (this.scope === 3 && this.category === 15) {
            newItemDataPattern.ides_industry = itemDataPattern?.external_source?.id;
            newItemDataPattern.carbon_value = itemDataPattern?.external_source?.value_source;
          }
        }
        if (itemDataPattern?.db_customize) {
          if (this.scope >= 3 && this.category < 16) {
            newItemDataPattern.wsu_source = Number(itemDataPattern?.db_customize?.id?.toString().split(',').join(''));
          } else {
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source?.toString().split(',').join('');
          }
          newItemDataPattern.wsu_unit = itemDataPattern?.db_customize?.unit_source;
          newItemDataPattern.wsu_value = Number(itemDataPattern?.db_customize?.value_source);
          newItemDataPattern.unit = itemDataPattern?.db_customize?.unit;

          newItemDataPattern.wsu_item_name = itemDataPattern?.db_customize?.item_name;
          newItemDataPattern.wsu_unit_activity = itemDataPattern?.db_customize?.unit;
          // s1m1
          if (this.scope === 1 || this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
          }

          if (this.scope === 1 && this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
          }
          if (this.scope === 1 && this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
          }

          if (this.scope === 1 && this.method === 3) {
            newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
          }
          if (this.scope === 1 && this.method === 4) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
          }

          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id?.toString().split(',').join('');
          }

          // s3c2 cpa_unit = unit
          if (this.scope === 3 && this.category === 2) {
            newItemDataPattern.cpa_unit = itemDataPattern?.db_customize?.unit;
          }

          // s3c3 energy_unit = unit,  energy_type = item_name
          if (this.scope === 3 && this.category === 3) {
            newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
          }

          // s3c4m1 qd_unit = unit
          if (this.scope === SCOPE.SCOPE_3 && this.category === 4) {
            newItemDataPattern.qd_unit = itemDataPattern?.db_customize?.unit;
            // for m3
            newItemDataPattern.value_source1 = Number(itemDataPattern?.db_customize?.value_source);
            newItemDataPattern.unit_source1 = itemDataPattern?.db_customize?.unit_source;
            if (this.methodLayer2 === 4) {
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.methodLayer2 === 4) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
            }
            if (this.methodLayer2 === 5) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }

            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 5) {
            newItemDataPattern.item_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 6) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.travel_unit = itemDataPattern?.db_customize?.unit;
            if (this.method === 1) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.method === 2) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === CATEGORY.CATEGORY_7) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.te_unit = itemDataPattern?.db_customize?.unit;
            if (this.method === 1) {
              // newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.method === 3) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id;
            }

            if (this.method === 2) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 8) {
            newItemDataPattern.lease_asset_name = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.iss_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 9) {
            newItemDataPattern.activity_name = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.energy_type = itemDataPattern?.db_customize?.item_name;
            if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
              newItemDataPattern.energy_type = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
            if (this.methodLayer2 === 2) {
              newItemDataPattern.fuel = itemDataPattern?.db_customize?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.source;
            }
          }

          if (this.scope === 3 && this.category === 10) {
            newItemDataPattern.intermediate_product = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === 3 && this.category === 11) {
            newItemDataPattern.intermediate_product = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.wsu_source = itemDataPattern?.db_customize?.id;
          }

          if (this.scope === 3 && this.category === 12) {
            newItemDataPattern.item_unit = itemDataPattern?.db_customize?.unit;
            newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
          }

          if (this.scope === 3 && this.category === 13) {
            newItemDataPattern.lease_asset_name = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.scale_index_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === 3 && this.category === 14) {
            newItemDataPattern.activity = itemDataPattern?.db_customize?.item_name;
            newItemDataPattern.scale_index_unit = itemDataPattern?.db_customize?.unit;
          }

          if (this.scope === 3 && this.category === 15) {
            newItemDataPattern.ides_industry = itemDataPattern?.db_customize?.id;
            newItemDataPattern.carbon_value = itemDataPattern?.db_customize?.value_source;
          }

          // s3c1
          newItemDataPattern.qd_unit = itemDataPattern?.db_customize?.unit;
          newItemDataPattern.wsu_unit = itemDataPattern?.db_customize?.unit_source;
          newItemDataPattern.wsu_value = itemDataPattern?.db_customize?.value_source;
          newItemDataPattern.db_id = itemDataPattern?.db_customize_id;

          //pt_s3c9
          newItemDataPattern.energy_unit = itemDataPattern?.db_customize?.unit;
          // this.handleBindDbCustomize(newItemDataPattern, itemDataPattern?.db_customize?.id);

          if (this.scope === 3 && this.category === 15) {
            newItemDataPattern.ie_company_unit = itemDataPattern?.db_customize?.unit;
            if (!this.idCustomizes.includes(newItemDataPattern.db_customize_id)) {
              newItemDataPattern.db_id = itemDataPattern?.db_customize?.source;
            }
          }
        }

        // handle for db master
        if (itemDataPattern?.db_master) {
          if (this.scope >= 3) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
            if (
              (this.category === 4 && this.methodLayer2 === 4) ||
              (this.category === 9 && (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5))
            ) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
              if (this.methodLayer2 === 4) {
                newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              }
            }
          } else {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
          }
          newItemDataPattern.wsu_unit = itemDataPattern?.db_master?.unit_source;
          newItemDataPattern.wsu_value = this.formatValueSourceNumber(itemDataPattern?.db_master?.value_source);
          newItemDataPattern.wsu_unit_activity = itemDataPattern?.db_master?.unit;
          newItemDataPattern.wsu_item_name = itemDataPattern?.db_master?.item_name;
          newItemDataPattern.unit = itemDataPattern?.db_master?.unit;
          if (itemDataPattern?.db_master_id) {
            newItemDataPattern.value_source1 = this.formatValueSourceNumber(itemDataPattern?.db_master?.value_source);
            newItemDataPattern.unit_source1 = itemDataPattern?.db_master?.unit_source;
            newItemDataPattern.db_id = itemDataPattern?.db_master_id;
          }

          if (this.scope === 1 || this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
          }
          if (this.scope === 1 && this.method === 1) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
          }
          if (this.scope === 1 && this.method === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
          }
          if (this.scope === 1 && this.method === 3) {
            newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
          }
          if (this.scope === 1 && this.method === 4) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id?.toString().split(',').join('');
          }
          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id?.toString().split(',').join('');
          }
          if (this.scope === 2) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.id?.toString().split(',').join('');
          }

          // s3c4
          if (this.scope === 3 && this.category === 4) {
            if (this.method === 2) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
            if (this.method === 1 && this.methodLayer2 === 5) {
                newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
                newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
              }
          }
          if (this.scope === 3 && this.category === 6) {
            // newItemDataPattern.transportation_facilities = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.travel_unit = itemDataPattern?.db_master?.unit;
            if (this.method === 1) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
            if (this.method === 2) {
                newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
                newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
              }
          }
          // s3c7
          if (this.scope === 3 && this.category === 7) {
            newItemDataPattern.te_unit = itemDataPattern?.db_master?.unit;
            if (this.method === 1) {
              // newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
            if (this.method === 3) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
            }
            if (this.method === 2) {
              newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
          }

          if (this.scope === SCOPE.SCOPE_3 && this.category === 8) {
            newItemDataPattern.lease_asset_name = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.iss_unit = itemDataPattern?.db_master?.unit;
          }

          if (this.scope === 3 && this.category === 9) {
            newItemDataPattern.energy_type = itemDataPattern?.db_master?.item_name;
            if (this.methodLayer2 === 1 || this.methodLayer2 === 4 || this.methodLayer2 === 5) {
              newItemDataPattern.energy_type = itemDataPattern?.db_master?.id;
            }
            if (this.methodLayer2 === 2) {
              newItemDataPattern.fuel = itemDataPattern?.db_master?.id;
              newItemDataPattern.wsu_source = itemDataPattern?.db_master?.source;
            }
          }
          if (this.scope === 3 && this.category === 10) {
            newItemDataPattern.intermediate_product = itemDataPattern?.db_master?.item_name;
            newItemDataPattern.sales_volume_unit = itemDataPattern?.db_master?.unit;
          }
          if (this.scope === 3 && this.category === 11) {
            newItemDataPattern.wsu_source = itemDataPattern?.db_master?.id;
          }

          if (this.scope === 3 && this.category === 15) {
            newItemDataPattern.ides_industry = itemDataPattern?.db_master?.id;
            newItemDataPattern.carbon_value = itemDataPattern?.db_master?.value_source;
          }
        } else {
          delete itemDataPattern.db_master_id;
          delete newItemDataPattern.db_master_id;
        }

        if (itemDataPattern?.db_customize1) {
          // for pattern10
          newItemDataPattern.value_source2 = parseFloat(itemDataPattern?.db_customize1?.value_source);
          newItemDataPattern.unit_source2 = itemDataPattern?.db_customize1?.unit_source;
          this.handleBindDbCustomize1(newItemDataPattern, itemDataPattern?.db_customize1?.id);
        }

        if (itemDataPattern?.db_master1) {
          // for pattern10
          newItemDataPattern.value_source2 = this.formatValueSourceNumber(itemDataPattern?.db_master1?.value_source);
          newItemDataPattern.unit_source2 = itemDataPattern?.db_master1?.unit_source;
          newItemDataPattern.db_customize_id_1 = itemDataPattern?.db_master_id_1;
        }

        if (this.category === 16) {
          newItemDataPattern.wsu_source = itemDataPattern?.wsu_source;
          newItemDataPattern.wsu_unit = itemDataPattern?.wsu_unit;
          newItemDataPattern.wsu_value = Number(itemDataPattern?.wsu_value);
        }

        // convert wsu_source to number
        if (this.category === 2) {
          newItemDataPattern[itemDataPattern] = parseInt(newItemDataPattern[itemDataPattern]);
        }

        // newItemDataPattern.emissions = Number(itemDataPattern.emissions);
        newItemDataPattern.wsu_type = itemDataPattern.wsu_type && parseInt(itemDataPattern.wsu_type);
        if (this.scope === 1 && this.method === 4) {
          newItemDataPattern.wsu_source = itemDataPattern.db_customize_id || itemDataPattern.db_master_id || itemDataPattern?.db_relation?.db_source_detail_id || itemDataPattern?.db_relation?.service_product_package_link_code;
        }

        // save by cell s3c1
        if (this.scope === 3 && this.category === 1) {
          newItemDataPattern.emissions = newItemDataPattern.emissions === null ? 'N/A' : newItemDataPattern.emissions;
        }
        // update format date for s4
        if (this.scope === 4 && this.category === 1) {
          if(this.dataPattern?.workflow_data?.status !== STATUS_FIELD.STATUS_DRAFT) {
            newItemDataPattern.dir_date = itemDataPattern.dir_date ? moment(new Date(itemDataPattern.dir_date)).format('YYYY/M/D') : ''
          }
          // newItemDataPattern.certification_standard_name_id = newItemDataPattern.certification_standard_name?.name
          // newItemDataPattern.certification_standard_type_id = newItemDataPattern.certification_standard_type?.name
          let certiNameData = this.dataPattern.certification[newItemDataPattern.certification_standard_name_id];
          newItemDataPattern.certification_standard_name_id = certiNameData?.name;
          newItemDataPattern.certification_standard_type_id = certiNameData?.child.find(item => item.id === newItemDataPattern.certification_standard_type_id)?.name;
        }
        if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_TEMPLATE) {
          // reset emissions in template detail
          newItemDataPattern.emissions = newItemDataPattern.is_ocr && newItemDataPattern.emissions ? newItemDataPattern.emissions : null;
          if(this.scope === 4) {
            newItemDataPattern.dir_value = null;
            if(newItemDataPattern.purchase_level_unit === 't-CO2') { //t-CO2 is default value
              newItemDataPattern.emissions_factor = 1
            }
          }
        }
        // update null data
        if (this.scope === 1 && this.category === 1 && this.method === 3) {
          newItemDataPattern.fuel_efficiency_source = newItemDataPattern.fuel_efficiency_source || null;
        }
        if (this.scope === 1 && this.category === 1 && this.method === 4) {
          newItemDataPattern.distance_source = newItemDataPattern.distance_source || null;
          newItemDataPattern.loading_data_source = newItemDataPattern.loading_data_source || null;
        }
        if (this.scope === 3 && this.category === 4 && this.method === 3) {
          newItemDataPattern.source = newItemDataPattern.source || null;
        }
        newItemDataPattern.branch_id_old = newItemDataPattern?.branch_id
        newItemDataPattern.business_group_id_old = newItemDataPattern?.business_group_id

        if(itemDataPattern?.is_primary_data) { //handle auto fill for supplier data link
          const dbItem = itemDataPattern.db_customize || itemDataPattern.db_master || itemDataPattern.external_source
          if(this.scope === 3 && this.category === 3) {
            newItemDataPattern.energy_unit = dbItem?.unit
            newItemDataPattern.energy_type = dbItem?.item_name || dbItem?.source
          }
          if(this.scope === 3 && this.category === 1) {
            newItemDataPattern.qd_unit = dbItem?.unit
            // newItemDataPattern.supplier_name = dbItem?.item_name || dbItem?.source
          }
          if(this.scope === 3 && this.category === 13) {
            newItemDataPattern.scale_index_unit = dbItem?.unit
            newItemDataPattern.lease_asset_name = dbItem?.item_name || dbItem?.source
          }
        }

        if(itemDataPattern?.business_group_id) {
          const company = this.groupObj.company_id_obj[itemDataPattern.business_group_id]
          newItemDataPattern.pcaf_business_group_name = company?.business_group_name
          newItemDataPattern.pcaf_business_name = company?.business_name
        }
        // add clientRowId per row
        newItemDataPattern['clientRowId'] = index + 1;
        if(this.category === 16) {
          newItemDataPattern.wsu_value = itemDataPattern?.wsu_value?.toString()
        }

        if (!(!newItemDataPattern.wsu_value && newItemDataPattern.wsu_value !== 0)) {
          newItemDataPattern.wsu_value = this.formatNumberRealNum(newItemDataPattern.wsu_value)
        }
        
        this.listDataPattern.push(newItemDataPattern);
      });
    },
    async renderDataPatternOnTable() {
      const uniqueIds = new Set();
      // case: don't use db
      if (this.category === 14 || this.scope === 4) {
      this.onFlexGridInitialDone(this.temFlexGrid, false)
      setTimeout(async () => {
        if(this.$route.query?.import === 'true') {
          const header = this.getHeaderTable();
          await this.handleCsvNew(header)
        }
        this.originalData = this.listDataPattern.filter((element) => {
          const isDuplicate = uniqueIds.has(element.id) && element.id;
          uniqueIds.add(element.id);
          return !isDuplicate;
        });

        if(this.lengthCsv > 0) {
          this.isShowPopupCsv = true
          this.messagePopupCsv = this.$t("register_data.popup_message_import_success", { rowNum: this.lengthCsv});
          if(this.itemErrorCount > 0) {
            this.messagePopupCsv += `\n\n${this.itemErrorCount}${this.$t("register_data.popup_message_import_failed")}`;
          }
        }

        this.initialView();
        this.$nextTick(() => {
          this.scrollToTop();
          this.timeoutUndoStack = true;
        });
        this.actionUpdateIsLoading(false)
        return;
      }, 300)
      return
      }

      if(this.pattern?.isUseBasicUnit) {
        await Promise.all([this.getListBasicUnitDefault(), this.getListBasicUnitForPulldown()])
      } else {
        await this.getMasterDb()
      }
      setTimeout(() => this.actionUpdateIsLoading(false), 300)
      const dataProps = {
        flexgrid: this.temFlexGrid,
        itemCount: this.isApproval ? 0 : 100,
        branchData: this.branchData,
        dbStore: this.getDbStore,
        businessGroupList: this.businessGroupList,
        isLimitedAttributionFactor: this.isLimitedAttributionFactor,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF,
        getNewBbStore: this.getNewBbStore,
        handleDataOnCheckError: this.handleDataOnCheckError,
        getDataRowOnCheckError: this.getDataRowOnCheckError
      }

      this.pattern.initializeGrid(dataProps);
      this.onFlexGridInitialDone(this.temFlexGrid, false)
      if (Object.entries(this.dataPattern).length) {
        this.dataPattern?.db_source_detail?.forEach((dbSourceDetail) => {
          const nameSource = dbSourceDetail.source;
          const valueSource = dbSourceDetail.value_source;
          const unitSource = dbSourceDetail.unit_source;
          const unit = dbSourceDetail.unit;
          const dbDataItem = {
            ...dbSourceDetail,
            source: nameSource,
            unit_source: unitSource,
            value_source: valueSource,
            isExternalSource: true,
            type: DB_TYPE.IDEA,
            item_name: nameSource,
            unit: unit
          }
          this.addedDbExternalToMasterDB(dbDataItem);
        });
        this.dataPattern?.service_product_package_link?.forEach((dbSourceDetail) => {
          const source = dbSourceDetail.source;
          this.addedDbExternalToMasterDB({...dbSourceDetail, numberValue: dbSourceDetail.package_used_number, id: dbSourceDetail.product_package_code, item_name: dbSourceDetail.package_name, source: source,  type: DB_TYPE.SERVICE_LINK});
        });
      }
      const header = this.getHeaderTable();
      if(this.$route.query?.import) {
        await this.handleCsvNew(header)
      }
      if(!this.$route.query?.import) {
        const itemDbKeyBinding = this.pattern?.valueSource
        this.originalData = this.listDataPattern?.filter((element) => {
          if(itemDbKeyBinding) {
            let newID = makeNewId(element[itemDbKeyBinding], element.type);
            if(this.getDbCustomizeOrDbMasterById[element[itemDbKeyBinding]]) {
              newID = element[itemDbKeyBinding]
            }
            if (!this.getDbCustomizeOrDbMasterById[newID]) {
              element[itemDbKeyBinding] = element.db_customize?.name_basic || element.db_master?.item_name;
            } else {
              element[itemDbKeyBinding] = newID;
            }
          }
          this.selectedValuePulldown[element.clientRowId] = {
            id: element[itemDbKeyBinding],
            source: element['wsu_source'],
          }

          const isDuplicate = uniqueIds.has(element.id);
          uniqueIds.add(element.id);

          return !isDuplicate;
        });
      } else {
        this.originalData = this.listDataPattern
        if(this.lengthCsv > 0) {
          this.isShowPopupCsv = true
          this.messagePopupCsv = this.$t("register_data.popup_message_import_success", {rowNum: this.lengthCsv});
          if(this.itemErrorCount > 0) {
            this.messagePopupCsv += `\n\n${this.itemErrorCount}${this.$t("register_data.popup_message_import_failed")}`;
          }
        }
      }


      this.initialView();
      this.$nextTick(() => {
        this.scrollToTop();
        this.timeoutUndoStack = true;
      });
      this.actionUpdateIsLoading(false);
    },
    updateOrganization(data) {
      const { rowData, value } = data
      rowData.organizational_division = this.getObjOrigination[value]?.key
    },
    updateWsuType(data) {
      const { rowData, value } = data
      rowData.wsu_type = this.getWsutypeObj[value]
    },
    handleChangeTextToIdInSpecialPattern(rowData, binding, value) {
      const data = {rowData, binding, value}

      if(binding === 'organizational_division') { //update id for organizational_division
        this.updateOrganization(data)
      }
      if(binding === 'wsu_type') { //update wsu_type id  for s3c5 s3c12
        this.updateWsuType(data)
      }
    },
    handleAddValueSourceIntoGrid(valueSource) {
      this.bindingDataSource(this.scope, this.category, this.method, this.methodLayer2, valueSource)
    },
    bindingDataSource(scope, category,method, methodLayer2, data) {
      const values = data;
      const sourcesSelection = {};
      const dbSource = this.getDbCustomizeOrDbMasterByWsuSource[values.source]
      if(dbSource) return
      let dataScope = scopeCategorySourcesData
      if(this.settingPcafBySelectMonth) {
        dataScope[dataScope.length - 1].dbKey = 'source'
      }

      for (const c of dataScope) {
        if (
          (c.scope === undefined || c.scope === scope) &&
          (c.category === undefined || c.category === category) &&
          (c.method === undefined || c.method === method) &&
          (c.methodLayer2 === undefined || c.methodLayer2 === methodLayer2)
        ) {
          sourcesSelection['data'] = c.data;
          sourcesSelection['dbKey'] = c.dbKey;
        }
      }
      let typeDb = 2
      if(!data?.type && data?.type == 0) typeDb = 0
      if(data?.type) typeDb = data.type
      const newId  = makeNewId(values.origin_id, typeDb)

      if (!dbSource) {
        const sourceSelectIDE = {
          id: values.origin_id,
          [sourcesSelection.dbKey]: values.source,
          db_source_id: values.db_source_id,
          source: values.source,
          unit_source: values.unit_source,
          value_source: values.value_source,
          id_minor_processed: newId,
          type: typeDb,
          item_name: values.source,
          unit: values.unit
        }
        this.addedDbExternalToMasterDB(sourceSelectIDE);
        this.flexgrid.columnGroups = this.getHeaderTable();
        this.flexgrid.columns.forEach(column => {
          const columnData = this.flexgrid.getColumn(column);
          if (columnData) {
            columnData.minWidth = getWidthByTextContent(columnData.header) + 10;
          }
        });

      }
    },
    async handleCsvNew(header) {
      try {
        const fileData = sessionStorage.getItem('fileCsvName');
        this.lengthCsv = 0
        this.itemErrorCount = 0
        if(!fileData) return
        const data = await getCsvDataAction({ file_name: fileData})
        if(!data) return
        let branchList = JSON.parse(JSON.stringify(this.$store.state.registerData));
        const qualityScore = (this.isSettingPcaf && this.category === 15) ? this.pattern.getQualityScore(this.itemSelectPCAF?.dataTypeIdSelect, this.itemSelectPCAF) : null
        this.flexgrid.columnGroups = this.getHeaderTable({ useAllDbItem: true }); // update all db for pulldown

        const { csvData, error, itemErrorCount, filterIndex } = convertCsvToJsonNew({
          csvData: data.data,
          header,
          scope: this.scope,
          category: this.category,
          numberItem: this.listNumberItems,
          calcEmissions: this.pattern.calcEmissions,
          listBranchObj: branchList.listBranchObj,
          method: this.method,
          methodLayer2: this.methodLayer2,
          isSettingPcaf: this.settingPcafBySelectMonth,
          dbStore: this.getDbStore,
          isLimitedAttributionFactor: this.isLimitedAttributionFactor,
          qualityScore,
          dataGicsByYear: this.gicsList[this.selectedYear],
          basicUnitData: this.basicUnitData
        }, this.bindingDataSource, this.handleAddDbMasterToList);

        this.itemErrorCount = itemErrorCount
        if(error.length > 0) {
          sessionStorage.removeItem('fileCsvName');
          const query = this.$route.query;
          const dataParams = {
            isErrorCsv: true,
            csv: true,
            title: query.title,
            scope_select: query.scope_select,
            methods: query.methods,
            methods2: query.methods2,
            month: query.month,
            categoryName: query.categoryName
          }
          if(this.isSettingPcaf && this.category === 15) {
            dataParams.categoryIdSelect = this.itemSelectPCAF.categoryIdSelect
            dataParams.dataTypeIdSelect = this.itemSelectPCAF.dataTypeIdSelect
          }
          this.$router.push({
            path: '/emissions/list-menu',
            query: dataParams
          })
          return
        }
        this.flexgrid.columnGroups = this.getHeaderTable();
        this.listDataPattern = [ ...csvData || [] ];
        this.lengthCsv = csvData.length;
        sessionStorage.removeItem('fileCsvName');
        return csvData
      } catch (error) {
        console.warn(error);
      }
    },
    handleCsvImportEvent(data) {
      const jsonData = convertCsvToJson(data, this.flexgrid);
      this.listDataPattern = jsonData;
    },
    validateNumberField(input) {
      if (input) {
        let numberData = input.replace(/[^\d.]/g, '');
        if (Number(input) < 0) {
          return this.$t("validation.error_positive_value");
        }
        if (numberData && !(input.length - numberData.length)) {
          return null;
        } else {
          return this.$t("validation.error_number_field");
        }
      } else {
        return this.$t("validation.error_required_field");
      }
    },
    getError(item, propName) {
      // validate row if has data
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
        return null;
      }
      if(propName === null) {
        return null;
      }
      return this.pattern?.getError(item, propName, this.getDbStore, {itemsInView: this.itemsInView, branchData: this.branchData, isCheckMappingFacility: true, itemsInViewError: this.itemsInViewError}, this.isRemoveEmissionFactorOrValNumber);
    },
    resetMonth() {
      this.updateDataMonth(this.startMonth);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        if (!this.isCheckedError) {
          this.pattern.addBlankItemsToView(this.dataTable, this.isApproval ? 0 : 100);
        }
        this.scrollToTop();
      } else {
        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    initialView() {
      if (this.dataTable && !this.$route.query?.import) {
        this.originalData = this.dataTable.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      this.$store.dispatch('commonApp/actionUpdateIsSuccess', false, { root: true });
      const formatItems = [
        'value', 'loading_data_maximum', 'loading_data_rate',
        'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
        'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
        'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
        'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
        'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
        'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
        'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value','wsu_value','emissions', 'il_amount', 'il_total_amount', 'ie_ghg_emissions',
        'dq_quality_score', 'revenue', 'purchase_level', 'amount', 'total_capital', 'value_number_1', 'value_number_2', 'value_purchase', 'emissions_factor', 'value_number'
      ];
      this.dataTable = new wjcCore.CollectionView([...this.originalData || []], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        }
      });
      if (this.flexgrid !== null) {
        let branchList = JSON.parse(JSON.stringify(this.$store.state.registerData));
        branchList.listType = branchList.listType.filter(item => item.status === true);
        branchList.listBranch.company_name = this.branchData.company_name.filter(item => item.status === true);
        this.flexgrid.columnGroups = this.getHeaderTable();
        this.flexgrid.columns.forEach(column => {
          const columnData = this.flexgrid.getColumn(column);
          if (columnData) {
            if(['dir_date', 'invalid_dir_date_source'].includes(columnData.binding) && this.language.code === 'es'){
              columnData.minWidth = 200;
            } else if(['energy_type', 'wsu_source'].includes(columnData.binding)) {
              columnData.minWidth = 300;
            } else {
              columnData.minWidth = getWidthByTextContent(columnData.header) + 10;
            }
          }
        })
        setMinMaxSizeColumns(this.flexgrid, this.originalData);
        this.flexgrid.refresh();
      }
      const userAgent = navigator.userAgent.toLowerCase();
      const isMacOrUbuntu = ['mac', 'darwin', 'ubuntu'].some(browser => userAgent.indexOf(browser) !== -1)
      const timeWaitingByBrowser = isMacOrUbuntu ? 2000 : 500
      
      let gicObject = {}
      if(this.settingPcafBySelectMonth) {
        const gicsOfAllYear = this.getFullGicObjectOfAllYear()
        gicObject = gicsOfAllYear[this.selectedYear]
      }
      this.pattern.addBlankItemsToView(this.dataTable, this.isApproval ? 0 : 10);
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      let getDbStore = {}
      const layerResponse = this.layerData.map(item => item.layer_field)
      const filterLayer = [FILTER_DATA.ORGANIZATIONAL, FILTER_DATA.COMPANY, ...layerResponse]
      this.dataTable.collectionChanged.addHandler(throttle(() => {
        if(this.isAddIdeaDb && this.dataTable?.itemsAdded.length > 0) {
          this.handleUpdateHeader()
        }
        this.dataTable?.itemsAdded?.forEach((dataTableItemAdded, index) => {
          if(index === 0) {
            getDbStore = this.getDbStore
          }

          if (itemsInView.every((field) => dataTableItemAdded[field] === null || dataTableItemAdded[field] === '' || dataTableItemAdded[field] === undefined)) {
            return;
          }
          this.listNumberItems.forEach(key => {
            if(dataTableItemAdded[key]) {
              const isNumber = $_helper_isNumberType_bySetting(dataTableItemAdded[key]);
              const range = this.getSubstringRange(key);
              const subStringRang = dataTableItemAdded[key]?.toString()?.includes('-') ? range + 1 : range;
              const formatValue = this.formatNumberRealNum((removeSpecialChars(dataTableItemAdded[key]))?.substring(0, subStringRang))
              dataTableItemAdded[key] = isNumber ? formatValue : ''
            }
          })

          if(dataTableItemAdded.ie_year) {
            dataTableItemAdded.ie_year = formatValue(dataTableItemAdded.ie_year)
          }
          if(!dataTableItemAdded?.currency && this.settingPcafBySelectMonth) {
            if (!this.isDeleteTingEvent && !dataTableItemAdded?.isDeleteRow) {
              dataTableItemAdded.currency = CURRENCY_TYPE[0]
            }
          }
          if (this.settingPcafBySelectMonth) {
            if (dataTableItemAdded['ides_industry'] && !dataTableItemAdded['emissions_factor'] && !dataTableItemAdded['classify_gics'] && !dataTableItemAdded?.isDeleteRow) {
              dataTableItemAdded['emissions_factor'] = this.isRemoveEmissionFactorOrValNumber ? 1 : this.formatNumberRealNum(this.getDbCustomizeOrDbMasterById[dataTableItemAdded['ides_industry']]?.value_source);
            } else if (dataTableItemAdded['classify_gics'] && !dataTableItemAdded['emissions_factor'] && !this.isDeleteTingEvent && !dataTableItemAdded?.isDeleteRow) {
              const gicsItem = gicObject[dataTableItemAdded['classify_gics']]
              dataTableItemAdded['emissions_factor'] = this.formatNumberRealNum(gicsItem?.autoField?.substring(0, 25));
            }
          }
          const dataProps = {
            dataTable: dataTableItemAdded,
            bindingLayout: this.itemsInView,
            isSaveTemplate: false,
            branchData: this.branchData,
            isGetBranchDetail: false,
            dbStore: this.getDbStore,
            isLimitedAttributionFactor: this.isLimitedAttributionFactor,
            isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
            typeOfPattern: this.itemSelectPCAF,
            isDeleteTingEvent: this.isDeleteTingEvent,
            variables: {setAutoField: false}
          }
          this.pattern.handlerDataOnTable(dataProps); //for auto fill items add
          if (!dataTableItemAdded['clientRowId']) dataTableItemAdded['clientRowId'] = this.dataTable.sourceCollection.indexOf(dataTableItemAdded) + 1;
          // set filterIndex per row
          if (!this.workFlowData?.id) {
            const filterIndex = {};
            let filterItem = filterIndex[dataTableItemAdded['clientRowId']]
            if (typeof filterItem === 'undefined') {
              filterItem = [];
            }
            filterLayer.forEach(item => {
              if (dataTableItemAdded[item]) {
                filterItem.push(item);
              }
            })
            if (filterItem.length > 0) this.pattern.updateFilterIndex(filterIndex);
          }
        })

        this.dataTable?.itemsEdited.forEach(dataItemEdited => {
          if(dataItemEdited.ie_year) {
            dataItemEdited.ie_year = formatValue(dataItemEdited.ie_year)
          }
        })
      }, timeWaitingByBrowser))

      if (this.pattern !== null) {
        this.updateTotalEmissionsHandler();
      }
      this.isUpdated = false;
      this.checkTableHasData();
      this.dataTable.currentItem = null;
    },
    scrollToTop() {
      if (!this.flexgrid) {
        return;
      }

      try {
        let rc = this.flexgrid.cells.getCellBoundingRect(0, 0, true);
        this.flexgrid.scrollPosition = new wjcCore.Point(this.flexgrid.scrollPosition.x, -rc.top);
      } catch (error) {
        console.warn(error);
      }
    },
    async getDbByType(type, scopeType) {
      await this.getDetailMasterDb({
        db_types: type,
        scope_type: scopeType || null,
        contractor_id: this.contractor,
        category_id: this.category,
        scope_id: this.scope,
        status: true,
      });
    },
    async getDataWithDbCustomize() {
      const response = await getListDbCustomize({
        contractor_id: this.contractor,
        category_id: this.category,
        scope_id: this.scope,
        status: true,
      });
      const masterDbCustom = response.data.concat(this.dbMasterAddNew)
      this.masterDb = masterDbCustom
      this.dbExternalAdded =  {
        [DB_TYPE.IDEA]: [],
        [DB_TYPE.SERVICE_LINK]: [],
        [DB_TYPE.MASTER]: [],
        [DB_TYPE.CUSTOMIZE]: [],
      },
      this.dbMasterOriginal = [...masterDbCustom]
      this.uniqueOriginalDb = prepareMasterOrCustomizeDB(this.dbMasterOriginal)
      this.getCustomeObjDbMaster()
      this.getObjDbMaster()
    },
    formatValueSourceNumber(number) {
      const subRange = number?.toString()?.includes('-') ? 26 : 25
      return number?.toString()?.slice(0, subRange)
    },
    formatValueSourceNumber1(number) {
      let formatNumber = '';
      if (number?.includes('.')) {
        const natural = number.split('.')[0].length;
        const decimals = number.split('.')[1].length;
        if (natural + decimals + 1 <= 20) {
          return number;
        }
        formatNumber = parseFloat(number).toFixed(20 - natural - 1);
        return formatNumber;
      }
      return number;
    },
    canEffectChangeDataTable(theRowData, currentUserSection) {
      let currentTableSheet = this.$store.state.registerData;
      return (
        currentUserSection !== this.$socket.id &&
        currentTableSheet.duration === theRowData.duration_id &&
        currentTableSheet.yearSelected === theRowData.year &&
        currentTableSheet.month === theRowData.month
      );
    },
    createUndoStack() {
      this.undoStack = new UndoStack('#undoable-table', {
        maxActions: 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        },
      });
    },
    onFlexGridInitialDone(flexgrid, isImportCsv = true) {
      if (!flexgrid) {
        return;
      }
      // this.flexgrid = null
      this.flexgrid = flexgrid;
      let columnNum = null;
      const formatItems = [
        'value', 'loading_data_maximum', 'loading_data_rate',
        'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
        'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
        'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
        'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
        'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
        'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
        'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value','emissions',
        'ie_ghg_emissions', 'il_amount', 'il_total_amount', 'attribution_factor', 'revenue', 'purchase_level', 'government_bond_emissions','gdp_ppp_adjusted',
        'loan_amount', 'amount', 'total_capital', 'value_number_1', 'value_number_2', 'value_purchase', 'emissions_factor', 'value_number'
      ];
      this.createUndoStack();
      const isScope3Category15PCAF = this.isSettingPcaf && this.scope === 3 && this.category === 15;
      this.flexgrid.cellEditEnded.addHandler((s, e) => {
        this.checkTableChangeData();
        this.checkTableHasData();
        this.handleConvertFullWidthToHaftWidth(s, e);
        const isDeleteTing = e.data && (e.data.key === 'Backspace' || e.data.key === 'Delete')
        this.isDeleteTingEvent = isDeleteTing;
        const column = s.columns[e.col];
        const { row, col } = e.range
        const isBindingEmissionsFactorPCaf = ['emissions_factor', 'ides_industry'].includes(column.binding) && isScope3Category15PCAF

        if((column.binding === 'currency' || column.binding === 'dq_quality_score' || isBindingEmissionsFactorPCaf) && isDeleteTing) {
          this.dataTable.items[row].isDeleteRow = true;
        }
        else {
          this.dataTable.items[row].isDeleteRow = false;
        }
        if(formatItems.includes(column.binding) || this.isWsuValueS3c16(column.binding)){
          const { row, col } = e.range
          let cellData = s.getCellData(row, col, false)?.toString()
          const isNumber = $_helper_isNumberType_bySetting(cellData);
          const range = this.getSubstringRange(column.binding)
          const subStringRang = cellData?.includes('-') ? range + 1 : range;
          const formatValue = this.formatNumberRealNum((removeSpecialChars(cellData))?.substring(0, subStringRang))
          // remove logic emission factor
          s.setCellData(row, col, isNumber ? formatValue : '', false, true);
        }

        if(this.scope === 1 && this.method === 2 || this.scope === 2 || this.scope == 3 && this.category == 9 && this.method == 1 && this.methodLayer2 == 1) {
          if(column.binding === 'energy_type') {
            const { row, col } = e.range
            const item = this.dataTable.items[row]
            const energy_type = this.getDbCustomizeOrDbMasterById[item.energy_type];
            if(energy_type) {
              if((energy_type.ocr_type === 2 && !item.template_id) || (energy_type.ocr_type === 1 && item.template_id)) {
                this.dataTable.items[row].is_ocr = false;
                this.dataTable.items[row].file_ocr = null;
                this.dataTable.items[row].evidence = null;
                this.dataTable.items[row].file_name = null;
              }
            }
          }
        }
        const dataStore = this.getDbStore.getDbCustomizeOrDbMasterById
        const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(this.getDbStore.dbExternalAdded))
        if (this.category != 14 && (this.category == 15 && this.isSettingPcaf) === false) {
          const { cellData, currentItem, clientRowId } = this.handleDataOnCheckError({s, rowIndex: row, colIndex: col, colBinding: column.binding});
          const dataBinding = { s, binding: column.binding, row: clientRowId, cellData, currentItem, dataStore, dbStore: this.getDbStore, dbAddNewInObject }
          this.pattern.getAutoBindingDbSource(dataBinding)
        }
        this.updateTotalEmissionsHandler();
        this.getDataRowOnCheckError();
      });
      //TODO: handler pasted check errors
      this.flexgrid.pastedCell.addHandler((s, e) => {
        const {col, row} = e.range
        const binding = s.columns[col].binding;
        if (formatItems.includes(binding) || binding === 'value' || this.isWsuValueS3c16(binding)) { // formatItems.includes(colBinding)
          const cellData = s.getCellData(row, col, false)
          if (!cellData) return;
          const isNumber = $_helper_isNumberType_bySetting(cellData);
          const range = this.getSubstringRange(binding);
          const subStringRang = cellData?.toString()?.includes('-') ? range + 1 : range;
          const currentItem = s.rows[row]?.dataItem || {};
          const formatValueNumber = this.formatNumberRealNum((removeSpecialChars(cellData))?.substring(0, subStringRang))
          // const valueFormat = binding === 'emissions_factor' ? formatValue(cellData)?.substring(0, subStringRang) : formatValueNumber
          currentItem[binding] = isNumber ? formatValueNumber : ''
          const isLimitedAttributionFactor = currentItem?.is_in_approx === false ? false : currentItem?.is_in_approx || this.isLimitedAttributionFactor
          currentItem.emissions = this.pattern.calcEmissions({ ...currentItem, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber, dbStore: this.getDbStore })
        }
      });
      this.flexgrid.pasted.addHandler((s, e) => {
        this.checkTableHasData();
        this.checkTableChangeData();
        const isDontUseDb = !this.planType?.is_pcaf && [15, '15'].includes(this.category)
        if(this.lastPastingRow > 0 && !isDontUseDb) {
          this.handleUpdateHeader() //update header to get new DB list
          this.lastPastingRow = 0
          this.isAddIdeaDb = false
        }
        const view = s.collectionView
        const source = view.sourceCollection
        const { col, col2, row, row2 } = e.range
        const isChangeEmissionFactorByType = [5,6].includes(this.itemSelectPCAF?.dataTypeIdSelect)
        const dataStore = this.getDbStore.getDbCustomizeOrDbMasterById
        const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(this.getDbStore.dbExternalAdded))

        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const colBinding = s.columns[colIndex].binding
            
            if (formatItems.includes(colBinding) || colBinding === 'value' || this.isWsuValueS3c16(colBinding)) { // formatItems.includes(colBinding)
              const { cellData, currentItem } = this.handleDataOnCheckError({s, rowIndex, colIndex, colBinding});

              if (!cellData) continue;
              const isNumber = $_helper_isNumberType_bySetting(cellData);
              const range = this.getSubstringRange(colBinding);
              const subStringRang = cellData?.toString()?.includes('-') ? range + 1 : range;
              const formatValue = this.formatNumberRealNum((removeSpecialChars(cellData))?.substring(0, subStringRang))
              currentItem[colBinding] = isNumber ? formatValue : ''
              const isLimitedAttributionFactor = currentItem?.is_in_approx === false ? false : currentItem?.is_in_approx || this.isLimitedAttributionFactor
              currentItem.emissions = this.pattern.calcEmissions({...currentItem, isLimitedAttributionFactor: isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber, dbStore: this.getDbStore})
            }
            if(this.scope === 1 && this.method === 2 || this.scope === 2 || this.scope == 3 && this.category == 9 && this.method == 1 && this.methodLayer2 == 1) {
              if(colBinding === 'energy_type') {
                const item = this.dataTable.items[rowIndex]
                const energy_type = this.getDbCustomizeOrDbMasterById[item?.energy_type];
                if(energy_type) {
                  if((energy_type.ocr_type === 2 && !item.template_id) || (energy_type.ocr_type === 1 && item.template_id)) {
                    this.dataTable.items[rowIndex].is_ocr = false;
                    this.dataTable.items[rowIndex].file_ocr = null;
                    this.dataTable.items[rowIndex].evidence = null;
                    this.dataTable.items[rowIndex].file_name = null;
                  }
                }
              }
            }

            if(colBinding === 'classify_gics' && this.isSettingPcaf && this.isSearchEmissionView && isChangeEmissionFactorByType) {
              const currentItem = source[rowIndex] || {}
              const fullDataGic = this.getFullGicObjectOfAllYear()
              const gicsValOfYearData = fullDataGic[currentItem.year]
              const newGicObj = gicsValOfYearData[currentItem.classify_gics]
              currentItem.emissions_factor = this.formatNumberRealNum(formatNumberBySetting(newGicObj?.autoField)?.substring(0, 25))
            }
            if (this.category != 14 && (this.category == 15 && this.isSettingPcaf) === false) {
              // handle check error function
              // not using for s3c14 and PCAF
              const { cellData, currentItem, clientRowId } = this.handleDataOnCheckError({s, rowIndex, colIndex, colBinding});
              this.pattern.getAutoBindingDbSource({ s, binding: colBinding, row: clientRowId, cellData, currentItem, dataStore, dbStore: this.getDbStore, dbAddNewInObject })
            }
          }
        }
        setTimeout(() => {
          this.actionUpdateIsLoadingTable(false)
          this.updateTotalEmissionsHandler();
          if (this.isCheckedError) this.onHandleShowError(true)
        }, 0)
      });
      this.flexgrid.deletedRow.addHandler(() => {
        this.checkTableHasData();
        this.checkTableChangeData();
        this.updateTotalEmissionsHandler();
      })
      this.flexgrid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding === this.pattern.bindingValueColumn) {
          const rowData = s.rows[e.row].dataItem
          if(rowData) {
            const idServiceSource = rowData?.detailExternalProductCode
            if(idServiceSource) {
              wjcCore.addClass(e.cell, "auto-increment");
            }
          }
        }
        if(this.isViewOnlyServiceLink) {
          if (e.panel == s.cells && colBinding === 'service_detail') {
            const rowData = s.rows[e.row].dataItem
            const idServiceSource = rowData?.detailExternalProductCode
            const isDisableByStatus = !rowData?.status && this.isApproval
            if (!idServiceSource || isDisableByStatus) {
              wjcCore.addClass(e.cell, "btn-disabled");
            }
          }
        }
      });
      this.flexgrid.selectionChanged.addHandler((s, e) => {
        const column = s.columns[e.col];
        if(column.binding !== columnNum) {
          columnNum = column.binding;
        }
      });
      this.flexgrid.beginningEdit.addHandler((s, e) => {
        const column = s.columns[e.col];
        const rowValue = s.rows[e.row]._data;
        const { row, col } = e.range

        if(column.binding !== "status" && !rowValue?.status && this.isApproval) {
          e.cancel = true;
        }
        if(rowValue?.is_primary_data || (this.workFlowData?.id && !this.workFlowData.is_active && this.isApproval)) { // disable edit if rowdata is primary_data
          e.cancel = true;
        }

        if (e.panel == s.cells && column.binding === this.pattern.bindingValueColumn) {
          const idServiceSource = rowValue?.detailExternalProductCode;
          if(idServiceSource) {
            e.cancel = true;
          }
        }
        if(formatItems.includes(column.binding) && column.binding !== 'emissions'){
          const cellData = s.getCellData(row, col, false)
          const decimalSeparatorComma = [1,3].includes(this.getDigitsBySetting);
          const formatCell = decimalSeparatorComma ? removeSpecialChars(cellData)?.replace(/\./g, ',') : removeSpecialChars(cellData);
          s.setCellData(row, col, formatCell, false, true);
        }
        
        if (column.binding === "status") {
          if(!this.isApproval || (!this.workFlowData.is_active && this.workFlowData?.id)) {
            e.cancel = true;
            return
          }
          if(rowValue.isChecking) return
          rowValue.isChecking = true
          e.cancel = true;
          this.stackUndoWhenUncheck = new GridEditAction(s, e);
          this.handleCheckBox(rowValue);
        }
      });
      this.flexgrid.pasting.addHandler((s, e) => {
        const view = s.collectionView
        const source = view.sourceCollection
        const { col, col2, row, row2 } = e.range
        this.isDeleteTingEvent = false
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const currentItemStatus = source[rowIndex]?.status
            const colBinding = s.columns[colIndex]?.binding
            const isPrimaryData = source[rowIndex]?.is_primary_data
            if (((!currentItemStatus && colBinding !== 'status') || (isPrimaryData && colBinding !== 'status')) && this.isApproval) {
              e.cancel = true;
            }
          }
        }
      })
      
      flexgrid.lostFocus.addHandler(() => {
        // handle for check error when edit 1 row only
        this.getDataRowOnCheckError();
      })
      
      this.highlightChangedRows();

      this.flexgrid.scrollPositionChanged.addHandler(debounce(() => {
        this.highlightChangedRows();
        this.flexgrid.refresh()
      }, 1000))

      this.flexgrid.hostElement?.addEventListener(
        'keydown',
        (event) => {
          preventKeydownNumber({
            event,
            getDigitsBySetting: this.getDigitsBySetting,
            columnNum,
            formatItems,
            isWsuValueS3c16: this.isWsuValueS3c16
          })
        },
        false
      );

      if(this.$route.query?.import && isImportCsv) {
        setTimeout(() => {
          this.renderDataPatternOnTable()
        }, 2000)
      }
    },
    closeDialogStatus() {
      this.dialogStatusPopup = false
      this.dataTable.currentItem.isChecking = false
    },
    handleCheckBox(value) {
      if (value?.status) {
        this.dialogStatusPopup = true;
      } else {
        this.checkboxHandler(true);
      }
    },
    checkboxHandler(check) {
      this.dataTable.beginUpdate();
      this.dataTable.currentItem.status = check;
      setTimeout(()=> {
        this.dataTable.currentItem.isChecking = false
      }, 50)
      
      this.dataTable.endUpdate();
      this.addDataHasChangeStatus(this.dataTable.currentItem)
      this.updateTotalEmissionsHandler()
      this.dialogStatusPopup = false;
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
    },
    addDataHasChangeStatus(currentItem) {
      if(this.itemsChangeStatus[currentItem.id]) {
        delete this.itemsChangeStatus[currentItem.id]
        return
      }

      this.itemsChangeStatus[currentItem.id] = currentItem
      if(Object.values(this.itemsChangeStatus)) {
        this.isChangeStatus = true
      }
    },
    handleConvertFullWidthToHaftWidth(s, e) {
      const { row, col } = e.range;
      let cellData = s.getCellData(row, col, false)?.toString();
      const isNumber = $_helper_isNumberType_bySetting(cellData);
      cellData = cellData && isNumber ? cellData : convertNestedFullWidthToHalfWidth(cellData);
      s.setCellData(row, col, cellData, false, true);
    },
    highlightChangedRows() {
      if (this.dataPattern.data !== undefined && this.flexgrid) {
        let tooltip = new wjcCore.Tooltip();
        this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
          let accessRowIndex = eventHandler.row;
          let currentItem = this.dataPattern.data[accessRowIndex];
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            let currentItemChanged =
              currentItem !== undefined && currentItem['changed_columns'] !== undefined
                ? currentItem['changed_columns']
                : [];
            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (Object.keys(currentItemChanged).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              let column = handler.columns[eventHandler.col];
              if ('branch_id' in currentItemChanged) {
                Object.values(this.layerIndex).forEach((layer) => {
                  currentItemChanged[layer] = currentItemChanged['branch_id'];
                });
                currentItemChanged['organizational_division'] = currentItemChanged['branch_id'];
                currentItemChanged['company_name'] = currentItemChanged['branch_id'];
                delete currentItemChanged['branch_id'];
              }
              if (this.pattern.autoFields.includes(column.binding) && currentItemChanged[column.binding]) {
                delete currentItemChanged[column.binding];
              }
              if ("db_master_id" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_master_id"];
                });
              }
              if ("db_customize_id" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_customize_id"];
                });
              }
              if ("fuel" in currentItemChanged && this.pattern?.valueSource === 'fuel') {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["fuel"];
                });
              }
              if ("db_relation" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_relation"];
                });
              }
              if ("certification_standard_type_id" in currentItemChanged) {
                Object.values(this.pattern.autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["certification_standard_type_id"];
                });
              }
              if ("evidence" in currentItemChanged) {
                currentItemChanged.attach_file = currentItemChanged.evidence
              }
              if ("business_group_id" in currentItemChanged) {
                currentItemChanged.pcaf_business_group_name = currentItemChanged.business_group_id
                currentItemChanged.pcaf_business_name = currentItemChanged.business_group_id
              }

              if (
                Object.keys(currentItemChanged).length &&
                (Object.keys(currentItemChanged).includes(column.binding))
              ) {
                // let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                let updatedAt = moment
                  .utc(currentItemChanged[column.binding].updated_at)
                  .add(9, 'hours')
                  .format('YYYY/MM/DD HH:mm:ss');
                let updatedBy = currentItemChanged[column.binding].updated_by;
                if(this.isCheckLang === 'vi') {
                  tooltip.setTooltip(
                  eventHandler.cell,
                  `<div class='wj-cell-changed'>${this.$t("register_data.description_modified_by")} ` +
                    ` ${this.$t("register_data.description_administrator")}<br/>` + updatedBy + ' ' +
                    updatedAt +
                    '</div>',
                );
                } else {
                  tooltip.setTooltip(
                  eventHandler.cell,
                  `<div class='wj-cell-changed'>${this.$t("register_data.description_administrator")} ` +
                    updatedBy +
                    ` ${this.$t("register_data.description_modified_by")}<br/>` +
                    updatedAt +
                    '</div>',
                  );
                }
              }
            }
          }
        });
      }
    },
    actionRightClick(value) {
      if(value === ACTION_CODE.DELETE) {
        this.checkTableHasData();
        this.checkTableChangeData();
        this.updateTotalEmissionsHandler();
      }
    },
    highlightDuplicatedRows(data) {
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        if (eventHandler.panel.cellType === wjGrid.CellType.Cell && data?.includes(accessRowIndex)) {
          eventHandler.cell.classList.add('wj-duplicated-data');
        }
      });
    },
    checkTableHasData() {
      if((this.$route.query?.import && this.listDataPattern.length > 0)) {
        this.isHasDataTable = true;
        return true
      }
      if (typeof this.pattern.cellInputInView(this.itemsInView) === 'object') {
        this.isHasDataTable = false;
        const itemsInView = this.pattern.cellInputInView(this.itemsInView);
        this.dataTable.sourceCollection.some((item) => {
          const everyColumnsAreNull = itemsInView.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );
          if (!everyColumnsAreNull) {
            this.isHasDataTable = true;
            return true;
          }
        });
      }
    },
    cloneDataHandler(isSaveTemplate = false) {
      let cloneData = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      this.dataTable.sourceCollection.forEach((item, index) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
          return;
        }
        const isLimitedAttributionFactor = item?.is_in_approx === false ? false : item?.is_in_approx || this.isLimitedAttributionFactor
        const dataProps = {
          dataTable: item,
          bindingLayout: this.itemsInView,
          isSaveTemplate: isSaveTemplate,
          branchData: this.branchData,
          isGetBranchDetail: true,
          dbStore: this.getDbStore,
          isLimitedAttributionFactor: isLimitedAttributionFactor,
          isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
          typeOfPattern: this.itemSelectPCAF
        }
        let data = this.pattern.handlerDataOnTable(dataProps);
        delete data.id;
        cloneData.push({...data, index: index + 1});
      });

      return cloneData;
    },
    async redirectAfterClickBtn(action) {
      this.dialogNotification = false;
      if (this.isShowCheckbox) {
        this.isShowCheckbox = false;
        this.textNoti = null;
        return
      }
      if(!this.isReloadPage) {
        this.isReloadPage = true
        return
      }
      if (
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ||
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN ||
        (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT && action === '') ||
        (this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES && action === 'save-btn-template')
      ) {
        return this.$router.go(this.$router.currentRoute);
      }
      
      if (action !== '') {
        if (action.includes('/emissions/register/list-menu')) {
          await this.$router.push({ path: action });
          return this.$router.go(this.$router.currentRoute);
        } else {
          return this.$router.push({ path: action });
        }
      }
    },
    updateTotalEmissionsHandler(checkEmissions = false) {
      if (this.isOpenScoreSummary) {
        this.updateScoreSummaryTotalEmissionsHandler();
        return;
      }
      let sourceCollectionCurrent = this.dataTable?.sourceCollection;
      this.totalEmissions = 0;
      this.totalTreatment = {
        incinerator: 0,
        landfill: 0,
        recycling: 0,
        unknown: 0,
      };
      const columnsCalcEmission = this.pattern.columnsCalcEmission
      sourceCollectionCurrent?.forEach((item) => {
        const isCalcEmissionByRowValue = columnsCalcEmission?.some(itemKey => item[itemKey])
        if (!isCalcEmissionByRowValue && !item['emissions']) return true;
        const isLimitedAttributionFactor = item?.is_in_approx === false ? false :  item?.is_in_approx || this.isLimitedAttributionFactor
        let newEmissionCalc = this.pattern.calcEmissions({...item, isLimitedAttributionFactor: isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber, dbStore: this.getDbStore})
        if (this.scope === 3 && this.category === 14) newEmissionCalc = formatNumberBySetting(newEmissionCalc);
        const emission25Number = formatNumberBySetting(newEmissionCalc)?.includes('-') ? 26 : 25;
        const itemEmisstion = newEmissionCalc !== 'N/A' && newEmissionCalc !== null ? math.bignumber((newEmissionCalc.toString())?.substring(0, (newEmissionCalc.toString())?.includes('-') ? 51 : 50)) : '0';
        item['emissions'] = this.formatNumberRealNum((newEmissionCalc.toString())?.substring(0, emission25Number));
        if(!item?.status && this.isApproval) return // don't calc total emission if disable data in status approve
        // total emissions can more than 25 digit
        if(isNaN(newEmissionCalc)) {
          newEmissionCalc = '0.00'
        }
        this.totalEmissions = formatBigNumber(math.evaluate(`${math.bignumber(this.totalEmissions).toString()} + ${math.bignumber((newEmissionCalc.toString())?.substring(0, 50)).toString()}`), 50);
        ['ir_owned', 'pj_investment_ratio', 'ir_investment_on_total', 'ir_ratio', 'attribution_factor', 'dir_value'].forEach(key => {
          if(item[key]) {
            const emission25Number = formatNumberBySetting(item[key]).includes('-') ? 26 : 25;
            item[key] = (item[key])?.substring(0, emission25Number);
          }
        })

        if (this.category === 5 || this.category === 12) {
          switch (item['processing_method']) {
            case '焼却':
              this.totalTreatment.incinerator = formatBigNumber(math.evaluate(`${math.bignumber((this.totalTreatment.incinerator)).toString()} + ${itemEmisstion.toString()}`), 50);
              break;
            case '埋立':
              this.totalTreatment.landfill = formatBigNumber(math.evaluate(`${math.bignumber((this.totalTreatment.landfill)).toString()} + ${itemEmisstion.toString()}`), 50);
              break;
            case 'リサイクル':
              this.totalTreatment.recycling = formatBigNumber(math.evaluate(`${math.bignumber((this.totalTreatment.recycling)).toString()} + ${itemEmisstion.toString()}`), 50);
              break;
            case '不明':
              this.totalTreatment.unknown = formatBigNumber(math.evaluate(`${math.bignumber((this.totalTreatment.unknown)).toString()} + ${itemEmisstion.toString()}`), 50);
              break;
            default:
              break;
          }
        }

        if(this.scope === 4) {
          item['dir_value'] = item['emissions']
        }
      });
    
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.totalEmissions);
    },
    editDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false, isUpdateDataApproval = false) {
      let dataRegisterOnTable = [];
      const dataPropsForHandleData = {
        bindingLayout: bindingLayout,
        isSaveTemplate: isSaveTemplate,
        branchData: this.branchData,
        isGetBranchDetail: true,
        dbStore: this.getDbStore,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF
      }

      dataTable.itemsEdited.forEach((dataTableItemEdited) => {
        const index = this.dataTable.items.indexOf(dataTableItemEdited) + 1
        if (Object.values(ORGANIZATIONAL_DATA).includes(dataTableItemEdited.organizational_division)) {
          dataTableItemEdited.organizational_division = parseInt(Object.keys(ORGANIZATIONAL_DATA).find(key => dataTableItemEdited.organizational_division === ORGANIZATIONAL_DATA[key]));
        }
        const isLimitedAttributionFactor = dataTableItemEdited?.is_in_approx === false ? false :  dataTableItemEdited?.is_in_approx || this.isLimitedAttributionFactor

        const dataProps = {
          dataTable: dataTableItemEdited,
          isLimitedAttributionFactor: isLimitedAttributionFactor,
          ...dataPropsForHandleData
        }
        let dataTableItemCustom = this.pattern.handlerDataOnTable(dataProps);
        if (dataTableItemCustom?.id && dataTableItemCustom?.id === BLANK_ID) {
          return;
        }

        if(this.isApproval || this.dataPattern?.workflow_data?.id) {
          dataTableItemCustom.branch_id_old = dataTableItemEdited.branch_id_old
          dataTableItemCustom.business_group_id_old = dataTableItemEdited.business_group_id_old
        }
        dataRegisterOnTable.push({ ...dataTableItemCustom, index: index });
      });
      return dataRegisterOnTable;
    },
    addBlankDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
      let dataRegisterOnTable = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      dataTable.itemsEdited.forEach((dataTableItemEdited) => {
        const index = this.dataTable.items.indexOf(dataTableItemEdited) + 1;
        if (itemsInView.every((field) => dataTableItemEdited[field] === null || dataTableItemEdited[field] === '' || dataTableItemEdited[field] === undefined)) {
          return;
        }
        const isLimitedAttributionFactor = dataTableItemEdited?.is_in_approx === false ? false :  dataTableItemEdited?.is_in_approx || this.isLimitedAttributionFactor
        const dataProps = {
          dataTable: dataTableItemEdited,
          bindingLayout: bindingLayout,
          isSaveTemplate: isSaveTemplate,
          branchData: this.branchData,
          isGetBranchDetail: true,
          dbStore: this.getDbStore,
          isLimitedAttributionFactor: isLimitedAttributionFactor,
          isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
          typeOfPattern: this.itemSelectPCAF
        }
        let dataTableItemCustom = this.pattern.handlerDataOnTable(dataProps);
        if (dataTableItemCustom.id === BLANK_ID) {
          delete dataTableItemCustom.id;
        }
        if (dataTableItemCustom?.id && dataTableItemCustom.id !== BLANK_ID) {
          return;
        }
        if(dataTableItemCustom.db_relation) {
          dataTableItemCustom.db_master_id = null
          dataTableItemCustom.db_customize_id = null
        }
        if(this.settingPcafBySelectMonth) {
          delete dataTableItemCustom.db_master_id
        }
        if (!this.isDraftNotChangeMethod() || this.isCheckedError) {
          dataRegisterOnTable.push({ ...dataTableItemCustom});
        } else {
          dataRegisterOnTable.push({ ...dataTableItemCustom, index});
        }
      });

      return dataRegisterOnTable;
    },
    addDataRegisterOnTable(dataTable, bindingLayout, isSaveTemplate = false) {
      let dataRegisterOnTable = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      dataTable.itemsAdded.forEach((dataTableItemAdded, index) => {
        if (itemsInView.every((field) => dataTableItemAdded[field] === null || dataTableItemAdded[field] === '' || dataTableItemAdded[field] === undefined)) {
          return;
        }
        dataTableItemAdded['organizational_division'] = dataTableItemAdded['organizational_division'] === undefined ? null : dataTableItemAdded['organizational_division']
        dataTableItemAdded['company_name'] = dataTableItemAdded['company_name'] === undefined ? null : dataTableItemAdded['company_name']
        const isLimitedAttributionFactor = dataTableItemAdded?.is_in_approx === false ? false :  dataTableItemAdded?.is_in_approx || this.isLimitedAttributionFactor
        const dataProps = {
          dataTable: dataTableItemAdded,
          bindingLayout: bindingLayout,
          isSaveTemplate: isSaveTemplate,
          branchData: this.branchData,
          isGetBranchDetail: true,
          dbStore: this.getDbStore,
          isLimitedAttributionFactor: isLimitedAttributionFactor,
          isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
          typeOfPattern: this.itemSelectPCAF
        }
        let dataTableItemCustom = this.pattern.handlerDataOnTable(dataProps);
        delete dataTableItemCustom.id;
        if (!this.isDraftNotChangeMethod() || this.isCheckedError) {
          dataRegisterOnTable.push({ ...dataTableItemCustom});
        } else {
          const index = this.dataTable.items.indexOf(dataTableItemAdded) + 1;
          dataRegisterOnTable.push({ ...dataTableItemCustom, index});
        }
      });
      if (this.isFullScreen) {
        dataRegisterOnTable = [];
        dataTable.itemsAdded.forEach((dataTableItemAdded, index) => {
          if (itemsInView.every((field) => dataTableItemAdded[field] === null || dataTableItemAdded[field] === '' || dataTableItemAdded[field] === undefined)) {
            return;
          }
          const isLimitedAttributionFactor = dataTableItemAdded?.is_in_approx === false ? false :  dataTableItemAdded?.is_in_approx || this.isLimitedAttributionFactor
          const dataProps = {
            dataTable: dataTableItemAdded,
            bindingLayout: bindingLayout,
            isSaveTemplate: isSaveTemplate,
            branchData: this.branchData,
            isGetBranchDetail: true,
            dbStore: this.getDbStore,
            isLimitedAttributionFactor: isLimitedAttributionFactor,
            isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
            typeOfPattern: this.itemSelectPCAF
          }
          let dataTableItemCustom = this.pattern.handlerDataOnTable(dataProps);
          delete dataTableItemCustom.id;
          if (!this.isDraftNotChangeMethod() || this.isCheckedError) {
            dataRegisterOnTable.push({ ...dataTableItemCustom});
          } else {
            const index = this.dataTable.items.indexOf(dataTableItemAdded) + 1;
            dataRegisterOnTable.push({ ...dataTableItemCustom, index: index});
          }
        });
      }
      return dataRegisterOnTable;
    },
    removeDataRegisterOnTable(dataTable) {
      let removeItems = [];
      dataTable.itemsRemoved.forEach((dataTableItemRemoved) => {
        if (dataTableItemRemoved?.id && dataTableItemRemoved?.id !== BLANK_ID) {
          removeItems.push(dataTableItemRemoved.id);
        }
      });
      return removeItems;
    },
    isCreateNewData() {
      return !(
        !this.dataPattern?.workflow_data?.id ||
        this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES ||
        this.isGetAllData
      );
    },
    checkSelectedDuration() {
      const isExist = this.durationList.findIndex(
        (duration) => duration.id === this.selectDurationChange && duration.is_selected === true,
      );
      return isExist >= 0 ? true : false;
    },

    checkTableChangeData() {
      // use timeout to waiting itemsEdited in first time
      setTimeout(() => {
        if (typeof this.pattern.cellInputInView(this.itemsInView) === 'object') {
          this.isEditDataTable = false;
          const itemsInView = this.pattern.cellInputInView(this.itemsInView);
          this.dataTable.itemsEdited.some((item) => {
            const everyColumnsAreNull = itemsInView.every(
              (field) => item[field] === null || item[field] === '' || item[field] === undefined,
            );
            if (!everyColumnsAreNull) {
              this.isEditDataTable = true;
              return true;
            }
          });
        } else {
          this.isEditDataTable = false
        }
      }, 100)
    },
    getDataImport(isSaveTemplate = false) {
      let importData = [];
      const itemsInView = this.pattern.cellInputInView(this.itemsInView);
      // const dataImport = ((this.dataTable._view?.length === 0 && this.$route.query?.import) || isSaveTemplate) ? this.dataTable._src : this.dataTable._view
      const dataImport = this.dataTable._src
      dataImport?.forEach((item, index) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
          return;
        }
        const dataProps = {
          dataTable: item,
          bindingLayout: this.itemsInView,
          isSaveTemplate: isSaveTemplate,
          branchData: this.branchData,
          isGetBranchDetail: true,
          dbStore: this.getDbStore,
          isLimitedAttributionFactor: this.isLimitedAttributionFactor,
          isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
          typeOfPattern: this.itemSelectPCAF
        }
        let data = this.pattern.handlerDataOnTable(dataProps);

        data.emissions = formatValue(data.emissions)?.substring(0, 25)
        delete data.id;
        importData.push({...data, status: 1});
      });

      // if (!this.isChangeMethod && this.isCheckedError) {
      //   importData.push(...this.addBlankDataRegisterOnTable(this.dataTable, this.itemsInView).concat(
      //   this.addDataRegisterOnTable(this.dataTable, this.itemsInView),
      // ))
      // }

      return importData;
    },
    onHandleShowExportPopup(value) {
      this.exportDialog = value;
      this.exportDialogKey++;
    },
    async onHandleGotoScoreSummary(value) {
      this.isOpenScoreSummary = value;
      if (value === false) {
        this.$router.push({ path: '/emissions/view/3/15' }); // back to the router old way
        await this.getUrlFromScopeCategory();
        this.getHeaderFromScope();
        this.getDataFromScope();
        this.key ++ //rerender table
      } else {
        this.$router.replace({ path: '/emissions/view/score-summary', query: { selectedYear: this.periodData?.startYear, selectedMonth: this.periodData?.startMonth } });
        // await this.getUrlFromScopeCategory();
        // this.getHeaderFromScope();
        // this.getDataFromScope();
        // this.updateScoreSummaryTotalEmissionsHandler();
      }
    },
    async handleSearchEmission(viewEmissionData) {
      this.isSearchEmissionView = true;
      this.viewEmissionData = viewEmissionData;
      if (Object.keys(this.viewEmissionData).length > 0) {
        const { scope, category, method, methodLayer2, type_data, type_classify, itemsDataType, itemsCategoryType, currentDuration } = this.viewEmissionData
        this.scope = scope
        this.category = category
        this.method = method
        this.methodLayer2 = methodLayer2
        this.itemSelectPCAF.categoryIdSelect = type_classify
        this.itemSelectPCAF.dataTypeIdSelect = type_data
        this.itemSelectPCAF.itemsCategoryType = itemsCategoryType
        this.itemSelectPCAF.itemsDataType = itemsDataType
        this.currentDuration = currentDuration
        this.currentSelected = method
        this.currentSelectedLayer2 = methodLayer2
        this.getMethodList(this.scope, this.category);
        if(this.isLayer2()) {
          this.getMethodListLayer2();
        }
      }
      await this.getUrlFromScopeCategory();
      await getListDetailBranch().then((res) => {
        this.branchData = prepareBranchData(res)
        this.layerData = res.layer;
      });
      this.getHeaderFromScope();
      this.getDataFromScope();
      this.key ++;
    },
    async onUpdateGotoScoreSummary() {
      await this.getUrlFromScopeCategory();
      this.getDataFromScope();
      const patternData = await import(`../../../concerns/newRegisterData/others/scoreSummary.js`);
      this.pattern = patternData.default;
      this.updateScoreSummaryTotalEmissionsHandler();
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    async handleGetServicesLinked() {
      try {
        const dataResponse = await getServicesLinked(true);
        this.listServicesLinked = dataResponse.data;
      } catch (error) {
        console.warn(error);
      }
    },
    setDefaultMethodBySetting(settingGhg) {
      this.method = 1
      this.currentSelected = 1
      this.methodLayer2 = null
      this.currentSelectedLayer2 = null
      this.getMethodListLayer2()

      if(settingGhg) {
        this.methodLayer2 = 5
        this.currentSelectedLayer2 = 5
      }
    },
    handleUpdateMethodByUrl() {
      if(!this.$route.path?.includes('emissions/view')) return

      const scope = Number(this.$route.params.scope)
      const category = Number(this.$route.params.category)
      getDefaultMethods(scope, category, this.settingPcafBySelectMonth)
    },
    isWsuValueS3c16(key){
      return key === 'wsu_value' && this.category === 16
    },
    handleCheckInputDuration() {
      const startYear = this.selectedYear
      const endYear = this.selectedYear
      const startMonth = this.selectedMonth
      const endMonth = this.selectedMonth

      if(!this.selectedMonth || !this.selectedYear) return

      this.checkPcafInDurtionSelect({startYear, endYear, startMonth, endMonth, startMonthSetting: this.startMonth})
    },
    async checkPcafInDurtionSelect(dataSelected) {
      const rangeYear = getRangeFiscalYear(dataSelected)
      // TODO: refactor change year,month
      const isSettingPcafBYSelectYear = this.planType?.is_pcaf;
      const isStatusApproval = [STATUS_FIELD.STATUS_REPRENSENT_APPROVED,STATUS_FIELD.STATUS_APPROVED].includes(this.workFlowData?.status)
      const isRerenderTable = (this.isGetAllData || isStatusApproval)

      if(this.category === 15) {
        this.settingPcafBySelectMonth = isSettingPcafBYSelectYear
        this.updateMethodSetting(isSettingPcafBYSelectYear)
        
        if ((this.isGetAllData || isStatusApproval) && !this.$route.path.includes('/emissions/view/score-summary')) {
          try {
            await this.getUrlFromScopeCategory();
            await getListDetailBranch().then((res) => {
              this.branchData = prepareBranchData(res)
              this.layerData = res.layer;
            });
          } catch (error) {
            console.warn(error);
          }
        }

        if(isRerenderTable && this.planType?.is_pcaf) {
          this.methodLayer2 = null
          if (this.$route.fullPath.includes('/view/score-summary') && this.planType?.is_pcaf) {
            this.isOpenScoreSummary = true
          } else {
            this.isOpenScoreSummary = false
          }
        } else {
          if(this.isEmissionViewScreen) {
            // this.method = 1
            this.methodLayer2 = null
          }
        }
        if(!this.pattern && !this.isApproval) return
        if ((isRerenderTable || this.isEmissionViewScreen) && !this.$route.path.includes('/emissions/view/score-summary')) {
          this.getHeaderFromScope();
          this.getDataFromScope();
          this.getMethodList(this.scope, this.category);
          // this.getMethodListLayer2()
          this.key++;
          this.keySetting++
        }
      } else {
        try {
          if (this.isGetAllData || isStatusApproval) {
            await this.getUrlFromScopeCategory();
            await getListDetailBranch().then((res) => {
              this.branchData = prepareBranchData(res)
              this.layerData = res.layer;
            });
            this.getHeaderFromScope();
            this.getDataFromScope();
            if(isRerenderTable) {
              this.key++;
            }
          }
        } catch (error) {
          console.warn(error);
        }
      }
    },
    async getCertification() {
      try {
        const resCertification = await getCertification()
        this.actionsUpdateCertifications(resCertification?.data);
        this.dataPattern.certification = {};
        resCertification?.data.forEach(item => {
          this.dataPattern.certification[item.id] = item;
        })
      } catch (error) {

      }
    },

    readFileOcrDialog(context) {
      this.itemReadFile = context;
      this.itemReadFile.energy_type = this.getDbCustomizeOrDbMasterById[context.item?.energy_type];
      this.itemReadFile.pattern_id = findRowNumPattern(scopeCategoriesData, this.scope, this.category, this.method, this.methodLayer2);
      this.itemReadFile.isReadOnly = this.isReadOnlyDataTable;
      this.dialogReadFile = true;
    },

    onAddDataReadFile(data){
      const indexStart = this.itemReadFile?.row.index;
      data.data_read_file.forEach((item, index) => {
        const indexItemIntable = indexStart + index;
        this.dataTable.editItem(this.dataTable.items[indexItemIntable]);
        this.dataTable.items[indexItemIntable].memo_1 = item.memo_1;
        this.dataTable.items[indexItemIntable].memo_2 = item.memo_2;
        this.dataTable.items[indexItemIntable].unit = item.unit;
        this.dataTable.items[indexItemIntable].value = item.value;
        this.dataTable.items[indexItemIntable].emissions = this.pattern.calcEmissions(this.dataTable.items[indexItemIntable]);

        if(!this.itemReadFile.item.is_ocr) {
          this.dataTable.items[indexItemIntable].file_ocr = data.file;
          this.dataTable.items[indexItemIntable].evidence = data.file;
          this.dataTable.items[indexItemIntable].file_name = data.file.file_name;
        }
        this.dataTable.items[indexItemIntable].is_ocr = true;
        this.dataTable.items[indexItemIntable].template_id =  data.template_id !== 'receipt' ? data.template_id : null
        this.dataTable.commitEdit();
      })
      this.updateTotalEmissionsHandler();
      this.checkTableChangeData();
      this.checkTableHasData();
    },

    basicUnitSearchPopup(context, key, isBinddingEmissionFactor = false, isDisabled = false) {
      const rowData = context?.row?.dataItem;
      if (isDisabled  || (this.dataPattern?.workflow_data?.status && this.isReadOnlyDataTable) || (!this.dataPattern?.workflow_data?.status && this.isReadOnlyDataTable) || (this.isDisableByStatus && !context?.row?.dataItem?.status)) {
        return;
      }
      if(rowData?.is_primary_data && this.isApproval) {
        return;
      }
      this.selectedRow = context.row.index;
      this.isBinddingEmissionFactor = isBinddingEmissionFactor
      this.detailExternalSource = dbIdeaObj[rowData?.idDbExternal]?.db_source_id || rowData?.detailExternalSourceId;
      this.basicUnitDialog = true;
    },
    externalSourcePopup(context, key, isBinddingEmissionFactor = false, isDisabled = false) {
      const rowData = context?.row?.dataItem;
      if (isDisabled || !this.existDbSource || (this.dataPattern?.workflow_data?.status && this.isReadOnlyDataTable) || (!this.dataPattern?.workflow_data?.status && this.isReadOnlyDataTable) || (this.isDisableByStatus && !context?.row?.dataItem?.status)) {
        return;
      }
      if(rowData?.is_primary_data && this.isApproval) {
        return;
      }
      this.selectedRow = context.row.index;
      this.isBinddingEmissionFactor = isBinddingEmissionFactor
      this.detailExternalSource = dbIdeaObj[rowData?.idDbExternal]?.db_source_id || rowData?.detailExternalSourceId;
      this.externalSourceDialog = true;
    },

    addExternalSource(data, rowIndex, detailExternalSourceId, typeSource) {
      const values = data.item;
      const nameSource = values.source;
      const valueSource = values.value_source;
      const unitSource = values.unit_source;
      const unit = values.unit;
      const sourcesSelection = {};
      let dataScope = scopeCategorySourcesData
      if(this.settingPcafBySelectMonth) {
        dataScope[dataScope.length - 1].dbKey = 'source'
      }

      for (const c of dataScope) {
        if (
          (c.scope === undefined || c.scope === this.scope) &&
          (c.category === undefined || c.category === this.category) &&
          (c.method === undefined || c.method === this.method) &&
          (c.methodLayer2 === undefined || c.methodLayer2 === this.methodLayer2)
        ) {
          sourcesSelection['data'] = c.data;
          sourcesSelection['dbKey'] = c.dbKey;
        }
      }
      const newId  = makeNewId(values.id, typeSource)
      const isIdDuplicate = this.masterDb.some(item => {
        return item.id_minor_processed === newId
      });
      if (!isIdDuplicate) {
        const sourceTemplate = {
          ...values,
          id: values.id,
          [sourcesSelection.dbKey]: nameSource,
          source: nameSource,
          unit_source: unitSource,
          value_source: valueSource,
          id_minor_processed: newId,
          type: typeSource,
          item_name: nameSource,
          unit: unit
        }
        this.addedDbExternalToMasterDB(sourceTemplate);
      
        this.flexgrid.columnGroups = this.getHeaderTable();
        this.flexgrid.columns.forEach(column => {
          const columnData = this.flexgrid.getColumn(column);
          if (columnData) {
            columnData.minWidth = getWidthByTextContent(columnData.header) + 10;
          }
        });

      }
      const dataUpdateCell = {
        [sourcesSelection.data]: newId,
        wsu_unit: unitSource,
        wsu_value: valueSource,
        wsu_source: nameSource,
        unit: unit,
        emissions_factor: this.isBinddingEmissionFactor ? valueSource : null, //for s3c15 pcaf
      }
      // const emissionFactor = this.dataTable.sourceCollection[rowIndex]?.emissions_factor
      // if(this.settingPcafBySelectMonth && emissionFactor) {
      //   dataUpdateCell.emissions_factor = emissionFactor // set old val emissions_factor
      // }
      this.updateFlexgridCells(rowIndex, dataUpdateCell);
      this.updateFlexUnitCells(rowIndex, dataUpdateCell);

      if(this.dataTable.sourceCollection[rowIndex]) {
        this.dataTable.sourceCollection[rowIndex].idDbExternal = newId
        const dbRelationKey = getDbRelationKeysByType(typeSource);
        this.dataTable.sourceCollection[rowIndex].db_relation = {
          [dbRelationKey]: values.id,
        }
        this.dataTable.sourceCollection[rowIndex].detailExternalSourceId = detailExternalSourceId
      }

      const isLimitedAttributionFactor = this.dataTable.sourceCollection[rowIndex]?.is_in_approx === false ? false : this.dataTable.sourceCollection[rowIndex]?.is_in_approx || this.isLimitedAttributionFactor
      const dataProps = {
        dataTable: this.dataTable.sourceCollection[rowIndex],
        bindingLayout: this.itemsInView,
        isSaveTemplate: false,
        branchData: this.branchData,
        isGetBranchDetail: true,
        dbStore: this.getDbStore,
        isLimitedAttributionFactor: isLimitedAttributionFactor,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF
      }
      const dataItem = this.pattern.handlerDataOnTable(dataProps)
      this.dataTable.sourceCollection[rowIndex].emissions = dataItem.emissions
      this.dataTable.sourceCollection[rowIndex].emissions_factor = this.formatNumberRealNum(valueSource)

      this.updateTotalEmissionsHandler()
      this.checkTableChangeData();
      this.checkTableHasData();
    },
    updateFlexgridCells(rowIndex, cellData) {
      if (!this.dataTable.sourceCollection[rowIndex]) {
        this.pattern.addBlankItemsToView(this.dataTable, 1);
      }
      for (const columnName in cellData) {
        const colIndex = this.getColumnIndexByBinding(columnName)
        if (colIndex >= 0) {
          this.flexgrid.setCellData(rowIndex, colIndex, cellData[columnName], false, true);
        }
      }
    },
    updateFlexUnitCells(rowIndex, cellData) {
      // find itemkey is unit in row data & set data unit
      const listItemKeyUnit = ['qd_unit','cpa_unit','energy_unit','unit','travel_unit','te_unit','iss_unit','sales_volume_unit','scale_index_unit']
      listItemKeyUnit.forEach(itemKey => {
        const colIndex = this.getColumnIndexByBinding(itemKey)
        if(colIndex && colIndex >= 0) {
          this.flexgrid.setCellData(rowIndex, colIndex, cellData.unit, false, true);
        }
      })
    },
    getColumnIndexByBinding(binding) {
      return this.flexgrid.columns.findIndex((column) => column.binding === binding);
    },
    getColumnIndex(columnName) {
      return this.flexgrid.getColumn(columnName).index;
    },
    setPatternId(category, scope, method, methodLayer2, isOriginalPattern = false) {
      if (category === 1 && scope === 1) {
        if (method === 2) {
          return 1;
        } else if (method === 3) {
          return 3;
        } else if (method === 4) {
          return 2;
        } else if (method === 1) {
          return 4;
        }
      } else if (scope === 3) {
        if (category === 4) {
          if (method === 1) {
            if (methodLayer2 === 5) {
              return 6;
            } else if (methodLayer2 === 6) {
              return 5;
            }
          }
          if (method === 3) {
            return 2
          }
        }
        if (category === 6) {
          if (method === 1) {
            return 2;
          } else if (method === 2) {
            return 3;
          } else if (method === 3) {
            return 1;
          }
        }
        if (category === 7) {
          if (method === 1) {
            return 2;
          } else if (method === 2) {
            return 3;
          } else if (method === 3) {
            return 1;
          }
        }
        if (category === 9) {
          if (method === 1) {
            if (methodLayer2 === 2) {
              return 3;
            } else if (methodLayer2 === 3) {
              return 2;
            }
          }
        }
        if(category === 11) {
          if (method === 2) {
            return 4
          }
        }
        if(category === 15) {
          return this.settingPcafBySelectMonth ? method + 4 : method
        }
      }
      let patternId = null;
      if (isOriginalPattern) {
        patternId = methodLayer2 || method;
      }
      else patternId = this.isChangeMethod ? methodLayer2 || method : this.workFlowData?.pattern_id || methodLayer2 || method

      return patternId;
    },
    getMethodActiveByPatternId(category, scope, patternId) {
      if (category === 1 && scope === 1) {
        if (patternId === 1) {
          return 2;
        }
        if (patternId === 2) {
          return 4;
        }
        if (patternId === 3) {
          return 3;
        }
        return 1;
      } else if (scope === 3) {
        if (category === 4) {
          if (patternId === 5) {
            return 6;
          } else if (patternId === 6) {
            return 5;
          }
        }
        if (category === 6) {
          if (patternId === 2) {
            return 1;
          } else if (patternId === 3) {
            return 2;
          } else if (patternId === 1) {
            return 3;
          }
        }
        if (category === 7) {
          if (patternId === 2) {
            return 1;
          } else if (patternId === 3) {
            return 2;
          } else if (patternId === 1) {
            return 3;
          }
        }
        if (category === 9) {
          if (patternId === 2) {
            return 3;
          } else if (patternId === 3) {
            return 2;
          }
        }
        if (category === 15) {
          return this.settingPcafBySelectMonth ? patternId - 4 : patternId
        }
      }

      return patternId
    },
    handleContextPasted() {
      this.checkTableHasData()
      this.updateTotalEmissionsHandler(true)
    },
    handleUpdateAutoFill(rowData, binding, dbIdea) {
      const isLimitedAttributionFactor = rowData?.is_in_approx === false ? false : rowData?.is_in_approx || this.isLimitedAttributionFactor
      const dataProps = {
        dataTable: rowData,
        bindingLayout: this.itemsInView,
        isSaveTemplate: false,
        branchData: this.branchData,
        isGetBranchDetail: true,
        dbStore: this.getDbStore,
        isLimitedAttributionFactor: isLimitedAttributionFactor,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF,
        binding: binding,
        variables: {setAutoField: true}
      }
      if(binding === this.pattern?.valueSource) { //case auto fill db
        dataProps.itemAdded = dbIdea
      }
      this.pattern.handlerDataOnTable(dataProps)
      if (this.isCheckedError) this.onHandleShowError(true)
    },

    attachFilePopup(e, context) {
      this.evidences = {};
      this.selectedRow = context.row.index;

      this.descriptionScope.detailExternalProductCode = context.item?.detailExternalProductCode


      let evidenceData = context?.item?.evidence;
      if (typeof evidenceData === 'string') {
        evidenceData = JSON.parse(evidenceData)
      }
      this.onlyViewEvidence = false;

      const energy_type = this.getDbCustomizeOrDbMasterById[context?.item?.energy_type];
      
      this.pattern_id_evidence = evidenceData?.pattern_id;
      if( context?.item?.is_primary_data || (context?.item?.file_ocr && energy_type.ocr_type > 0) ) { // always onlyViewEvidence is used for primary data
        this.onlyViewEvidence = true;
      }
      if (this.isViewOnlyAttachFile) {
        if(evidenceData && evidenceData.file_name) {
          this.evidences.data = evidenceData;
          this.typeFormEvidenceStorage = 'view';
          this.evidences.value = this.dataTable.sourceCollection[this.selectedRow]
            ? formatNumberBySetting(this.dataTable.sourceCollection[this.selectedRow][this.pattern.bindingValueColumn])
            : null;
          this.onlyViewEvidence = true;
          this.openDialogEvidenceStorage = true;
        }
        return;
      }
      if(evidenceData && evidenceData.file_name) {
        this.evidences.data = evidenceData;
        this.typeFormEvidenceStorage = 'view';
      }
      else {
        this.typeFormEvidenceStorage = 'create';
      }
      this.evidences.value = this.dataTable.sourceCollection[this.selectedRow]
        ? formatNumberBySetting(this.dataTable.sourceCollection[this.selectedRow][this.pattern.bindingValueColumn])
        : null;
      this.openDialogEvidenceStorage = true;
    },
    handleAddAttachFile(dataEvidence) {
      let evidenceDataInCellTable = this.dataTable.items[this.selectedRow]?.evidence;
      this.dataTable.editItem(this.dataTable.items[this.selectedRow]);
      dataEvidence = {
        ...evidenceDataInCellTable,
        ...dataEvidence,
        created_at: evidenceDataInCellTable ? evidenceDataInCellTable.created_at : dataEvidence.created_at // TODO: always get the first created date of the record
      }
      const numericValue = dataEvidence?.value;
      const rowIndex = this.selectedRow;
      this.updateFlexgridCells(rowIndex, {
        [this.pattern.bindingValueColumn]: numericValue,
        attach_file: null
      });
      this.updateFlexgridCells(rowIndex, {
        [this.pattern.bindingValueColumn]: numericValue,
        attach_file: dataEvidence?.file_name
      });
      if(this.dataTable.items[rowIndex] && dataEvidence?.file_name) {
        this.dataTable.items[rowIndex].evidence = dataEvidence
        this.dataTable.items[rowIndex].attach_file = dataEvidence.file_name;
      }
      if(!isNaN(parseInt(numericValue))) {
        this.dataTable.items[rowIndex][this.pattern.bindingValueColumn] = this.formatNumberRealNum(formatNumberBySetting(numericValue));
      }
      else {
        this.dataTable.items[rowIndex][this.pattern.bindingValueColumn] = '';
      }

      const isLimitedAttributionFactor = this.dataTable.items[rowIndex]?.is_in_approx === false ? false : this.dataTable.items[rowIndex]?.is_in_approx || this.isLimitedAttributionFactor
      const dataProps = {
        dataTable: this.dataTable.items[rowIndex],
        bindingLayout: this.itemsInView,
        isSaveTemplate: false,
        branchData: this.branchData,
        isGetBranchDetail: true,
        dbStore: this.getDbStore,
        isLimitedAttributionFactor: isLimitedAttributionFactor,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF
      }
      const dataItem = this.pattern.handlerDataOnTable(dataProps)
      this.dataTable.items[rowIndex].emissions = dataItem.emissions
      this.updateTotalEmissionsHandler()

      this.checkTableChangeData();
      this.checkTableHasData();
      const isHasChangeEvidence = this.checkEvidenceChangeData(dataEvidence, evidenceDataInCellTable);
      if (isHasChangeEvidence) {
        const itemChange = this.dataTable.items[rowIndex]
        if(itemChange.id) {
          this.itemsChangeEvidence[itemChange.id] = { ...itemChange, rowIndex: rowIndex}
        }
        setTimeout(() => {
          this.isEditDataTable = true;
          this.isChangeEvidence = true;
        }, 100)
      }
      this.dataTable.commitEdit();

    },
    checkEvidenceChangeData(newEvidence, oldEvidence) {
      return !Object.keys(newEvidence).every(key => newEvidence?.key && oldEvidence?.key && newEvidence[key] === oldEvidence[key]);
    },
    handleDeleteAttachFile() {
      this.evidences = {};
      this.updateFlexgridCells(this.selectedRow, {
        attach_file: null,
      });
      this.dataTable.sourceCollection[this.selectedRow].evidence = null;
      this.dataTable.sourceCollection[this.selectedRow].attach_file = null;
      this.checkTableChangeData();
      this.checkTableHasData();
    },
    removeHeaderItem(header, bindingName) {
      const indexRemoved = header.findIndex(h => h.binding === bindingName);
      if (indexRemoved !== -1) {
        header.splice(indexRemoved, 1);
      }

      if(indexRemoved === -1) {
        header.forEach(headerItem => {
          if(headerItem.columns?.length > 0) {
            const subIndexRemoved = headerItem.columns.findIndex(sub => sub.binding === bindingName);
            if (subIndexRemoved !== -1) {
              headerItem.columns.splice(subIndexRemoved, 1);
            }
          }
        })
      }
    },
    onHandleShowError(value) {
      this.isCheckedFilterData = true;
      this.isCheckedError = value;
      this.actionUpdateIsLoading(true);
      if (value) {
        setTimeout(() => {
          this.actionAllowAddNew(false);
          const itemsInView = this.pattern.cellInputInView(this.itemsInView);
          this.dataTable.filter = (item) => {
            if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
              return false;
            }
            if (itemsInView.some(key => this.pattern?.getError(item, key, this.getDbStore, {itemsInView: this.itemsInView, branchData: this.branchData, isCheckMappingFacility: true, itemsInViewError: this.itemsInViewError}, this.isRemoveEmissionFactorOrValNumber) !== null)) { //true for check facility mapping
              return true;
            }
          }
          this.getDataRowOnCheckError();
          this.actionUpdateIsLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          this.dataTable.filter = null;
          this.getDataRowOnCheckError();
          this.actionAllowAddNew(true);
          this.actionUpdateIsLoading(false);
        }, 100);
      }
    },
    updateScoreSummaryTotalEmissionsHandler() {
      this.totalEmissions = 0;
      this.totalTreatment = {
        incinerator: 0,
        landfill: 0,
        recycling: 0,
        unknown: 0,
      };
      this.originalData?.forEach((item) => {
        const newEmissionCalc = item['raw_emissions'];
        if(!newEmissionCalc) return
        // const itemEmisstion = newEmissionCalc !== 'N/A' && newEmissionCalc !== null ? math.bignumber(formatValue(newEmissionCalc)) : '0';
        this.totalEmissions = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalEmissions)).toString()} + ${math.bignumber(formatValue(newEmissionCalc)?.substring(0, 50)).toString()}`), 50);
        // this.totalEmissions = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(+this.totalEmissions)).toString()} + ${itemEmisstion.toString()}`), 50);
      });
      this.$store.dispatch('registerData/updateTotalDurationEmission', this.totalEmissions);
    },
    addedDbExternalToMasterDB(sourceTemplate) {
      const typeSource = sourceTemplate.type;
      if (!this.dbExternalAdded[typeSource].find(db => db.id === sourceTemplate.id)) {
        this.dbExternalAdded[typeSource].push(sourceTemplate);
        if (typeSource === DB_TYPE.SERVICE_LINK) {
          this.masterDb.push(sourceTemplate)
        }
        else if (typeSource === DB_TYPE.IDEA) { // [master database, IDEA source ,[new item], service linked]
          this.masterDb.splice(this.masterDb.length - this.dbExternalAdded[DB_TYPE.SERVICE_LINK].length, 0, sourceTemplate)
        }
      }
    },
    serviceLinkPopup(context) {
      if (!this.listServicesLinked.length) {
        return;
      }
      this.selectedRow = context.row.index;
      const rowData = context?.row?.dataItem;
      const id = rowData?.wsu_source; // force to s3c1
      const dbService = dbServiceLinkObj[id]
      this.codeProductValue = dbService?.product_code || rowData?.detailExternalProductCode;
      this.onlyViewedServicesLinked = rowData?.detailExternalProductCode ? true : false;

      if (this.isViewOnlyServiceLink) {
        if(this.codeProductValue) {
          this.dataTable.sourceCollection[this.selectedRow].detailExternalProductCode = this.codeProductValue;
          this.serviceLinkDialog = true;
        }
        return;
      }
      this.serviceLinkDialog = true;
    },
    addServiceLinkSource(data, rowIndex, detailExternalProductCode, typeSource) {
      const sourcesSelection = {};
      for (const c of scopeCategorySourcesData) {
        if (
          (c.scope === undefined || c.scope === this.scope) &&
          (c.category === undefined || c.category === this.category) &&
          (c.method === undefined || c.method === this.method) &&
          (c.methodLayer2 === undefined || c.methodLayer2 === this.methodLayer2)
        ) {
          sourcesSelection['data'] = c.data;
          sourcesSelection['dbKey'] = c.dbKey;
        }
      }
     
      const dataAutoBinding = [];
      data.item.packages.forEach((item, i) => {
        if (item.package_value) {
          const newId = makeNewId(item.product_package_code, typeSource)
          const isIdDuplicate = this.masterDb.some(item => {
            return item.id_minor_processed === newId
          });
          const nameSourceCustome = item.source;
          if (!isIdDuplicate) {
            const sourceTemplate = {
              ...item,
              id: item.product_package_code,
              item_name: item.package_name,
              [sourcesSelection.dbKey]: nameSourceCustome,
              type: typeSource,
              source: nameSourceCustome,
              id_minor_processed: newId,
              product_code: data.item?.code,
              numberValue: item.package_value,
            }
            this.addedDbExternalToMasterDB(sourceTemplate);
          }
          dataAutoBinding.push({
            [sourcesSelection.data]: newId,
            wsu_unit: item.unit_source,
            wsu_source: nameSourceCustome,
            wsu_value: this.formatNumberRealNum(item.value_source),
            unit: item.unit,
            [this.pattern.bindingValueColumn]: item.package_value,
            product_package_code: item.product_package_code
          })
        }
      })
      this.flexgrid.columnGroups = this.getHeaderTable();
      this.flexgrid.columns.forEach(column => {
        const columnData = this.flexgrid.getColumn(column);
        if (columnData) {
          columnData.minWidth = getWidthByTextContent(columnData.header) + 10;
        }
      });
      dataAutoBinding.forEach((item, index) => {
        const row = rowIndex + index;
        const newId  = makeNewId(item.product_package_code, typeSource)
        this.updateFlexgridCells(row, item);
        this.updateFlexUnitCells(row, item);
        if(this.dataTable.sourceCollection[row]) {
          this.dataTable.sourceCollection[row].idDbExternal = newId
          const dbRelationKey = getDbRelationKeysByType(typeSource);
          this.dataTable.sourceCollection[row].db_relation = {
            [dbRelationKey]: item.product_package_code,
          }
          this.dataTable.sourceCollection[row].detailExternalProductCode = detailExternalProductCode || data.item?.code;
        }
        const isLimitedAttributionFactor = this.dataTable.sourceCollection[row]?.is_in_approx === false ? false : this.dataTable.sourceCollection[row]?.is_in_approx || this.isLimitedAttributionFactor
        const dataProps = {
          dataTable: this.dataTable.sourceCollection[row],
          bindingLayout: this.itemsInView,
          isSaveTemplate: false,
          branchData: this.branchData,
          isGetBranchDetail: true,
          dbStore: this.getDbStore,
          isLimitedAttributionFactor: isLimitedAttributionFactor,
          isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
          typeOfPattern: this.itemSelectPCAF
        }
        const dataItem = this.pattern.handlerDataOnTable(dataProps)
        this.dataTable.sourceCollection[row].emissions = dataItem.emissions
      })
      this.onlyViewedServicesLinked = true;
      this.updateTotalEmissionsHandler()
      this.checkTableChangeData();
      this.checkTableHasData();
    },
    handleUpdatePeriodData(periodData) {
      this.periodData = periodData
    },
    async handleGetGicsList() {
      try {
        this.gicsList = getListGicsByYear()
      } catch (error) {
        console.warn(error);
      }
    },
    getDataGicsObjectByYear(yearSelected = null) {
      const year = yearSelected || this.selectedYear
      const gicObjectByYear = {};
      // loop gics of year selected
      this.gicsList[year]?.forEach(item => {
        if(!gicObjectByYear?.[item?.key]) { 
          gicObjectByYear[item.key] = item;
        }
      })
      return gicObjectByYear
    },
    getFullGicObjectOfAllYear() {
      if(this.yearList.length <= 0) return

      const dataAllYear = {}
      this.yearList?.forEach(year => {
        const dataGicOfThisYear = this.getDataGicsObjectByYear(year.value)
        dataAllYear[year.value] = dataGicOfThisYear
      })
      return dataAllYear
    },
    updateEmissionFactorByGicsAndYear() {
      if(!this.dataTable) return

      const oldYear = this.getOldYearOfDataGics()
      const gicObjectByYear = this.getDataGicsObjectByYear();
      const gicObjectByOldYear = this.getDataGicsObjectByYear(oldYear);
      this.dataTable.items.forEach((item, index) => {
        if(item?.classify_gics) { //update emissions factor when item has gic
          this.dataTable.editItem(this.dataTable.items[index]);

          const emissionFactorByGicsValOfOldYear = this.formatNumberRealNum(gicObjectByOldYear[item.classify_gics].autoField);
          const emissionFactorByGicsValOfNewYear = this.formatNumberRealNum(gicObjectByYear[item.classify_gics].autoField);
          const oldEmissionFactor = this.dataTable.items[index].emissions_factor
          const isUpdateEmissionFactor = oldEmissionFactor === emissionFactorByGicsValOfOldYear
          const isTemplateStatus = this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES

          const isUpdateYearforDataTemplate = isTemplateStatus && this.selectedYear
          if(isUpdateEmissionFactor) {
            this.dataTable.items[index].emissions_factor = emissionFactorByGicsValOfNewYear
          }
          if(isUpdateYearforDataTemplate) {
            this.dataTable.items[index].yearTemplate = this.selectedYear
          }
        }
      })
      this.dataTable.commitEdit();
      this.updateTotalEmissionsHandler();
    },
    getOldYearOfDataGics() {
      const isTemplateStatus = this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES
      if(!isTemplateStatus) return this.oldYearSelected
      if(this.selectedYear && this.oldYearSelected) return this.oldYearSelected
      return this.originalData[0]?.year
    },
    submitBasicUnitItem(data) {
      const rowIndex = this.selectedRow
      const nameSource = data.source;
      const valueSource = data.value_source;
      const unitSource = data.unit_source;
      const unit = data.unit;
      const isAddIdeaItem = data.type_db === DB_TYPE.IDEA
      const sourcesSelection = {};
      let dataScope = scopeCategorySourcesData
      if(this.settingPcafBySelectMonth) {
        dataScope[dataScope.length - 1].dbKey = 'source'
      }

      for (const c of dataScope) {
        if (
          (c.scope === undefined || c.scope === this.scope) &&
          (c.category === undefined || c.category === this.category) &&
          (c.method === undefined || c.method === this.method) &&
          (c.methodLayer2 === undefined || c.methodLayer2 === this.methodLayer2)
        ) {
          sourcesSelection['data'] = c.data;
          sourcesSelection['dbKey'] = c.dbKey;
        }
      }
      const newId  = makeNewId(data.id, data.type_db)
      const isIdDuplicate = this.masterDb.some(item => {
        return item.id_minor_processed === newId || (item.id === data.id && item.type_db === data.type_db)
      });

      if (!isIdDuplicate) {
        const sourceTemplate = {
          ...data,
          type: data.type_db,
          id_minor_processed: newId,
          source: data.name_basic
        }
        if(isAddIdeaItem) {
          this.addedDbExternalToMasterDB(sourceTemplate);
        } else {
          this.handleAddDbMasterToList(sourceTemplate)
        }
      
        this.flexgrid.columnGroups = this.getHeaderTable();
      }

      const dataUpdateCell = {
        wsu_unit: unitSource,
        wsu_value: valueSource,
        wsu_source: nameSource,
        unit: unit,
        [sourcesSelection.data]: newId,
        emissions_factor: this.isBinddingEmissionFactor ? valueSource : null, //for s3c15 pcaf
      }
      if (rowIndex || rowIndex == 0) {
        this.selectedValuePulldown[rowIndex + 1] = {
          id: newId,
          source: data.name_basic
        }
      }
      
      this.updateFlexgridCells(rowIndex, dataUpdateCell);
      this.updateFlexUnitCells(rowIndex, dataUpdateCell);
      if (this.isCheckedError) this.onHandleShowError(true)
      if(this.dataTable.sourceCollection[rowIndex] && data.type_db === DB_TYPE.IDEA) {
        this.dataTable.sourceCollection[rowIndex].idDbExternal = newId
        const dbRelationKey = getDbRelationKeysByType(data.type_db);
        this.dataTable.sourceCollection[rowIndex].db_relation = {
          [dbRelationKey]: data.id,
        }
        // this.dataTable.sourceCollection[rowIndex].detailExternalSourceId = detailExternalSourceId
      } else {
        this.dataTable.sourceCollection[rowIndex].idDbExternal = null
        this.dataTable.sourceCollection[rowIndex].db_relation = {}
      }

      const isLimitedAttributionFactor = this.dataTable.sourceCollection[rowIndex]?.is_in_approx === false ? false : this.dataTable.sourceCollection[rowIndex]?.is_in_approx || this.isLimitedAttributionFactor
      
      const dataProps = {
        dataTable: this.dataTable.sourceCollection[rowIndex],
        bindingLayout: this.itemsInView,
        isSaveTemplate: false,
        branchData: this.branchData,
        isGetBranchDetail: true,
        dbStore: this.getDbStore,
        isLimitedAttributionFactor: isLimitedAttributionFactor,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF
      }
      const dataItem = this.pattern.handlerDataOnTable(dataProps)
      this.dataTable.sourceCollection[rowIndex].emissions = dataItem.emissions
      this.dataTable.sourceCollection[rowIndex].wsu_value = this.formatNumberRealNum(valueSource)

      this.updateTotalEmissionsHandler()
      this.checkTableChangeData();
      this.checkTableHasData();
    },
    handleAddDbMasterToList(dbItem, isUpdateHeader = false, isNoUpdateHeader = false){
      const typeSource = dbItem.type;
      const itemAdd = { ...dbItem, id: isUpdateHeader ? dbItem.origin_id : dbItem.id }
      const isIdDuplicate = this.masterDb.some(item => {
        return item.id_minor_processed === dbItem.id || (item.id === dbItem.id && typeSource === 0)
      });

      if(!isIdDuplicate) {
        if (!this.dbExternalAdded[typeSource].find(db => db.id === itemAdd.id)) {
          this.dbExternalAdded[typeSource].push(itemAdd);
        }

        this.masterDb.push(dbItem)
        if(isUpdateHeader && !isNoUpdateHeader) {
          this.flexgrid.columnGroups = this.getHeaderTable();
          this.flexgrid.refresh()
        }
      }
    },
    async getListBasicUnitDefault() {
      try {
        // get all basic unit db
        const response = await getListBasicUnit({ row_num_pattern: this.objPatternData.row_num_pattern });
        // this.basicUnitListDefault = response;
        this.basicUnitListDefault = []
        const basicUnitData = {
          source: {},
        }
        for (let index = 0; index < response.length; index++) {
          const item = response[index];
          if(!basicUnitData.source[item.name_basic]){
            basicUnitData.source[item.name_basic] = { 
              ...item,
              type: item.type_db,
              origin_id: item.id,
              source: this.category === 15 ? item.source : item?.name_basic
            }
          }
          this.basicUnitListDefault.push({
            ...item,
            expired_time: rangeDateFormat({
              year: item?.year_from,
              month: item?.month_from,
            }) + ' - ' + rangeDateFormat({
              year: item?.year_end,
              month: item?.month_end,
            })
          })
        }

        this.basicUnitData = basicUnitData
        this.uniqueOriginalAllDbUnit = prepareMasterOrCustomizeDB(Object.values(this.basicUnitData.source))
      } catch (err) {
        console.warn(err);
      }
    },
    async getListBasicUnitForPulldown(listDbUnitUsed = []) {
      // return
      try {
        const payload = {
          notIdea: 1,
          row_num_pattern: this.objPatternData.row_num_pattern,
          workflow_data_id: this.dataPattern?.workflow_data?.id || null
        }
        // add payload year,month in register or detail
        if(!this.isEmissionViewScreen) {
          payload.year = this.objPatternData.year;
          payload.month = this.objPatternData.month;
        }
        const responseData = await getListBasicUnit(payload);
        const fullListDbItem = responseData.concat(listDbUnitUsed)
        this.basicUnitFilterDataDefault = {};
        for (let i = 0; i < responseData?.length; i++) {
          this.basicUnitFilterDataDefault[fullListDbItem[i]['id']] = fullListDbItem[i]['name_basic'];
        }
        let masterDbCustom = fullListDbItem.map(item => {
          return {
            ...item,
            source: this.pattern?.isUseBasicUnit ? item.name_basic : item.source,
            item_name: this.pattern?.isUseBasicUnit ? item.name_basic : item.item_name,
          }
        })

        masterDbCustom = masterDbCustom.concat(this.dbMasterAddNew)
        this.masterDb = masterDbCustom
        this.dbExternalAdded =  {
          [DB_TYPE.IDEA]: [],
          [DB_TYPE.SERVICE_LINK]: [],
          [DB_TYPE.MASTER]: [],
          [DB_TYPE.CUSTOMIZE]: [],
        },
        this.dbMasterOriginal = [...masterDbCustom]
        this.uniqueOriginalDb = prepareMasterOrCustomizeDB(this.dbMasterOriginal)
        this.getCustomeObjDbMaster()
        this.getObjDbMaster()
      } catch (error) {
        console.warn(error);
      }
    },
    getDataBasicUnitSelectedInTable() {
      const basicUnitSelecteds = {}
      const keyBindingBasicUnit = this.pattern.valueSource
      this.dataTable.sourceCollection.forEach((item) => {
        const valueBasicUnit = item[keyBindingBasicUnit]
        if(!valueBasicUnit) return
      
        const valueSource = this.getDbCustomizeOrDbMasterById[valueBasicUnit]
        if(!basicUnitSelecteds[valueSource?.id]) {
          basicUnitSelecteds[valueSource.id] = {
            ...valueSource,
            id: valueSource.origin_id,
            name_basic: valueSource.type === 2 ? valueSource.source : valueSource?.name_basic
          }
        }
      })

      return basicUnitSelecteds
    },
    getNewBbStore() {
      return this.getDbStore
    },
    getHeaderTable(props) {
      const isTemplateStatus = this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES
      let branchList = JSON.parse(JSON.stringify(this.$store.state.registerData));
      const isRemoveStatusColumn = !this.dataPattern?.workflow_data?.id && !this.$route.fullPath.includes('/emissions/view/')
      const getDbStore = this.getDbStore

      const propsHeader = {
        registerData: branchList,
        listLayout: this.layer,
        branchData: this.branchData,
        attachFilePopup: this.attachFilePopup,
        serviceLinkPopup: this.serviceLinkPopup,
        dbStore: getDbStore,
        grid: this.flexgrid,
        isRemoveStatusColumn,
        businessGroupList: this.businessGroupList,
        readFileOcrDialog: this.readFileOcrDialog,
        listCategoryGlobal: this.listCategoryGlobal,
        isRemoveEmissionFactorOrValNumber: this.isRemoveEmissionFactorOrValNumber,
        typeOfPattern: this.itemSelectPCAF,
        emissionIntensityPopup: this.emissionIntensityPopup,
        uniqueOriginalDb: this.uniqueOriginalDb,
        basicUnitSearchPopup: this.basicUnitSearchPopup,
        basicUnitData: this.basicUnitData,
        externalSourcePopup: this.externalSourcePopup,
        uniqueOriginalAllDbUnit: props?.useAllDbItem ? this.uniqueOriginalAllDbUnit : [],
        selectedValuePulldown: this.selectedValuePulldown,
        // getNewBbStore: this.getNewBbStore
      }
      if(props?.useAllDbItem && this.settingPcafBySelectMonth) { // case PCAF => use only IDEA
        propsHeader.uniqueOriginalAllDbUnit = Object.values(dbIdeaObj)
      }
      const header = this.pattern.header(propsHeader);

      if (!this.existDbSource ) {
        this.removeHeaderItem(header, 'view_detail')
      }
      if (!this.listServicesLinked.length) {
        this.removeHeaderItem(header, 'service_detail')
      }
      if (isTemplateStatus) {
        this.removeHeaderItem(header, 'status')
      }
      if (!this.dataPattern?.workflow_data?.id && !this.$route.fullPath.includes('/emissions/view/')) {
        this.removeHeaderItem(header, 'status')
      }
      
      const setting_ocr_unit_price = this.currentUser.user.contractor?.setting_ocr_unit_price;
      if(!this.planType.is_ocr || !setting_ocr_unit_price) this.removeHeaderItem(header, 'read_file')
      if(isHideColumnEmissionPcaf(this.method, this.itemSelectPCAF)) {
        this.removeHeaderItem(header, 'emissions_factor');
        this.removeHeaderItem(header, 'view_detail');
        if(this.planType?.is_pcaf) { // only remove in pattern of plan pcaf, ghg no remove
          this.removeHeaderItem(header, 'ides_industry');
        }
      }

      if(isHideColValueNumber(this.method, this.itemSelectPCAF)) {
        this.removeHeaderItem(header, 'value_number_2')
      }

      const statusColumn = header[header.length - 1]
      if(statusColumn.binding === 'status') {
        const widthColumnStatus = getWidthByTextContent(statusColumn?.header) + 40 // 40 include tooltip icon
        statusColumn.minWidth = widthColumnStatus
        statusColumn.width = widthColumnStatus
      }
      return header;
    },
    getDataRowOnCheckError() {
      if (!this.isCheckedError) {
        this.rawDataOnCheckError = {};
        return;
      }
      this.rawDataOnCheckError = {};
      this.dataTable._view.forEach((item, index) => {
        this.rawDataOnCheckError[index] = item.clientRowId
      })
    },
    handleDataOnCheckError(dataProps = {}) {
      const { s, rowIndex, colIndex, colBinding, rightClick = false } = dataProps;
      const source = s.collectionView.sourceCollection;
      let cellData = '';
      if (!rightClick) cellData = s.getCellData(rowIndex, colIndex, false);
      let currentItem = source[rowIndex] || {};
      
      if (!this.isCheckedError) {
        return {
          clientRowId: rowIndex,
          cellData,
          currentItem
        }
      }
      
      const clientRowId = this.rawDataOnCheckError[rowIndex] - 1;
      if (!rightClick) cellData = source[clientRowId][colBinding];
      currentItem = source[clientRowId];
      
      return {
        clientRowId,
        cellData,
        currentItem
      }
    },
  },
  computed: {
    ...mapState('registerData', [
      'errorMess',
      'totalDurationEmissions',
      'duration',
      'durationValue',
      'listDuration',
      'isLoading',
      'isShowPopup',
      'errorFilterData',
      'month',
      'isUpdateData',
      'idCustomizes',
      'totalData',
      'isClearUndoStack',
      'watchDeleted',
      'allowAddNew',
      'isFullScreen',
      'yearSelected',
    ]),
    ...mapState('emission', ['durationId']),
    ...mapState('userData', ['contractor', 'currentUser', 'isSettingPcaf', 'planType', 'existDbSource', 'language', 'isShowNotiPcaf']),
    ...mapState('commonApp', ['statusAction', 'loadMore', 'isExpand']),
    ...mapState('approval', ['listApprovers']),
    ...mapState('actionsTable', ['startAction', 'idDelete']),
    ...mapState('newRegisterData', ['isApproved', 'methodLayer1Id', 'methodLayer2Id', 'patternId']),
    ...mapGetters('newRegisterData', ['isLoadingSubmitting', 'apiResponseObject']),
    ...mapGetters('settingApp', ['getDigitsBySetting']),
	displayAsTemplateDetails() {
		return this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES;
	},
  displayAsOwnerTemplate() {
    return !(this.dataPattern?.workflow_data?.template_type && this.dataPattern?.workflow_data?.is_shared);
  },
	theTemplateIsShared() {
		return this.dataPattern?.workflow_data?.template_type === 1;
	},
	checkHasUserAccessTemplateDetail() {
		return this.listUsersAccessTemplateData.length > 0;
	},
    isApproval() {
      if (this.$route.path?.includes('emissions/view')) return true
      const listRouteGetData = [
        'emissions/register/approval',
        'emissions/register/primary-data',
        'emissions/register/list-menu',
      ]
      const isApprovalOrMenu = listRouteGetData.some(path => this.$route.path?.includes(path))
      const isStatusApproved = [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.workFlowData?.status)
      if (isStatusApproved && isApprovalOrMenu) return true
      return false
    },
    workFlowStatus(){
      return this.workFlowData?.status;
    },
    breadcrumbOption(){
      const dashBoard = {
        text: this.$t("register_data.hyperlink_home"),
        disabled: false,
        href: ROUTES.DASHBOARD,
      }
      const emission = {
        text: this.$t("register_data.hyperlink_exhaust_volume_registration"),
        disabled: false,
        href: ROUTES.EMISSIONS,
      }
      const scope = {
        text: `${this.$t('dashboard_main.label_scope')} ${this.scope}`,
        disabled: true,
        href: ROUTES.EMISSIONS,
      }
      const position = {
        text: this.getPosition,
          disabled: true,
        href: ROUTES.EMISSIONS,
      }
      const title={
        text: this.getTitle,
        disabled: true,
        href: ROUTES.EMISSIONS,
      }
      if(this.isEmissionViewScreen) {
        emission.text = this.$t("emissions_detail.hyperlink_list_of_emissions");
        return [dashBoard,  emission]
      }
      switch (this.$route.params?.scope) {
        case ROUTES.LIST_EMISSION_SUBMITTED:
          emission.text= this.$t("register_data.hyperlink_type_list_submitted");
          emission.href= `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_SUBMITTED}`;
          break;
        case ROUTES.LIST_EMISSION_TEMPLATES:
          emission.text= this.$t("register_data.hyperlink_type_list_template");
          emission.href= `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_TEMPLATES}`;
          break;
        case ROUTES.LIST_APPROVAL:
          emission.href= ROUTES.APPROVAL;
          switch (this.workFlowStatus) {
            case STATUS_FIELD.STATUS_APPROVED:
            case STATUS_FIELD.STATUS_REPRENSENT_APPROVED:
              emission.text= this.$t("register_data.hyperlink_type_list_approval");
              break;
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED:
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER:
              emission.text= this.$t("register_data.hyperlink_type_list_approval");
              break;
            default:
              emission.text= this.$t("register_data.hyperlink_type_list_approval");
              break;
          }
          break;
        case ROUTES.LIST_MENU_EMISSION:
          emission.href= ROUTES.EMISSIONS;
          switch (this.workFlowStatus) {
            case STATUS_FIELD.STATUS_RETURN:
            case STATUS_FIELD.STATUS_REPRENSENT_RETURN:
              emission.text= this.$t("register_data.hyperlink_type_new");
              break;
            case STATUS_FIELD.STATUS_SUBMIT:
              emission.text= this.$t("register_data.hyperlink_type_new");
              break;
            case STATUS_FIELD.STATUS_APPROVED:
              emission.text= this.$t("register_data.hyperlink_type_new");
              break;
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED:
            case STATUS_FIELD.STATUS_REPRENSENT_APPROVED:
              emission.text= this.$t("register_data.hyperlink_type_new");
              break;
            case STATUS_FIELD.STATUS_CANCEL_SUBMMITED:
              emission.text= this.$t("register_data.hyperlink_type_list_submitted");
              break;
            default:
              emission.text= this.$t("register_data.hyperlink_type_new");
              break;
          }
          break;
        case ROUTES.PRIMARY_DATA_MERGE_DETAIL:
          emission.text= this.$t("primary_data_merge.title_primary_data_merge_screen");
          emission.href= ROUTES.PRIMARY_DATA_MERGE;
          break;
        default:
          break;
      }
      if(this.isRegisterNew){
        emission.text= this.$t("register_data.hyperlink_type_new");
      }
      if(this.isEmissionViewScreen){
        emission.text= this.$t("register_data.hyperlink_type_list_view_approved");
        emission.href= ROUTES.LIST_EMISSIONS;
      }
      return this.scope === 4
        ?
        [dashBoard, emission, position, title]
        :
        [dashBoard, emission, scope, position, title]
    },
    month: {
      set(month) {
        this.$store.state.registerData.month = month;
      },
      get() {
        return this.$store.state.registerData.month;
      },
    },
    isHasDataRegister() {
      // Handle condition to display dot
      return this.$route.fullPath.includes('/emissions/view/');
    },
    displayGetMethodName() {
      return !this.dataPattern?.workflow_data?.id || this.isGetAllData || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT;
    },
    getMonthWhenSameYear() {
      let listMonth = [];
      for (let index = this.startMonth; index <= this.endMonth; index++) {
        listMonth.push(index);
      }
      return listMonth;
    },
    getMethodName() {
      if (!this.method) {
        return this.$t("register_data.button_please_select");
      }
      if(this.category === 15 && (this.registerWorkflow?.id || this.isEmissionViewScreen)) {
        let patternId = this.registerWorkflow.pattern_id;

        // if has changed to get new pattern id
        if(this.isChangeMethod || this.isEmissionViewScreen) {
          patternId = this.planType?.is_pcaf ? this.method + 4 : this.method;
        }
        let methodName = ''
        switch (patternId) {
          case 1:
            methodName = this.$t("register_data.radio_stock")
            break;
          case 2:
            methodName = this.$t("register_data.radio_debt_bond_loan")
            break;
          case 3:
            methodName = this.$t("register_data.radio_project_finance")
            break;
          case 4:
            methodName = this.$t("register_data.radio_asset_management_customer_service")
            break;
          case 5:
            methodName = this.$t("register_data.popup_method_pcaf1")
            break;
          case 6:
            methodName = this.$t("register_data.popup_method_pcaf2")
            break;
          case 7:
            methodName = this.$t("register_data.popup_method_pcaf3")
            break;
          case 8:
            methodName = this.$t("register_data.popup_method_pcaf4")
            break;
          case 9:
            methodName = this.$t("register_data.popup_method_pcaf5")
            break;
          case 10:
            methodName = this.$t("register_data.popup_method_pcaf6")
            break;
          case 11:
            methodName = this.$t("register_data.popup_method_pcaf7")
            break;
          default:
            break;
        }
        return methodName
      }

      let index = '';
      if (this.methodLayer2 !== null) {

        if (this.scope === 3 && this.category === 9 && this.currentSelected === 2) {
          if (this.currentSelectedLayer2 === 4) {
            index = 0;
          } else if (this.currentSelectedLayer2 === 5) {
            index = 1;
          } else {
            return this.$t("register_data.button_please_select");
          }
        } else {
          index = this.methodListLayer2.findIndex((method) => method.value === this.methodLayer2);
        }
        return this.methodListLayer2[index]?.text || this.$t("register_data.button_please_select");
      }
      index = this.methodList.findIndex((method) => method.value === this.method);

      if(this.settingPcafBySelectMonth && this.category === 15 && this.registerWorkflow?.id) {
        index = this.methodList.findIndex((method) => method.value === this.method - 4);
      }

      return this.methodList[index]?.text || this.$t("register_data.button_please_select");
    },
    getTitle() {
      if (this.scope > 2) {
        if (this.category === 16 || this.scope === 4) {
          return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
        } else {
          return `${this.category}. ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
        }
      } else {
        return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
      }
    },
    getPosition() {
      return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['position'];
    },
    getLastTimeUpdate() {
      return this.dateTimeFormat(this.latestRecord?.updated_at_latest);
    },
    getErrorFilterData() {
      if (this.errorFilterData !== '') {
        this.isErrorFilterData = true;
        return this.errorFilterData;
      }
      return '';
    },
    isReadOnlyDataIsApproved() {
      return (
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED ||
        this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_APPROVED
      );
    },
    isEditData() {
      return this.dataTable?.itemsEdited.length || (this.$route.query?.import && this.listDataPattern.length > 0) || this.isChangeEvidence || this.isChangeStatus || this.isEditTitleApprovedData ? 1 : 0;
    },
    isHasLatestRecord() {
      return this.latestRecord?.updated_at_latest;
    },
    isReadOnlyDataTable() {
      return Boolean((this.currentUser?.user?.role_id !== ROLE.ADMIN && (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_APPROVED)) ||  this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED
      || (this.currentUser?.user?.role_id !== ROLE.ADMIN && this.$route.fullPath.includes('/emissions/view/'))
      || (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_SUBMIT)
      || this.isPrimaryDisableOnReturnStatus)
    },
    isSummaryScoreScreen() {
      return this.$route.fullPath.includes(ROUTES.SCORE_SUMMARY);
    },
    isPrimaryDisableOnReturnStatus() {
      return (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN) && this.$route.fullPath.includes('/emissions/register/primary-data')
    },

    isReadOnlyTitle() {
      return Boolean(([
          STATUS_FIELD.STATUS_SUBMIT,
          STATUS_FIELD.STATUS_CANCEL_SUBMMITED
        ].includes(this.dataPattern?.workflow_data?.status) || this.isPrimaryDisableOnReturnStatus) || (this.dataPattern?.workflow_data?.status && [ROLE.APPROVAL, ROLE.USER].includes(this.currentUser?.user?.role_id)));
    },
    isResizeCustome() {
      return ['NewRegisterData', 'RegisterData'].includes(this.$route.name)
    },
    isDisableTemplate() {
      const workflowData = this.dataPattern?.workflow_data
      return STATUS_FIELD.STATUS_TEMPLATE === workflowData?.status &&
        this.category === 15 &&
        ((this.isSettingPcaf && workflowData?.pattern_id < 5) || (!this.isSettingPcaf && workflowData?.pattern_id >= 5))
    },
    isDisableBySetting() {
      const workflowData = this.dataPattern?.workflow_data
      return (STATUS_FIELD.STATUS_TEMPLATE === workflowData?.status && !this.dataPattern.disabledByPermission) && !this.isChangeStatus && this.category === 15
    },
    selectedMonthAndYear() {
      return this.selectedMonth + this.selectedYear
    },
    selectDataTypePAcf() {
      return this.itemSelectPCAF.categoryIdSelect + this.itemSelectPCAF.dataTypeIdSelect;
    },
    getYearSettingPcaf() {
      return this.durationOtherSetting.filter(item => item.isSettingPcaf)?.map(item => item.year)
    },
    getYearSettingGhg() {
      return this.durationOtherSetting.filter(item => !item.isSettingPcaf)?.map(item => item.year)
    },
    getSelectLabel() {
      return this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES ? '' : this.$t("emissions_detail.placeholder_please_select");
    },
    isHideUndoRedo() {
      return [
          STATUS_FIELD.STATUS_SUBMIT,
          STATUS_FIELD.STATUS_CANCEL_SUBMMITED,
          STATUS_FIELD.STATUS_SUBMIT_NON_USER,
          STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER
        ].includes(this.dataPattern?.workflow_data?.status);
    },
    isDisableButtonStatusTemplate() {
      if(this.category !== 15) return false
      return this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES && (!this.selectedYear || !this.selectedMonth)
    },
    dbCustomizesOrDbMasters() {
      const listDbAddNew = getListDbAddNew(this.dbExternalAdded)
      const dbList = [ ...this.uniqueOriginalDb, ...listDbAddNew ]
      return dbList
    },
    getDbStore() {
      const isSelectedPcaf = this.category === 15 && this.planType?.is_pcaf
      const isTemplateStatus = this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES
      this.getCustomeObjDbMaster()
      this.getObjDbMaster()
      
      const dataDbMaster = {
        dbCustomizes: this.masterDb,
        masterDbOriginal: this.dbMasterOriginal,
        getDbCustomizeByItemName: this.getDbCustomizeByItemName,
        getDbCustomizeOrDbMasterByItemName: this.getDbCustomizeOrDbMasterByItemName,
        getDbCustomizeByIdSource: this.getDbCustomizeByIdSource,
        getDbCustomizeOrDbMasterById: this.getDbCustomizeOrDbMasterById,
        dbCustomizesOrDbMasters: this.dbCustomizesOrDbMasters,
        getDbCustomizeOrDbMasterByWsuSource: this.getDbCustomizeOrDbMasterByWsuSource,
        getDbCustomizeOrDbMasterByNameSource: this.getDbCustomizeOrDbMasterByNameSource,
        getGroupCompanyObj: this.groupObj,
        hdrTips: this.hdrTips,
        summaryScoreType: this.summaryScoreType,
        dbExternalAdded: this.dbExternalAdded,
        typeOfPattern: this.itemSelectPCAF,
        isFlagUpdateDataListEmission: this.isFlagUpdateDataListEmission,
        startMonth: this.startMonth,
      }

      if(this.isSettingPcaf && isSelectedPcaf) {
        dataDbMaster.fullGicsList = this.gicsList
      }

      if(this.settingPcafBySelectMonth && isSelectedPcaf) {
        dataDbMaster.selectedYear = this.selectedYear
        dataDbMaster.gicsList = this.gicsList[this.selectedYear] || []
        dataDbMaster.getFullGicObjectOfAllYear = this.getFullGicObjectOfAllYear()
      }

      if((isTemplateStatus || this.isEmissionViewScreen) && isSelectedPcaf) {
        const year = this.originalData[0]?.year
        dataDbMaster.gicsList = this.gicsList[2023] || [] // fake list gics in template
        dataDbMaster.selectedYear = year
      }
      return dataDbMaster
    },
    getObjOrigination() {
      let data = {}
      this.branchData.organizational_division.forEach(item => {
        if (!data[item.value]) {
          data[item.value] = {...item}
        }
      })
      return data
    },
    getWsutypeObj() {
      const types = {
        '産業廃棄物': '1',
        '一般廃棄物': '2',
        '不明': '3',
      }
      return types
    },
    descriptionScope() {
      this.getPatternIdCurrent();
      let scope = this.registerWorkflow.scope
      if (this.isSearchEmissionView) {
        scope = this.scope;
      }
      return {
        scope: scope,
        category: this.category,
        year: this.selectedYear,
        month: this.selectedMonth,
        pattern_id: this.setPatternId(this.category, this.scope, this.method, this.methodLayer2),
        onlyView: this.onlyViewEvidence,
        method: this.getMethodName === this.$t("register_data.button_please_select") ? null : this.getMethodName
      }
    },
    isViewOnlyAttachFile() {
      return (
        (this.dataPattern?.workflow_data?.status && this.isReadOnlyDataTable) ||
        (this.currentUser?.user?.role_id !== ROLE.ADMIN && this.$route.fullPath.includes('/emissions/view/')) ||
        (this.currentUser?.user?.role_id === ROLE.ADMIN && (this.$route.fullPath.includes('/emissions/register/primary-data/')))
      );
    },
    isDisableByStatus(){
      return (this.dataPattern?.workflow_data?.id && this.$route.fullPath.includes('/emissions/view/')) || this.isApproval
    },
    isShowSummaryScore() {
      if(this.isEmissionViewScreen) {
        return this.$route.fullPath.includes('/emissions/view/score-summary') && this.scope === 3 && this.category === 15 && this.planType?.is_pcaf
      }
      return this.$route.fullPath.includes('/emissions/view/score-summary') && this.settingPcafBySelectMonth;
    },
    summaryScoreItems() {
      return [
        {
          id: 1,
          value: this.$t('setting_pcaf.summary_score_item_asset_class'),
        },
        {
          id: 0,
          value: this.$t('setting_pcaf.summary_score_item_sector')
        }
      ]
    },
    isShowNotePcaf() {
      if(this.isEmissionViewScreen) {
        return this.scope === 3 && this.category === 15 && this.planType?.is_pcaf
      }
      return this.settingPcafBySelectMonth || (this.workFlowData?.pattern_id > 4 && this.category === 15)
    },
    isViewOnlyServiceLink() {
      let isOtherStatusView = true;
      
      // 2 status can edit service link
      if (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_DRAFT || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN) {
        isOtherStatusView = false;
      }
      return (this.dataPattern?.workflow_data?.status && this.isReadOnlyDataTable) || 
      this.$route.fullPath.includes('/emissions/view/') || 
      this.$route.fullPath.includes('/approval') || 
      this.dataPattern?.workflow_data?.status && isOtherStatusView
    },
    isRemoveEmissionFactorOrValNumber() {
      return isHideColumnEmissionPcaf(this.method, this.itemSelectPCAF) || isHideColValueNumber(this.method, this.itemSelectPCAF)
    },
    isShowCategorySelectPcaf() {
      return [1,2].includes(this.method);
    },
    isEmissionViewScreen() {
      return this.$route.path.includes('/emissions/view') && !this.$route.path.includes('/emissions/view/score-summary');
    },
    isDateInvalid() {
      return !(this.yearSelected && this.month && this.yearSelected !== 'Invalid date' && this.month !== 'Invalid date');
    },
    selectedFiscalYear() {
      return this.selectedMonth < this.startMonth ? this.selectedYear - 1 : this.selectedYear
    },
    objPatternData() {
      return {
        month: this.selectedMonth,
        year: this.selectedYear,
        scope: this.scope,
        category: this.category,
        method: this.method,
        methodLayer2: this.methodLayer2,
        row_num_pattern: findRowNumPattern(scopeCategoriesData, this.scope, this.category, this.method, this.methodLayer2),
      }
    }
  },
  watch: {
    method: {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (
            (this.scope === 3 && this.category === 11 && oldVal !== 1 && newVal === 1) ||
            (this.scope === 3 && this.category === 9) ||
            // (this.scope === 3 && this.category === 15 && this.settingPcafBySelectMonth) ||
            (this.scope === 3 && this.category === 4 && newVal !== 3)
          ) {
          } else {
            if (
              (this.scope === 3 && this.category === 11 && oldVal === 1 && newVal !== 1) ||
              (this.scope === 3 && this.category === 4 && newVal === 3)
            ) {
              this.methodLayer2 = null;
            }
            // re-render table
            if(this.isSettingPcaf) {
              await this.getCategoryPCAF();
              this.getDataCategoryPCAF()
            }
            await this.handleGetGicsList()

            if (this.isUpdated) {
              try {
                if(this.isSettingPcaf) {
                  const getFirstItemId = (items) => {
                    return items.length ? items[0].id : null;
                  }
                  this.itemSelectPCAF.categoryIdSelect = getFirstItemId(this.itemSelectPCAF.itemsCategoryType);
                  this.itemSelectPCAF.dataTypeIdSelect = getFirstItemId(this.itemSelectPCAF.itemsDataType);
                  if(this.itemSelectPCAF.categoryIdSelect === this.itemSelectPCAF.categoryIdSelectOriginal && 
                   this.itemSelectPCAF.dataTypeIdSelect === this.itemSelectPCAF.dataTypeIdSelectOriginal) {
                    this.isChangeSelectTypePCAF = false;
                  } else this.isChangeSelectTypePCAF = true;
                }
                if (this.isGetAllData) {
                  await this.getUrlFromScopeCategory();
                }
                // this.getListDurations(this.selectDurationChange);
                this.getHeaderFromScope();
                this.getDataFromScope();
                this.key++;
                 //handle change method
             
              } catch (error) {
                this.getDataFromScope();
              }
              // this.getListDurations(this.selectDurationChange);
              // this.getHeaderFromScope();
              // this.getDataFromScope();
              // this.key++;
            }
          }
        }
        if(this.currentSelected !== newVal) {
          this.currentSelected = newVal;
          this.getMethodListLayer2();
        }
      },
    },
    route: {
      handler() {
        this.scope = parseInt(this.$route.params?.scope) || 1;
        this.category = parseInt(this.$route.params?.category) || 1;
        this.updateDataScope(this.scope);
        this.updateDataCategory(this.category);
      },
    },
    $route: {
      handler() {
        if (this.isEmissionViewScreen) {
          const dashBoard = {
            text: this.$t("register_data.hyperlink_home"),
            disabled: false,
            href: ROUTES.DASHBOARD,
          }
          const emission = {
            text: this.$t("emissions_detail.hyperlink_list_of_emissions"),
            disabled: false,
            href: ROUTES.EMISSIONS,
          }
          this.updateBreadCrumb([dashBoard, emission]);
        }
      }
    },
    methodLayer2: {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // re-render table
          if (this.isUpdated && newVal !== null) {
            try {
              if (this.isGetAllData) {
                await this.getUrlFromScopeCategory();
              }
              // this.getListDurations(this.selectDurationChange);
              this.getHeaderFromScope();
              this.getDataFromScope();
              this.key++;
            } catch (error) {
              this.getDataFromScope();
            }
            // this.getListDurations(this.selectDurationChange);
            // this.getHeaderFromScope();
            // this.getDataFromScope();
            // this.key++;
          }
        }
      },
    },
    selectedYear(_, oldVal) {
      this.oldYearSelected = oldVal
    },
    selectedMonthAndYear: {
      async handler() {
        if(this.$route.path.includes('/emissions/view/score-summary')) {
          await this.getUrlFromScopeCategory()
          this.getHeaderFromScope();
          this.getDataFromScope();
          this.updateScoreSummaryTotalEmissionsHandler();
          this.key++;
        }

        if (this.isEmissionViewScreen) return; // no need to handle selected month year at this screen
        if(this.isSettingPcaf) {
          if(this.pattern) this.pattern.setFilterIndex({})
          // this.refreshTable();
          this.updateEmissionFactorByGicsAndYear()
        }
        if (this.isShowSummaryScore) {
          this.monthList = getMonthListCustome(this.selectedYear, this.startMonth).map(item => {
            item.isShowIcon = this.summaryScoreIsRegistered[this.selectedYear]?.indexOf(item.value) >= 0 ? true : false;
            return item
          });

          this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.selectedYear).map(item => {
            item.isShowIcon = this.summaryScoreIsRegistered.hasOwnProperty(item.value) ? true : false;
            return item
          });
          return
        } else {
          this.monthList = getMonthListCustome(this.selectedYear, this.startMonth).map(item => {
            item.isShowIcon = this.durationRegistered[this.yearSelected]?.indexOf(item.value) >= 0 ? true : false;
            return item
          });

          this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.selectedYear).map(item => {
            item.isShowIcon = this.durationRegistered.hasOwnProperty(item.value) ? true : false;
            return item
          });
        }

        if(this.pattern?.isUseBasicUnit && this.selectedMonth && this.selectedYear){ // update basic unit data & rerender table
          this.actionUpdateIsLoadingTable(true)
          const basicUnitUsed = this.getDataBasicUnitSelectedInTable()
          const listDbUnitUsed = Object.values(basicUnitUsed)
          await this.getListBasicUnitForPulldown(listDbUnitUsed)
          this.handleUpdateHeader()
          this.actionUpdateIsLoadingTable(false)
        }
        this.updateMonthYearSelected();
        this.handleCheckInputDuration()
      },
    },
    apiResponseObject(response) {
      if (response.message) {
        this.dialogNotification = true;
        this.submitDataDialogPopup = false;
        this.messageNotification = response.message;
      }
    },
    selectDurationChange: {
      async handler(value, oldVal) {
        this.updateDuration(value);
        this.isReadOnly = !this.checkSelectedDuration();
        const selectedDuration = this.durationList.find((duration) => duration.id === value);
        if(oldVal !== null) {
          let settingGhgByDuration = this.durationOtherSetting.find(duration => duration.duration_id === selectedDuration.id)?.isGhg

          if(!settingGhgByDuration && settingGhgByDuration !== false) {
            settingGhgByDuration = true
          }
          // this.updateMethodSetting(!settingGhgByDuration)

          // if(this.category === 15) {
          //   this.setDefaultMethodBySetting(!settingGhgByDuration)
          // }
        }

        this.getMethodList(this.scope, this.category);
        this.keySetting ++
        this.getMonthSelectionList(
          selectedDuration.start_at,
          selectedDuration.end_at,
          selectedDuration.duration_registered,
        );
        if (this.selectMonthYearChange === new Date(selectedDuration.start_at).getMonth() + 1) {
          try {
            await this.getUrlFromScopeCategory();
            // this.getListDurations(value);
            this.getDataFromScope();
          } catch (error) {
            this.getDataFromScope();
          }
        } else if (this.isGetAllData) {
          if (this.$route.query.month) {
            this.selectMonthYearChange = parseInt(this.$route.query.month)
            const newQuery = this.$route.query;
            delete newQuery.month
            this.$router.replace({ path: this.$route.path , query: { ...newQuery } })
          } else {
            this.selectMonthYearChange = new Date(selectedDuration.start_at).getMonth() + 1;
          }
        }
      },
    },
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
      this.updateTotalEmissionsHandler()
    },
    canUndo(newValue) {
      this.actionUpdateStatusBtn({ undo: newValue, redo: this.canRedo });
    },
    canRedo(newValue) {
      this.actionUpdateStatusBtn({ undo: this.canUndo, redo: newValue });
    },
    isEditDataTable() {
      this.updateIsEditData(this.isEditDataTable)
    },
    summaryScoreType(v) {
      this.onUpdateGotoScoreSummary();
    },
    exportDialog(isShowDialog) {
      if(isShowDialog) {
        document.body.classList.add('hidden-scrollbar');
      } else {
        document.body.classList.remove('hidden-scrollbar');
      }
    }
  },
	sockets: {
		accessedDetailTemplate(socketData) {
			if (
				socketData.template === this.dataPattern?.workflow_data?.id &&
				this.theTemplateIsShared &&
				this.$socket.id !== socketData.socketId
			) {
				if (this.listUsersAccessTemplateData) {
					this.listUsersAccessTemplateData = [socketData.socketId];
				} else {
					if (!this.listUsersAccessTemplateData.includes(socketData.socketId)) {
						this.listUsersAccessTemplateData.push(socketData.socketId);
					}
				}
			}
		},
		leavingTemplateDetail(socketData) {
			if (socketData.template === this.dataPattern?.workflow_data?.id && this.theTemplateIsShared) {
				this.listUsersAccessTemplateData = this.listUsersAccessTemplateData.filter((user) => {
					return user !== socketData.socketId;
				});
			}
		},
	},
  beforeDestroy() {
    this.updateStartMonth(this.startMonth);
    this.updateIsEditData(false);
    this.method = null;
    if(this.pattern) {
      this.pattern.setFilterIndex({});
    }
    webStorage.set('startMonth', this.startMonth);
	if (this.theTemplateIsShared) {
		this.$socket.emit('leavingTemplateDetail', this.workFlowData?.id);
	}

	clearInterval(this.socketAccessInterval);
  },
};
</script>

<style lang="scss">
.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.title-block {
  margin-top: 40px;
  .title-block--detail {
    &.has-opacity {
      opacity: 0;
    }
  }
}

.total-treatment-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 12px;

  .treatment-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .treatment-label {
      min-width: 1912x;
    }

    .treatment-value {
      color: $goldMid;
      line-height: 24px;
      text-align: left;
      margin-left: 10px;

      span {
        margin-left: 8px;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
}

.data-is-registered::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #bfd4d9;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.duration-text {
  border: 1px solid rgba(42, 42, 48, 0.1);
  border-radius: 4px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  color: $monoBlack;
  padding: 8px 15px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 72px;
    display: inline-block;
    font-style: normal;
    letter-spacing: 0.42px;
    &.month-text {
      width: 56px;
    }
  }
}

.durations-pulldown {
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.durations-label {
  font-family: $mainFont;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.03em;
  color: $monoBlack;
}

.update-block {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .item {
    height: 42px;

    .item-icon {
      margin-right: 12px;
    }

    .item-detail {

      .item-title {
        font-family: $mainFont;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        height: 18px;

        letter-spacing: 0.03em;
        color: $monoBlack;
      }

      .item-content {
        font-family: $mainFont;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 0;

        letter-spacing: 0.05em;
        color: $monoBlack;
      }
    }
  }
}
.action-table-template {
	margin-left: 8px;
	display: flex;
	gap: 10px;
	align-items: center;

	.v-btn {
		height: 28px;
		min-width: 50px;
		box-shadow: unset;
	}

	.btn-action {
		border-radius: 4px;
		border: 1px solid var(--Devider-Black, rgba(42, 42, 48, 0.10));
		background: $monoOffWhite;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		padding: 4px 8px 6px 8px;
		overflow: hidden;
		color: $monoBlack;
		text-align: center;
		text-overflow: ellipsis;
		font-size: 11px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px;
		letter-spacing: 0.33px;

		&:hover {
			&::before {
				opacity: 1;
				background-color: #A4A184;
				border: 1px solid rgba(42, 42, 48, 0.10);
				box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48),
					0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31),
					0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24),
					0px 4px 14px 0px rgba(160, 181, 186, 0.17);
			}

			color: $monoWhite;
		}
	}

	.btn-apply {
		color: $monoWhite;
		background: $goldMid;
	}

}

.total-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $bgLight;
  border-radius: 4px;

  .emissions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .total-label {
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      color: $monoBlack;
    }

    .total-emission {
      font-family: 'Century Gothic Pro';
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      color: $goldMid;
      width: 100%;
      text-align: right;
      word-break: break-all;

      span {
        font-size: 13px;
        line-height: 28px;
        min-width: 40px;
        display: inline-block;
      }
    }
  }

  .method {
    width: 100%;

    .v-btn {
      width: 100%;
    }
  }
}

.method-label {
  color: $monoBlack;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.selections {
  gap: 40px;
}

.select-container{
  display: flex;
  flex-direction: column;
}

.bg-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: $bgCusLight;
  margin: 48px 0;
  padding: 16px 20px 20px;
  gap: 20px;
}
.left-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .select-block{
      display: flex;
      justify-content: space-between;
      gap: 8px;
      .durations-pulldown {
        width: 125px;
        .durations-select-item{
          width: 100%;
        }
        &.month {
          width: 110px;
        }
      }
    }
  }
}

.section-note-pcaf {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 3%;
  color: rgba(228, 91, 88, 1);
  margin: 8px 0 8px 34px;
}

@media (max-width: 1023px) {
  .left-container {

    .left{
      gap: 8px;
      width: 100%;

      .select-block{
        width: 100%;
        gap: 8px;
        .durations-pulldown {
          width: 50%;
          .durations-select-item{
            width: 100%;
          }
          &.month {
            width: 50%;
            .durations-select-item{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.method-container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
@include desktop {
  .total-treatment-list {
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin-left: 40px;

    .treatment-item {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      font-family: $subFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.03em;
      word-break: break-all;
      color: $monoBlack;
      align-items: flex-start;

      .treatment-value {
        color: $goldMid;
        line-height: 24px;
        text-align: left;
        margin-left: 10px;
      }
    }
  }
  .title-block {
    display: flex;
    // margin-top: 48px;
    .title-block--detail {
      width: 50%;
      padding-right: 0 !important;
      flex: 1 0;
      &.has-opacity {
        opacity: 0;
      }
    }
  }
  .register-page {
    .container {
      padding: 0 40px;
    }
  }
  .update-block {
    display: flex;
    justify-content: flex-end;
    width: max-content;
    margin-top: 0;
    flex-direction: row;
    .item {
      &:last-child {
        margin-top: 0;
      }
    }
  }
  .selections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .durations-pulldown {
      display: flex;
      flex-direction: row;
      width: 100%;

      .durations-select-item {
        max-width: 275px;
        &.new-register {
          max-width: 275px;
        }
      }
    }

    .total-result {
      flex-direction: row;

      .emissions {
        .total-emission {
          text-align: left;
          // margin-left: 8px;
          min-height: 38px;
          max-width: 250px;
          word-break: break-all;
          line-height: 40px;
        }

        span {
          margin-left: 8px;
        }
      }

      .method {
        max-width: 200px;

        .common-btn {
          max-width: 200px;

          &:disabled {
            background: transparent !important;
            box-shadow: unset;
            border: 1px solid rgba(42, 42, 48, 0.1);
            box-sizing: border-box;
            border-radius: 4px;
          }

          .v-btn__content {
            text-transform: lowercase !important;
          }
        }
      }

      &.max-content {
        .emissions {
          .total-emission {
            max-width: 100%;
          }
        }
      }
    }
  }
  .select-month {
    .month {
      width: 70.33px;
      height: 42px;
      flex: none;
      align-self: stretch;
      flex-grow: 1;
      margin: 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px 0px 0px;
      text-align: center;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border-radius: 0;

      letter-spacing: 0.03em;

      color: $monoMid;
    }
  }
.bg-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 48px 0 24px 0;
  gap: 40px;
  align-items: stretch;
}
.left-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
  max-width: 70%;
  flex-wrap: wrap;
  justify-content: flex-start;
  .method-container {
    ::v-deep .common-btn{
      min-height: 38px !important;
    }
  }
}
  .total-result {
    display: flex;
    justify-content: end;
    .emissions {
      width: auto;
    }
  }
}

.bg-container{
  &.is-over-text-method-emissions {
    gap: 20px;
    flex-direction: column;
    .total-result {
      justify-content: start;
      .emissions {
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        align-items: center;
        .total-emission {
          text-align: right;
        }
      }
    }
  }
}

.bg-container{
  &.is-over-text-method-flex {
    gap: 20px;
    flex-direction: column;
    .left-container {
    flex-direction: column;

    }
    .total-result {
      justify-content: start;
      .emissions {
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        align-items: center;
        .total-emission {
          text-align: right;
        }
      }
    }
  }
}

@include large-desktop {
  .total-treatment-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: max-content;
    margin-left: 45px;

    .treatment-item {
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: $subFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.03em;
      word-break: break-all;
      color: $monoBlack;

      .treatment-value {
        color: $goldMid;
        line-height: 24px;
        text-align: left;
        margin-left: 0;
      }
    }
  }
  .bg-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }

  &.full-screen {
    position: fixed;
    top: 84px;
    right: 0;
    z-index: 99;
    height: 100vh;
    padding-right: 20 !important;
    width: 100%;
    animation: bottotop 0.2s ease-in 0s 1 normal none;
    -webkit-animation: bottotop 0.2s ease-in 0s 1 normal none;

    .category-table {
      height: 100%;
      height: calc(100vh - 84px);
      background: #fff;
    }

    &.expanded {
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;
      }
    }

    &.no-expanded {
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;
      }
    }
  }

  @include desktop {
    &.full-screen {
      position: fixed;
      top: 84px;
      right: 0;
      z-index: 99;
      height: 100vh;
      padding-right: 0 !important;
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;

        .table-toolbar {
          margin-bottom: 0 !important;
        }
      }

      &.expanded {
        width: calc(100% - 235px);

        .category-table {
          height: 100%;
          background: #fff;
        }
      }

      &.no-expanded {
        width: calc(100% - 73px);

        .category-table {
          height: 100%;
          background: #fff;
        }
      }
    }
  }
}

.category-table {
  .wj-cell-check {
    margin: unset !important;
  }
}

.main-table {
  @media (orientation: landscape) {
    @media (max-width: 1000px) {
      padding-left: 20px !important;
    }
  }
}
.category-name,
.method-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 64px;
  width: 100%;
  border-radius: 4px;
  padding: 7px 16px!important;
  border: 1px solid rgba(42, 42, 48, 0.1);

  span {
    flex: unset !important;
    display: block !important;
    max-width: 100%;
    white-space: pre-line;
    // overflow: hidden;
    text-transform: initial !important;
    color: $monoBlack;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
  }
}
.category-name {
  min-width: 220px;
  span { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style lang="scss">
.durations-pulldown {
  width: 100%;
  .select-month {
    &.v-input--is-disabled {
      .v-input__slot {
        background: #e4e6e7 !important;
      }
    }
  }
}
.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      gap: 4px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
  &.select-menu {
    &.select-years {
      // min-width: 118px !important;
    }
    &.select-months {
      // min-width: 100px !important;
    }
  }
  &.fullmode{
    margin-top : 34px !important;
    &.select-durations {
      min-width: 85px !important;
    }
    &.select-months {
      min-width: 65px !important;
    }
  }
}
.hide-filter {
  .wj-btn.wj-elem-filter {
    display: none;
  }
}
.wj-flexgrid .wj-cell{
  padding: 4px !important;
  .wj-cell-maker {
    button {
      margin: auto;
    }
    &:hover {
      color: $monoWhite !important;
      background: #0072a3;
    }
  }
  &.select-source {
    padding: 4px !important;
  }
  &.wj-header.search-detail {
    padding: 5px 8px !important;
  }
  &.search-detail {
    padding: 2px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background: $monoOffWhite;
    &.btn-disabled {
      button {
        background: #eef0f0 !important;
      }
    }
    .wj-cell-maker {
      display: flex;
      height: 28px !important;
      max-height: 28px !important;
      // width: fit-content;
      padding: 4px 8px 6px 8px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      background: $monoOffWhite;
      border: 1px solid rgba(42, 42, 48, 0.10);

      &:hover {
        color: $goldMid !important;
        background: unset !important;
      }
    }
    .search-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      gap: 6px;
      color: $monoBlack;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
      img {
        width: 11.999px;
        height: 11.998px;
        flex-shrink: 0;
      }
    }
    &.is-admin-read-only {
      button {
        background: #e4e6e7 !important;
        color: #404d50 !important;
        border: unset !important;
        &:hover {
          background: #e4e6e7 !important;
          cursor: default;
        }
      }
    }
  }
  &.attach-file {
    &.btn-disabled {
      button {
        background: $monoLight !important;
        color: $monoBlack !important;
        border: unset !important;
      }
    }
    .wj-cell-maker {
      color: $goldMid !important;
      position: unset !important;
      background: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $goldLight;
      &:hover {
        background: #0072A3;
        border: unset;
        color: $monoWhite !important;
        .btn_custom {
          color: $monoWhite;
        }
      }
      &:has(.file-attached-container) {
        border: 1px solid $goldMid;
      }
      &:has(.file-attached-container.approved) {
        border: 1px solid $goldHeavy;
      }
    }
    &.wj-has-changed {
      button {
        color: $goldHeavy !important;
        border: 1px solid $goldHeavy;
      }
      &.is-admin-read-only {
        button {
          background: $monoLight !important;
          color: $monoBlack !important;
          border: unset;
        }
      }
    }
    .file-attached-container {
      width: 18px;
      height: 18px;
      img {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }
    }
  }
}
.select-types {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  flex-wrap: nowrap;
  @media (max-width: $desktop) {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    flex-wrap: wrap;
  }
  .category-type, 
  .data-type {
    width: 220px;
    .tooltip {
        display: none;
        position: absolute;
        z-index: 100;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        align-self: stretch;
        border: 1px solid rgba(42, 42, 48, 0.1);
        background: $monoWhite;
        box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
          0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
          0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
          0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
          0px 36px 33px 0px rgba(160, 181, 186, 0.07);
        color: $monoBlack;
        padding: 5px;
        min-width: fit-content;
        max-width: fit-content;
        font-size: 16px;
      }
    // width: 100%;
    @media (max-width: $desktop) {
      width: 100%;
    }

  }

}
.summary-score {
  .custome-radio-group {
    display: flex;
    align-items: flex-end;
    min-height: 72px;
    .v-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;
    }

    @media (min-width: 1035px) and (max-width: 1130px) {
      .v-input--radio-group__input {
        flex-flow: column!important;
      }
    }
    
    .v-input--radio-group__input {
      gap: 16px;
      flex-flow: row;
    }
    .v-radio:not(:last-child):not(:only-child) {
      margin-bottom: unset !important;
    }
    .v-messages {
      display: none;
    }
    &.full-screen-summary-score {
      align-items: center;
      min-height: unset;
      .v-label {
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.33px;
        color: $monoBlack;
        }
    }
  }
}
</style>
