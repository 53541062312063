<template>
  <div>
    <v-dialog
      v-model="getDialog"
      ref="dialog"
      max-width="100%"
      content-class="title-close log-history"
      scrollable
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t('idea_db_source.title_original_search') }}</span>
          <div class="button-close" @click="close">
            <img src="@/assets/images/dialog/close.svg" class="button-close_close" />
            <img src="@/assets/images/dialog/close_active.svg" class="button-close_active" />
          </div>
        </v-card-title>
        <v-card-text class="pt-4">
          <div class="search-wrapper-popup">
            <div class="search-item">
              <div class="search-item-label">{{ $t('idea_db_source.title_select_original_type') }}</div>
              <v-select
                solo
                flat
                :items="externalSourcePulldown"
                v-model="externalSourceModel"
                :label="$t('idea_db_source.label_select_unit_db')"
                no-data-text="''"
                class="select-type custom-label list-menu--select select-item content-item-block-select search-item-select"
                :menu-props="{ contentClass: 'select-menu product-select select-external-source' }"
                ref="scope"
              />
            </div>
            <!-- <SearchInput
              :searchTitle="$t('basic_unit_library.label_search')"
            /> -->
          </div>
          <div class="external-sources-table" v-if="externalSourceModel">
            <data-table
              :init-grid="initGrid"
              :data-source="externalGrid"
              :grid-columns="externalColumns"
              :allowAddNew="false"
              :isShowToolbar="false"
              :rowFocus="1"
              :showFocus="false"
              :tableName="'searchExternalSource'"
            />
          </div>
          <!-- <Paging /> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataTable from '@/components/category/data-table';
import { CollectionView } from '@mescius/wijmo';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import { getWidthButtonByText } from '@/utils/multiLanguage';
import { getListIdeaDbSourceDetailsApi, getIdeaDbSourceDetailsApi } from '@/api/ideaDbSource';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { mapActions } from "vuex";
import { DB_TYPE } from '@/constants/dbType';
import { formatNumberByConditions } from '@/utils/convertNumber';
export default {
  components: { DataTable },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Number,
      default: null,
    },
    detailExternalSource: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      flexgrid: null,
      externalGrid: null,
      externalColumns: [],
      data: [],
      externalSourcePulldown: [],
      ideaDbSource: [],
      ideaDbSourceHeader: [],
      externalSourceModel: null,
      dataOriginal: [],
      itemLoadMoreNumber: 3000,
      lengthdataOriginal: null,
      isLoading: false
    };
  },
  mounted() {
    this.initialView();
    this.fetchIdeaDBsourcesPullDown();
  },
  created() {},
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  watch: {
    async externalSourceModel(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.fetchDetailsIdeaSources();
      }
    },
    dialog: {
      handler(val) {
        if (val && this.detailExternalSource) {
          this.externalSourceModel = this.detailExternalSource;
        }
      },
    },
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingPopUp']),
    async fetchDetailsIdeaSources() {
      this.actionUpdateIsLoadingPopUp(true)
      this.isLoading = true;
      try {
        const res = await getIdeaDbSourceDetailsApi(this.externalSourceModel);
        const ideaDbSource = res;
        this.ideaDbSourceHeader = ideaDbSource.header;
        this.dataOriginal = ideaDbSource.db_source_detail;
        this.lengthdataOriginal = this.dataOriginal.length;
        this.prepareData(ideaDbSource.db_source_detail, this.itemLoadMoreNumber);
        this.ideaDbSource = ideaDbSource.db_source_detail.slice(0, Math.min(this.lengthdataOriginal, this.itemLoadMoreNumber));
        this.externalGrid = new CollectionView([...this.ideaDbSource]);
        this.flexgrid.columnGroups = this.initGridColumn();
        setMinMaxSizeColumns(this.flexgrid, this.ideaDbSource);
        this.externalGrid.currentItem = null;
        this.actionUpdateIsLoadingPopUp(false)
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    addDataLoadMore(data, cnt) {
      // lengthdataOriginal always better than cnt
			let more = this.prepareData(this.dataOriginal.slice(this.ideaDbSource.length, this.ideaDbSource.length + Math.min(this.lengthdataOriginal - cnt, cnt)), Math.min(this.lengthdataOriginal - cnt, cnt));
			for (let i = 0; i < more.length; i++) {
				data.push(more[i])
			}
      this.itemLoadMoreNumber += this.itemLoadMoreNumber
      if (this.flexgrid) {
        setMinMaxSizeColumns(this.flexgrid, this.ideaDbSource);
      }
		},
    prepareData(ideaDbSource, cnt) {
      let max = Math.min(this.lengthdataOriginal, cnt);
      for (let i = 0; i < max; i++) {
          for (let j = 0; j < this.ideaDbSourceHeader.length; j++) {
            const parseContent = JSON.parse(ideaDbSource[i].content);
            const indexSourceValue = j === 2; // To handle format num index source value
            ideaDbSource[i][`header_${j}`] = indexSourceValue
            ? formatNumberByConditions(parseContent[j], { isRealNumber: true })
            : parseContent[j];
          }
      }
      return ideaDbSource;
    },
    async fetchIdeaDBsourcesPullDown() {
      try {
        const res = await getListIdeaDbSourceDetailsApi();
        this.externalSourcePulldown = res.data.map((source) => ({
          ...source,
          value: source.id,
          text: source.db_name,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    close() {
      if(this.isLoading) {
        return
      }
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    minWidthDetailsSource(index, header) {
      switch (index) {
        case 0:
          return 128;
          break;
        case 1:
          return 114
          break;
        case 2:
          return 74
          break;
      
        default:
          return getWidthByTextContent(header) + 5
          break;
      }
    },
    initGridColumn() {
      this.ideaDbSourceHeader[0] = this.$t('idea_db_source.title_header_source_name')
      this.ideaDbSourceHeader[1] = this.$t('idea_db_source.title_header_unit')
      this.ideaDbSourceHeader[2] = this.$t('idea_db_source.title_header_value_source')
      this.ideaDbSourceHeader[3] = this.$t('idea_db_source.title_header_unit_source')
      const mapColumns = this.ideaDbSourceHeader.map((header, index) => {
        const getWidth = getWidthButtonByText(header, { fontSize: '13px', fontWeight: 500, letterSpacing: '0.04em', lineHeight: 20 });
        return {
          header: header,
          binding: `header_${index}`,
          width       : index === this.ideaDbSourceHeader.length - 1 ? '*' : getWidth + 31, // 30 is width of the filter button
          minWidth    : getWidthByTextContent(header) + 12, // 30 is width of the filter button
          maxWidth    : 1428,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          cssClassAll: 'single-row',
        };
      });
      const getWidth = getWidthButtonByText(this.$t('b_register_product.button_choice') , { fontSize: '13px', fontWeight: 500, letterSpacing: '0.03em', lineHeight: 20 });
      return [
        {
          header: ' ',
          binding: 'select_source',
          allowSorting: false,
          align: 'center',
          minWidth: getWidth,
          maxWidth: getWidth,
          wordWrap: true,
          cssClassAll: 'btn-detail hide-filter product-detail select-source',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_register_product.button_choice'),
            click: (event, context) => this.handleIdClick(context),
          }),
        },
        ...mapColumns,
      ];
    },
    initGrid(grid) {
      this.flexgrid = grid;

      grid.scrollPositionChanged.addHandler((s) => {
				if (s.viewRange.bottomRow >= s.rows.length - 1 && this.ideaDbSource.length < this.lengthdataOriginal) {
          let view = s.collectionView;
          let index = view.currentPosition; // keep position in case the view is sorted
          this.addDataLoadMore(this.ideaDbSource, this.itemLoadMoreNumber);
          this.externalGrid = new CollectionView([...this.ideaDbSource]);
					view.refresh();
          view.currentPosition = index;
				}
			});

      if (this.flexgrid) {
        this.flexgrid.columnGroups = this.initGridColumn();
      }
      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel === s.columnHeaders) {
          if (colBinding === 'select_source') {
            e.cell.innerHTML = e.cell.innerHTML.replace('<span class="wj-glyph-filter"></span>', '');
          }
        }
      });
    },
    initialView() {
      if (this.externalGrid) {
        this.data = this.externalGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
        this.externalGrid.currentItem = null;
      }
      if (this.flexgrid) {
        this.flexgrid.columnGroups = this.initGridColumn();
        setMinMaxSizeColumns(this.flexgrid, this.ideaDbSources);
      } 
    },
    handleIdClick(context) {
      this.$emit('submit', context, this.selectedRow, this.externalSourceModel, DB_TYPE.IDEA);
      this.$emit('close');
    },
    getDataFromExternalSource() {},
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';

.search-item-label {
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}

.search-item-select {
  margin-top: 8px;
  margin-bottom: 24px;
  max-width: 300px;
}
.search-wrapper-popup {
  display: flex;
  gap: 24px;
}
</style>
<style lang="scss">
.external-sources-table {
  .select-item {
    background: $monoWhite !important;

    .v-input__slot {
      background: $monoWhite !important;
    }
  }
  .wj-flexgrid .wj-cell {
    &.wj-state-active {
      color: $monoBlack !important;
    }

    color: $monoBlack !important;

    &.wj-header {
      color: $monoDark !important;
    }
    .wj-cell-maker {
      border: 1px solid $goldLight !important;
      color: $goldMid !important;
      background: unset;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      min-width: 38px;
      position: unset !important;
    }
  }
}
</style>
